// REQUEST Models
import { TaskPageElementRequest } from "./task-page-element.request";

// UI Models
import { TaskPageUI } from "../../app/ui";

export class UpdateTaskPageRequest {
  id?: string;
  task_id: string;
  page_number: number;
  task_page_elements?: TaskPageElementRequest[];

  constructor(taskPageUI: TaskPageUI, taskId: string, taskPageId?: string) {
    this.id = taskPageId;
    this.task_id = taskId;
    this.page_number = taskPageUI.pageNumber;
    this.task_page_elements = taskPageUI.taskPageElements?.map(
      element => new TaskPageElementRequest(element)
    );
  }
}
