// UI Models
import { OnboardingPageElementUI } from "./onboarding-page-element.ui";

// Domain Models
import { OnboardingPage } from "../domain";

type OnboardingPageUIType = {
  id?: string;
  pageNumber: number;
  taskPageElements?: OnboardingPageElementUI[];
};

export class OnboardingPageUI {
  id?: string;
  pageNumber: number;
  taskPageElements: OnboardingPageElementUI[];

  constructor(data?: OnboardingPageUIType | OnboardingPage | null) {
    this.id = data?.id;
    this.pageNumber = data?.pageNumber || 0;

    this.taskPageElements =
      data?.taskPageElements?.map(
        element => new OnboardingPageElementUI(element)
      ) || [];
  }
}
