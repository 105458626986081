// DTOS
import { FitnessFiltersDto } from "../dtos";
// DOMAIN Models
import { FitnessFilters } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type FitnessFiltersResponseDataType = {
  filters: FitnessFiltersDto;
  statusType: ResponseStatusType;
};

export class FitnessFiltersResponse {
  filters: FitnessFilters;
  statusType: ResponseStatusType;

  constructor(responseData: FitnessFiltersResponseDataType) {
    this.filters = new FitnessFilters(responseData.filters);
    this.statusType = ResponseStatusType.OK;
  }
}
