// REQUEST Models
import { FitnessPageElementRequest } from "./fitness-page-element.request";

// UI Models
import { FitnessPageUI } from "../../app/ui";

export class UpdateFitnessPageRequest {
  id?: string;
  //   fitness_id: string;
  page_number: number;
  fitness_page_elements?: FitnessPageElementRequest[];

  constructor(
    fitnessPageUI: FitnessPageUI,
    // fitnessId: string,
    fitnessPageId?: string
  ) {
    this.id = fitnessPageId;
    // this.fitness_id = fitnessId;
    this.page_number = fitnessPageUI.pageNumber;
    this.fitness_page_elements = fitnessPageUI.pageElements?.map(
      element => new FitnessPageElementRequest(element)
    );
  }
}
