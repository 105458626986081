import { useState } from "react";
import { toast } from "react-toastify";

// Components
import PageHeader from "../../components/pageHeader/PageHeader";

// Containers
import OnboardingForm from "../../containers/forms/onboarding/onboarding-form/Onboarding.form";
import OnboardingPageForm from "../../containers/forms/onboarding/onboarding-page-form/OnboardingPageForm";
import OnboardingPageElement from "../../containers/forms/onboarding/onboarding-page-element-form/OnboardingPageElement.form";

// App Enums
import { OnboardingFormTypeEnum } from "../../enums/app";

// Api Enums
import { ResponseStatusType } from "../../enums/api";

// UI Models
import {
  OnboardingUI,
  OnboardingPageUI,
  OnboardingPageElementUI,
  OnboardingQuestionAnswerUI
} from "../../models/app/ui";

// Calls
import {
  deletePage,
  deletePageElement,
  deleteOnboardingPageElementQuestionAnswer
} from "../../api/calls/onboarding/onboarding";

// Contracts
import { StateStructureType } from "./contracts/onboarding.type";

// Utils
import * as utils from "../../utils/global.utils";

const Onboarding = () => {
  const [pageState, setPageState] = useState<StateStructureType>({
    previousFormTab: OnboardingFormTypeEnum.ONBOARDING_FORM,
    formTab: OnboardingFormTypeEnum.ONBOARDING_FORM,
    onboardingId: undefined,
    chosenPage: undefined,
    chosenPageId: undefined,
    chosenPageElement: undefined,
    chosenPageElementId: undefined
  });

  const setOnboardingId = (id: string): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        onboardingId: id
      })
    );
  };

  /**
   * Sets formTab and previousFormTab
   */

  const goBackOneFormHandler = (
    pageId?: string,
    chosenPage?: OnboardingPageUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        formTab: prevValues.previousFormTab,
        previousFormTab: OnboardingFormTypeEnum.ONBOARDING_FORM,
        chosenPageId: pageId ? pageId : undefined,
        chosenPage: chosenPage ? chosenPage : undefined,
        chosenPageElement: undefined,
        chosenPageElementId: undefined
      })
    );
  };

  // ONBOARDING ACTIONS

  /**
   * Saves values from task form in task state
   * Sets chosenPage as new TaskPageUI with predefined page number
   * @param onboardingValues - TaskUI
   */
  const addNewPage = (onboardingValues: OnboardingUI): void => {
    let lastTaskPageNumber = onboardingValues.taskPages?.length || 0;

    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        previousFormTab: OnboardingFormTypeEnum.ONBOARDING_FORM,
        formTab: OnboardingFormTypeEnum.PAGE_FORM,
        chosenPage: new OnboardingPageUI({ pageNumber: lastTaskPageNumber + 1 })
      })
    );
  };

  /**
   * Sets chosenPageId from list of pages
   * @param pageId - string
   */
  const editPage = (pageId?: string): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPageId: pageId,
        previousFormTab: OnboardingFormTypeEnum.ONBOARDING_FORM,
        formTab: OnboardingFormTypeEnum.EDIT_PAGE_FORM
      })
    );
  };

  /**
   * Removes chosen page from list of pages and fixes pages number order
   * Requires users confirmation
   * @param pageIndex - number
   */
  const removePageFromOnboarding = async (
    pageIndex: number,
    pageId?: string,
    values?: OnboardingUI,
    setFieldValue?: (fieldName: string, values: OnboardingPageUI[]) => void
  ): Promise<void> => {
    const result = window.confirm("Are u sure u want to delete this page?");

    if (!result) {
      return;
    }

    if (pageId) {
      const response = await deletePage(pageId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
    }

    values &&
      setFieldValue &&
      setFieldValue(
        "taskPages",
        values?.taskPages
          .filter((page, i) => i !== pageIndex)
          .map((page, i) =>
            utils.updateInstanceObject(OnboardingPageUI, page, {
              pageNumber: i + 1
            })
          )
      );
  };

  // PAGE ACTIONS

  /**
   * Adds new page element to chosenPageElement
   * Saves current page form values
   * @param currentFormTab - OnboardingFormTypeEnum
   * @param pageFormValues - OnboardingPageUI
   */
  const addNewPageElement = (
    currentFormTab: OnboardingFormTypeEnum,
    pageFormValues: OnboardingPageUI
  ): void => {
    const lastPageElementNumber: number =
      pageFormValues.taskPageElements?.length || 0;

    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage: utils.updateInstanceObject(OnboardingPageUI, prevValues, {
          ...pageFormValues
        }),
        chosenPageElement: new OnboardingPageElementUI({
          sequenceNumber: lastPageElementNumber + 1
        }),
        previousFormTab: currentFormTab,
        formTab: OnboardingFormTypeEnum.PAGE_ELEMENT_FORM
      })
    );
  };

  /**
   * Adds page element from list of elements in page to chosenPageElement
   * Saves current page form values
   * @param pageElelement - OnboardingPageElementUI
   * @param pageFormValues - OnboardingPageUI
   */
  const editPageElement = (
    pageFormValues: OnboardingPageUI,
    pageElelement: OnboardingPageElementUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage: utils.updateInstanceObject(OnboardingPageUI, prevValues, {
          ...pageFormValues
        }),
        previousFormTab: prevValues.formTab,
        formTab: OnboardingFormTypeEnum.EDIT_PAGE_ELEMENT_FORM,
        chosenPageElement: pageElelement,
        chosenPageElementId: pageElelement.id
      })
    );
  };

  /**
   * Removes chosen page element from list of elements in chosen page and fixes page elements number order
   * Requires users confirmation
   * @param pageIndex - number
   */
  const removePageElementFromPage = async (
    pageElementIndex: number,
    pageElementId?: string,
    values?: OnboardingPageUI,
    setFieldValue?: (
      fieldName: string,
      value: OnboardingPageElementUI[]
    ) => void
  ): Promise<void> => {
    const result = window.confirm("Are u sure u want to delete this element?");
    if (!result) {
      return;
    }

    if (pageElementId) {
      const response = await deletePageElement(pageElementId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
    }

    values &&
      values.taskPageElements &&
      setFieldValue &&
      setFieldValue(
        "taskPageElements",
        values?.taskPageElements
          ?.filter((element, i) => i !== pageElementIndex)
          .map((element, i) =>
            utils.updateInstanceObject(OnboardingPageElementUI, element, {
              sequenceNumber: i + 1
            })
          )
      );
  };

  // PAGE ELEMENT ACTIONS

  /**
   * Append new page element to chosen page
   * Redirect to page form with new page element
   * @param pageElement - OnboardingPageElementUI
   */
  const appendNewPageElementToChosenPage = (
    pageElement: OnboardingPageElementUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage:
          prevValues.chosenPage &&
          utils.updateInstanceObject(OnboardingPageUI, prevValues.chosenPage, {
            taskPageElements: prevValues.chosenPage?.taskPageElements
              ? [...prevValues.chosenPage?.taskPageElements, pageElement]
              : [pageElement]
          }),
        chosenPageElement: undefined,
        previousFormTab: OnboardingFormTypeEnum.ONBOARDING_FORM,
        formTab: prevValues.previousFormTab
      })
    );
  };

  /**
   * Replaces pageElement with chosenPageElement based on sequenceNumber
   * Redirect to page form with updated page element
   * @param pageElement - OnboardingPageElementUI
   */
  const updatePageElementToChosenPage = (
    pageElement: OnboardingPageElementUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage:
          prevValues.chosenPage &&
          utils.updateInstanceObject(OnboardingPageUI, prevValues.chosenPage, {
            taskPageElements:
              prevValues.chosenPage.taskPageElements &&
              prevValues.chosenPage.taskPageElements.map(element =>
                element.sequenceNumber === pageElement.sequenceNumber
                  ? pageElement
                  : element
              )
          }),
        chosenPageElement: undefined,
        previousFormTab: OnboardingFormTypeEnum.ONBOARDING_FORM,
        formTab: prevValues.previousFormTab
      })
    );
  };

  /**
   * Removes answer
   * Requires users confirmation
   * @param pageIndex - number
   */
  const removeAnswerFromQuestion = async (
    answerIndex: number,
    values: OnboardingPageElementUI,
    setFieldValue: (fieldName: string, value: any) => void,
    answerId?: string
  ): Promise<void> => {
    const result = window.confirm("Are u sure u want to delete this answer?");
    if (!result) {
      return;
    }

    if (answerId) {
      const response = await deleteOnboardingPageElementQuestionAnswer(
        answerId
      );
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
    }

    values &&
      values.question &&
      values.question.answers &&
      setFieldValue(
        "question.answers",
        values.question.answers
          .filter((answer, i) => i !== answerIndex)
          .map((answer, i) =>
            utils.updateInstanceObject(OnboardingQuestionAnswerUI, answer, {
              sequenceNumber: i + 1
            })
          )
      );
  };
  return (
    <>
      <PageHeader title="Onboarding" />
      {pageState.formTab === OnboardingFormTypeEnum.ONBOARDING_FORM && (
        <OnboardingForm
          addNewPage={addNewPage}
          editPage={editPage}
          setOnboardingId={setOnboardingId}
          removePageFromOnboarding={removePageFromOnboarding}
        />
      )}

      {(pageState.chosenPage || pageState.chosenPageId) &&
        (pageState.formTab === OnboardingFormTypeEnum.PAGE_FORM ||
          pageState.formTab === OnboardingFormTypeEnum.EDIT_PAGE_FORM) && (
          <OnboardingPageForm
            onboardingId={pageState.onboardingId}
            pageId={pageState.chosenPageId}
            page={pageState.chosenPage}
            formTab={pageState.formTab}
            addNewPageElement={addNewPageElement}
            goBackOneFormHandler={goBackOneFormHandler}
            editPageElement={editPageElement}
            removePageElementFromPage={removePageElementFromPage}
          />
        )}

      {(pageState.chosenPageElement || pageState.chosenPageElementId) &&
        (pageState.formTab === OnboardingFormTypeEnum.PAGE_ELEMENT_FORM ||
          pageState.formTab ===
            OnboardingFormTypeEnum.EDIT_PAGE_ELEMENT_FORM) && (
          <OnboardingPageElement
            onboardingId={pageState.onboardingId}
            formTab={pageState.formTab}
            chosenPageElement={pageState.chosenPageElement}
            pageElementId={pageState.chosenPageElementId}
            page={pageState.chosenPage}
            pageId={pageState.chosenPage?.id}
            goBackOneFormHandler={goBackOneFormHandler}
            appendNewPageElementToChosenPage={appendNewPageElementToChosenPage}
            updatePageElementToChosenPage={updatePageElementToChosenPage}
            removeAnswerFromQuestion={removeAnswerFromQuestion}
          />
        )}
    </>
  );
};

export default Onboarding;
