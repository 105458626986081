// DTOS
import { OnboardingDto } from "../../api/dtos";

// DOMAIN Models
import { OnboardingPage } from "./onboarding-page.model";

// Utils
import { isEmpty } from "../../../utils/global.utils";

export class Onboarding {
  id: string;
  taskPages: OnboardingPage[];

  constructor(entity: OnboardingDto) {
    this.id = entity.id;
    this.taskPages = !isEmpty(entity.pages)
      ? entity.pages?.map(page => new OnboardingPage(page))
      : [];
  }
}
