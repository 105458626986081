// Domain Model
import { FileModel } from "../models/app/domain";

// Global Utils
import { isEmpty } from "./global.utils";

/**
 * Gets url string from file
 * @param file - FileModel | string | null | undefined
 * @returns string | null
 */
export const returnUrlFromFile = (
  file?: FileModel | string | null
): string | null => {
  if (typeof file === "string") {
    return file;
  }

  if (!isEmpty(file) && file instanceof FileModel && !isEmpty(file.url)) {
    return file.url;
  }

  return null;
};
