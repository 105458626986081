//Dtos
import { FitnessDto } from "../../api/dtos";

// Domain models
import { FileModel } from "./file.model";
import { FitnessPage } from "./fitness-page.model";

// App Enums
import { FitnessDifficultyEnum, FitnessTypeEnum } from "../../../enums/app";

// Utils
import { isEmpty } from "../../../utils/global.utils";

export class Fitness {
  id: string;
  name: string;
  type: FitnessTypeEnum;
  duration: number;
  difficulty: FitnessDifficultyEnum;
  image: FileModel | null;
  exercises?: string[];
  equipments?: string[];
  targets?: string[];
  pages?: FitnessPage[];

  constructor(entity: FitnessDto) {
    this.id = entity.id;
    this.name = entity.name;
    this.type = entity.type;
    this.duration = entity.duration;
    this.difficulty = entity.difficulty;
    this.image = !isEmpty(entity.image) ? new FileModel(entity.image) : null;
    this.exercises = entity.exercises;
    this.equipments = entity.equipments;
    this.targets = entity.targets;
    this.pages = entity.pages?.map(page => new FitnessPage(page));
  }
}
