import { FormikHelpers, useFormik } from "formik";
import { toast } from "react-toastify";

// Theme
import {
  TextField,
  Button,
  Typography,
  Avatar,
  Card,
  CardContent
} from "@mui/material";

// Theme icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

// Enums
import { ResponseStatusType } from "../../../enums/api";

// SVGS
import { Logo } from "../../../SVGS";

//UI Models
import { LoginUI } from "../../../models/app/ui";

// Validations
import { loginFormValidation } from "./LoginForm.validation";

// Utils
import { setItem } from "../../../utils/localStorage.utils";

// Constants
import * as localStoageKeys from "../../../constants/localStorage.constants";

// Contracts
import { LoginFormProps } from "./contracts/login-form.types";

// API Calls
import { loginRequest } from "../../../api/calls/auth/auth";

// styles
import classes from "./LoginForm.module.css";

const LoginForm = ({ handleRoutesBasedOnToken }: LoginFormProps) => {
  /**
   * Makes Api login call
   * @param values - LoginUI
   * SideEffects
   * Sets TOKEN, REFRESH_TOKEN, EXPIRES_AT in local storage
   * Sets privates routes if login is successfull
   */
  // TODO add validations for failed request
  const loginHandler = async (
    values: LoginUI,
    { setSubmitting }: FormikHelpers<LoginUI>
  ): Promise<void> => {
    setSubmitting(true);
    const response = await loginRequest(values);
    if (response.statusType !== ResponseStatusType.OK) {
      setSubmitting(false);
      return;
    }

    setItem(localStoageKeys.TOKEN, response.auth.accessToken);
    setItem(localStoageKeys.REFRESH_TOKEN, response.auth.refreshToken);
    setItem(localStoageKeys.EXPIRES_AT, response.auth.expiresAt);
    toast.success("You have logged in successfully!");
    handleRoutesBasedOnToken(response.auth.accessToken);
  };

  const formik = useFormik({
    initialValues: new LoginUI(),
    onSubmit: loginHandler,
    validate: loginFormValidation
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting
  } = formik;

  return (
    <>
      <div className={classes.loginFormContainer}>
        <Card className={classes.loginFormCard}>
          <CardContent className={classes.loginFormCardContent}>
            <div className={classes.loginFormLeffPane}>
              <div className={classes.loginImageContainer}>
                <Logo className={classes.logo} />
              </div>
            </div>

            <div className={classes.loginFormRightPane}>
              <form onSubmit={handleSubmit} autoComplete="new-password">
                <div className={classes.loginIconContainer}>
                  <Avatar>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography className={classes.loginFormText}>
                    Sign in
                  </Typography>
                </div>
                <div className={classes.textFieldContainer}>
                  <TextField
                    fullWidth
                    id="email"
                    type="email"
                    name="email"
                    label="Email address *"
                    error={touched.email && errors.email ? true : false}
                    helperText={touched.email && errors.email}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classes.textFieldContainer}>
                  <TextField
                    fullWidth
                    id="password"
                    type="password"
                    name="password"
                    label="Password *"
                    error={touched.password && errors.password ? true : false}
                    helperText={touched.password && errors.password}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Sign in
                </Button>
              </form>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default LoginForm;
