// Enums
import {
  TaskQuestionsTypesEnum,
  QuestionAnswerTypeEnum
} from "../../../enums/app";

// Dtos
import { TaskQuestionDto } from "../../api/dtos";

// Domain Models
import { TaskQuestionAnswer } from "./task-question-answer.model";

// Utils
import { isEmpty } from "../../../utils/global.utils";

export class TaskQuestion {
  id: string;
  title: string;
  image: string;
  explanation: string;
  isMultiSelect: boolean;
  isSkippable: boolean;
  type: TaskQuestionsTypesEnum;
  answerType: QuestionAnswerTypeEnum;
  answers?: TaskQuestionAnswer[];

  constructor(entity: TaskQuestionDto) {
    this.id = entity.id;
    this.title = entity.title;
    this.image = entity.image;
    this.explanation = entity.explanation;
    this.isMultiSelect = entity.is_multiselect;
    this.isSkippable = entity.is_skippable;
    this.type = entity.type;
    this.answerType = entity.answer_type;
    this.answers = !isEmpty(entity.answers)
      ? entity.answers?.map(answer => new TaskQuestionAnswer(answer))
      : [];
  }
}
