import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastContainer, Zoom } from "react-toastify";

// Styles
import "react-toastify/dist/ReactToastify.css";

// configs
import { generateRouterConfig } from "./config/router.config";

// Utils
import * as localStorageUtils from "./utils/localStorage.utils";

// Constants
import * as localStorageConsts from "./constants/localStorage.constants";

// components
import PageWrapper from "./components/wrapper/PageWrapper";
import SideMenu from "./components/sidemenu/SideMenu";

// Pages
import NotFound from "./pages/notFound/NotFound";

export let logout: any;

const AppRouter = () => {
  const [routerConfig, setRouterConfig] = useState(
    generateRouterConfig(localStorageUtils.getItem(localStorageConsts.TOKEN))
  );
  const [history, setHistory] = useState<any | undefined>(undefined);

  const { fullLayout, routes } = routerConfig;

  /**
   * Handle routes based on token exsitance
   * @param token - string or undefined
   */
  const handleRoutesBasedOnToken = (token?: string): void => {
    setRouterConfig(generateRouterConfig(token));
  };

  useEffect(() => {
    logout = handleRoutesBasedOnToken;
  }, []);

  useEffect(() => {
    if (history) {
      history.replace("/");
    }
  }, [routerConfig]);

  return (
    <Router>
      {!fullLayout ? (
        <>
          <SideMenu
            setHistory={setHistory}
            handleRoutesBasedOnToken={handleRoutesBasedOnToken}
          />
          <Switch>
            {routes.map(route => (
              <Route key={route.path} path={route.path} exact={route.exact}>
                <PageWrapper>
                  <route.component />
                </PageWrapper>
              </Route>
            ))}
            <Route>
              <PageWrapper>
                <NotFound />
              </PageWrapper>
            </Route>
          </Switch>
        </>
      ) : (
        <Switch>
          {routes.map(route => (
            <Route key={route.path} path={route.path} exact={route.exact}>
              <route.component
                handleRoutesBasedOnToken={
                  route.path === "/" && handleRoutesBasedOnToken
                }
              />
            </Route>
          ))}
          <Route>
            <NotFound />
          </Route>
        </Switch>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Zoom}
      />
    </Router>
  );
};

export default AppRouter;
