// App Enums
import {
  OnboardingPageElementTypeEnum,
  OnboardingQuestionsTypesEnum,
  QuestionAnswerTypeEnum
} from "../../enums/app";

// Page Elements Type Field options
type pageElementsTypeOptionsType = {
  value: OnboardingPageElementTypeEnum;
  label: string;
};

export const pageElementsTypeOptions: pageElementsTypeOptionsType[] = [
  {
    value: OnboardingPageElementTypeEnum.TEXT,
    label: "Text"
  },
  {
    value: OnboardingPageElementTypeEnum.IMAGE,
    label: "Image"
  },
  {
    value: OnboardingPageElementTypeEnum.VIDEO,
    label: "Video"
  },
  {
    value: OnboardingPageElementTypeEnum.AUDIO,
    label: "Audio"
  },
  {
    value: OnboardingPageElementTypeEnum.QUESTION,
    label: "Question"
  },
  {
    value: OnboardingPageElementTypeEnum.CONTINUE_BUTTON,
    label: "Continue button"
  },
  {
    value: OnboardingPageElementTypeEnum.COMPLETE_BUTTON,
    label: "Complete button"
  }
];

export const fileUploadTypes: string[] = [
  OnboardingPageElementTypeEnum.IMAGE,
  OnboardingPageElementTypeEnum.VIDEO,
  OnboardingPageElementTypeEnum.AUDIO
];

export const buttonTypes: string[] = [
  OnboardingPageElementTypeEnum.COMPLETE_BUTTON,
  OnboardingPageElementTypeEnum.CONTINUE_BUTTON
];

// Page Elements Type Field options
type QuestionOptionsType = {
  value: OnboardingQuestionsTypesEnum;
  label: string;
};

export const questionTypeOptions: QuestionOptionsType[] = [
  {
    value: OnboardingQuestionsTypesEnum.CONDITIONAL,
    label: "Conditional"
  },
  {
    value: OnboardingQuestionsTypesEnum.JOURNAL,
    label: "Journal"
  }
];

type QuestionAnswerTypeOptions = {
  value: QuestionAnswerTypeEnum;
  name: string;
};

export const answerTypeOptions: QuestionAnswerTypeOptions[] = [
  {
    value: QuestionAnswerTypeEnum.SINGLE,
    name: "Single Select"
  },
  {
    value: QuestionAnswerTypeEnum.MULTISELECT,
    name: "Multi Select"
  },
  {
    value: QuestionAnswerTypeEnum.SLIDER,
    name: "Slider"
  },
  {
    value: QuestionAnswerTypeEnum.SINGLE_AND_OPEN,
    name: "Single and Open"
  }
];
