import { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";

// theme
import {
  Card,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  Box,
  TextField
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";

// UI Models
import { OnboardingPageUI, OnboardingUI } from "../../../../models/app/ui";

// Api Enums
import { ResponseStatusType } from "../../../../enums/api";

// Validations
import { onboardingValidationSchema } from "./onboarding-form.validation";

// Contracts
import { OnboardingFormProps } from "./contracts/onboarding-form.types";

// Calls
import {
  fetchOnboarding,
  reorderOnboardingPage
} from "../../../../api/calls/onboarding/onboarding";

// utils
import { updateObject } from "../../../../utils/global.utils";

// Styles
import classes from "./onboardingForm.module.css";

const OnboardingForm = ({
  addNewPage,
  editPage,
  removePageFromOnboarding,
  setOnboardingId
}: OnboardingFormProps) => {
  const [onboardingForm, setOnboardingForm] = useState<
    OnboardingUI | undefined
  >(undefined);
  const [reorderState, setReorderState] = useState<{
    isOpen: boolean;
    page?: OnboardingPageUI;
  }>({
    isOpen: false,
    page: undefined
  });

  const getOnboardingDetails = async (): Promise<void> => {
    const response = await fetchOnboarding("300", "1");
    if (response.statusType !== ResponseStatusType.OK) {
      return;
    }
    setOnboardingForm(new OnboardingUI(response.onboarding));
    setOnboardingId(response.onboarding.id);
  };

  useEffect(() => {
    getOnboardingDetails();
  }, []);

  return (
    <>
      {onboardingForm && (
        <Card className={classes.taskCard}>
          <Formik
            enableReinitialize={true}
            initialValues={onboardingForm}
            onSubmit={() => {}}
            validationSchema={onboardingValidationSchema}
          >
            {({ values, touched, errors, setFieldValue, handleSubmit }) => {
              const handleReorderPage = async (
                page?: OnboardingPageUI
              ): Promise<void> => {
                if (page?.id) {
                  const response = await reorderOnboardingPage(
                    page?.id,
                    page?.pageNumber
                  );
                  if (response.statusType !== ResponseStatusType.OK) {
                    return;
                  }
                  setFieldValue("taskPages", response.onboardingPages);
                  toast.success("Reorder completed");
                }

                setReorderState({
                  isOpen: false,
                  page: undefined
                });
              };
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Typography className={classes.headerTitle} variant="h6">
                    Onboarding form
                  </Typography>
                  <div className={classes.pages}>
                    <div className={classes.fullWidth}>
                      <div className={classes.pageElementsLabel}>
                        <Typography
                          className={classes.headerTitle}
                          variant="h6"
                        >
                          Pages
                        </Typography>
                        <Button
                          onClick={() => {
                            addNewPage(values);
                          }}
                          variant="contained"
                        >
                          Add new page
                        </Button>
                      </div>
                      <List
                        component="nav"
                        aria-label="secondary mailbox folder"
                      >
                        {values.taskPages?.map((page, i) => (
                          <ListItemButton key={i}>
                            <ListItemText primary={`Page ${page.pageNumber}`} />
                            <Button
                              onClick={() =>
                                setReorderState({
                                  isOpen: true,
                                  page: page
                                })
                              }
                              variant="contained"
                              color="success"
                            >
                              Reorder
                            </Button>
                            <Button
                              onClick={() => editPage(page.id)}
                              variant="contained"
                              color="primary"
                            >
                              Edit
                            </Button>

                            <Button
                              onClick={() =>
                                removePageFromOnboarding(
                                  i,
                                  page.id,
                                  values,
                                  setFieldValue
                                )
                              }
                              variant="contained"
                              color="error"
                            >
                              Delete
                            </Button>
                          </ListItemButton>
                        ))}
                      </List>
                      <p className={classes.errorMessage}>
                        {touched.taskPages &&
                          errors.taskPages &&
                          errors.taskPages}
                      </p>
                    </div>
                  </div>
                  <Modal
                    open={reorderState.isOpen}
                    onClose={() =>
                      setReorderState({
                        isOpen: false,
                        page: undefined
                      })
                    }
                  >
                    <Box className={classes.modalBox}>
                      <div className={classes.formModalHeader}>
                        <Typography variant="h5">
                          Page {reorderState.page?.pageNumber}
                        </Typography>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setReorderState({
                              isOpen: false,
                              page: undefined
                            })
                          }
                        >
                          <CloseOutlined />
                        </div>
                      </div>
                      <TextField
                        fullWidth
                        id="standard-number"
                        label="Lesson position"
                        type="number"
                        name="position"
                        variant="standard"
                        value={reorderState.page?.pageNumber}
                        onChange={e =>
                          setReorderState(prevState =>
                            updateObject(prevState, {
                              page: {
                                ...prevState.page,
                                pageNumber: e.target.value
                              }
                            })
                          )
                        }
                      />
                      <Button
                        onClick={() => {
                          handleReorderPage(reorderState.page);
                        }}
                        style={{ marginTop: "40px" }}
                        variant="contained"
                      >
                        Save changes
                      </Button>
                    </Box>
                  </Modal>
                </form>
              );
            }}
          </Formik>
        </Card>
      )}
    </>
  );
};

export default OnboardingForm;
