// Domain Models
import { TaskQuestionAnswer } from "../domain";

// App Enums
import { AnswerTypeEnum } from "../../../enums/app";

type TaskQuestionAnswerUIType = {
  id?: string;
  answer?: string;
  type?: AnswerTypeEnum;
  sequenceNumber: number;
  isCorrect?: boolean;
  answerScore?: number | null;
};

export class TaskQuestionAnswerUI {
  id?: string;
  answer: string;
  type: AnswerTypeEnum;
  sequenceNumber: number;
  isCorrect: boolean;
  answerScore?: number | null;

  constructor(data?: TaskQuestionAnswerUIType | TaskQuestionAnswer) {
    this.id = data?.id;
    this.answer = data?.answer || "";
    this.type = data?.type || AnswerTypeEnum.SELECTION;
    this.sequenceNumber = data?.sequenceNumber || 0;
    this.isCorrect = data?.isCorrect || false;
    this.answerScore = data?.answerScore || 0;
  }
}
