import React from "react";
import {
  Journey,
  Login,
  StorePage,
  CreateTask,
  EditTask,
  UsersPage,
  AdminsPage,
  FitnressPage,
  CreateFitness,
  EditFitness,
  Onboarding
} from "../pages";

type Route = {
  path: string;
  component: React.ElementType;
  exact: boolean;
};

type RouteConfig = {
  fullLayout: boolean;
  routes: Route[];
};

const publicRoutes: RouteConfig = {
  fullLayout: true,
  routes: [
    {
      path: "/",
      component: Login,
      exact: true
    }
  ]
};

const privateRoutes: RouteConfig = {
  fullLayout: false,
  routes: [
    {
      path: "/",
      component: Journey,
      exact: true
    },
    {
      path: "/fitness",
      component: FitnressPage,
      exact: false
    },
    {
      path: "/create-fitness",
      component: CreateFitness,
      exact: false
    },
    {
      path: "/edit-fitness/:id",
      component: EditFitness,
      exact: false
    },
    {
      path: "/store",
      component: StorePage,
      exact: false
    },
    {
      path: "/users",
      component: UsersPage,
      exact: false
    },
    {
      path: "/admins",
      component: AdminsPage,
      exact: false
    },
    {
      path: `/create-task/:taskType`,
      component: CreateTask,
      exact: false
    },
    {
      path: `/edit-task/:id`,
      component: EditTask,
      exact: false
    },
    {
      path: `/onboarding`,
      component: Onboarding,
      exact: false
    }
  ]
};

/**
 * generate routes based on token existance
 * @param token - string or undefined
 * @return privateRoutes or publicRoutes routes based on token existance
 */
export const generateRouterConfig = (token?: string): RouteConfig => {
  if (token) {
    return privateRoutes;
  }
  return publicRoutes;
};
