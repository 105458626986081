// UI Models
import { FitnessPageUI } from "./fitness-page.ui";

// Domain Models
import { Fitness } from "../domain";

// App Enums
import { FitnessTypeEnum, FitnessDifficultyEnum } from "../../../enums/app";

// Utils
import { returnUrlFromFile } from "../../../utils/file.utils";

type FitnessUIType = {
  id?: string;
  name: string;
  type: FitnessTypeEnum;
  duration: number;
  difficulty: FitnessDifficultyEnum;
  image?: string | null;
  exercises: string[];
  equipments: string[];
  targets: string[];
  pages?: FitnessPageUI[];
};

export class FitnessUI {
  id?: string;
  name: string;
  type: FitnessTypeEnum | string;
  duration: number;
  difficulty: FitnessDifficultyEnum | string;
  image?: string | null;
  exercises: string[];
  equipments: string[];
  targets: string[];
  pages: FitnessPageUI[];

  constructor(data?: FitnessUIType | Fitness | null) {
    this.id = data?.id;
    this.name = data?.name || "";
    this.type = data?.type || "";
    this.duration = data?.duration || 0;
    this.difficulty = data?.difficulty || "";
    this.image = returnUrlFromFile(data?.image);
    this.exercises = data?.exercises || [];
    this.equipments = data?.equipments || [];
    this.targets = data?.targets || [];
    this.pages = data?.pages?.map(page => new FitnessPageUI(page)) || [];
  }
}
