import { Editor } from "react-draft-wysiwyg";

// Contracts
import { TextFormProps } from "../contracts/forms-based-on-type.types";

// Utils
import { convertStringToEditorState } from "../../../../../utils/global.utils";

// Styles
import classes from "../pageElementesForm.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TextForm = ({
  setFieldTouched,
  setFieldValue,
  touched,
  errors,
  values
}: TextFormProps) => {
  return (
    <>
      <div className={classes.editorContainer}>
        <Editor
          onBlur={() => {
            setFieldTouched("content", true);
          }}
          editorState={convertStringToEditorState(values.content)}
          onEditorStateChange={editorState =>
            setFieldValue("content", editorState)
          }
          toolbar={{
            fontSize: {
              options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 36, 48, 60, 72, 96],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },
            fontFamily: {
              options: [
                "ITCAvantGardeStd-XLt",
                "ITCAvantGardeStd-Bk",
                "ITCAvantGardeStd-Md",
                "ITCAvantGardeStd-Demi",
                'ITCAvantGardeStd-BkCnObl'
              ],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined
            }
          }}
        />
      </div>
      <p className={classes.errorMessage}>
        {touched.content && errors.content && errors.content}
      </p>
    </>
  );
};

export default TextForm;
