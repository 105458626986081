import { QuizCompletionMessage } from "../../../models/app/domain";

type QuizCompletionMessageUIType = {
  id?: string;
  lowerLimit?: number;
  upperLimit?: number;
  completionMessage?: string;
};

export class QuizCompletionMessageUI {
  id?: string;
  lowerLimit: number;
  upperLimit: number;
  completionMessage: string;

  constructor(data?: QuizCompletionMessageUIType | QuizCompletionMessage) {
    this.id = data?.id;
    this.lowerLimit = data?.lowerLimit || 0;
    this.upperLimit = data?.upperLimit || 0;
    this.completionMessage = data?.completionMessage || "";
  }
}
