import { useState, useEffect } from "react";
import { FormikHelpers, useFormik } from "formik";
import { toast } from "react-toastify";

//UI Models
import { AdminUI } from "../../../models/app/ui";

// components
import AdminForm from "./AdminForm";

// Api Enums
import { ResponseStatusType } from "../../../enums/api";

// Calls
import {
  fetchChosenAdmin,
  updateAdminUser
} from "../../../api/calls/users/users";

// validations
import { editAdminValidation } from "./edit-admin-form.validation";

const EditAdmin: React.FC<{
  postCreateUpdateAction: () => void;
  handleClose: () => void;
  adminId: string;
}> = props => {
  const [chosenAdmin, setChosenAdmin] = useState(new AdminUI());
  /**
   * Fetches shop item details
   * If success sets article for edit form
   * If fails should return notification
   */
  const getChosenAdmin = async (): Promise<void> => {
    const response = await fetchChosenAdmin(props.adminId);
    if (response.statusType !== ResponseStatusType.OK) {
      return;
    }
    setChosenAdmin(new AdminUI(response.user));
  };

  useEffect(() => {
    getChosenAdmin();
  }, []);

  /**
   * Makes updateStoreItem request
   * If success updates shop item in list of items
   * If fails should return notification
   */
  const updateShopItemHandler = async (
    values: AdminUI,
    { setSubmitting }: FormikHelpers<AdminUI>
  ): Promise<void> => {
    setSubmitting(true);
    if (values.accountId) {
      const response = await updateAdminUser(values, values.accountId);
      if (response.statusType !== ResponseStatusType.OK) {
        setSubmitting(false);
        return;
      }

      toast.success(response.message);
      props.postCreateUpdateAction();
      return;
    }

    setSubmitting(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: chosenAdmin,
    onSubmit: updateShopItemHandler,
    validate: editAdminValidation
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    handleChange,
    handleSubmit,
    isSubmitting
  } = formik;

  return (
    <AdminForm
      isEditForm
      formTitle="Edit admin user"
      submitText="Update"
      values={values}
      errors={errors}
      touched={touched}
      setFieldValue={setFieldValue}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      handleClose={props.handleClose}
    />
  );
};

export default EditAdmin;
