// Constants
import {
  BASE_URL,
  API_PATH,
  REQUEST_TIMEOUT
} from "../constants/api.constants";

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */

  url: string;

  /**
   * Milliseconds before we timeout the request.
   */
  timeout?: number;

  /**
   * Authorization token (JWT).
   */
  authToken?: string;
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: `${BASE_URL}${API_PATH}`,
  timeout: REQUEST_TIMEOUT
};
