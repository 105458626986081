//UI Models
import { ShopArticleUI } from "../../../models/app/ui";

const validURL = (url: string) => {
  var pattern = new RegExp(
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
  );
  return !!pattern.test(url);
};

export const shopFormValidation = ({
  name,
  price,
  link,
  image
}: ShopArticleUI): any => {
  const errors: any = {};

  if (!name) {
    errors.name = "* Required";
  }

  if (!price) {
    errors.price = "* Required";
  }

  if (!image) {
    errors.image = "* Required";
  }

  if (!link) {
    errors.link = "* Required";
  } else if (!validURL(link)) {
    errors.link = "Link must be correct url.";
  }

  return errors;
};
