// UI Models
import { ShopArticleUI } from "../../app/ui";

export class CreateShopItemRequest {
  name: string;
  image: string | null;
  price: string;
  link: string;

  constructor(shopArticleUI: ShopArticleUI) {
    this.name = shopArticleUI.name;
    this.image = shopArticleUI.image;
    this.price = shopArticleUI.price;
    this.link = shopArticleUI.link;
  }
}
