// DTOS
import { FitnessPageElementDto } from "../dtos";
// DOMAIN Models
import { FitnessPageElement } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type FitnessPageElementResponseDataType = {
  fitness_page_element: FitnessPageElementDto;
  statusType: ResponseStatusType;
};

export class FitnessPageElementResponse {
  fitnessPageElement: FitnessPageElement;
  statusType: ResponseStatusType;

  constructor(responseData: FitnessPageElementResponseDataType) {
    this.fitnessPageElement = new FitnessPageElement(
      responseData.fitness_page_element
    );
    this.statusType = ResponseStatusType.OK;
  }
}
