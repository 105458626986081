// Enums
import { TaskTypeEnum, TaskLifecycleTypeEnum } from "../../../enums/app";

// Ui Models
import { OnboardingPageUI } from "./onboarding-page-ui";

// Domain Models
import { Onboarding } from "../domain";

// Utils
import { isEmpty } from "../../../utils/global.utils";

type OnboardingUIType = {
  id?: string;
  title: string;
  taskType: TaskTypeEnum;
  lifecycleType: TaskLifecycleTypeEnum;
  taskPages?: OnboardingPageUI[];
};

export class OnboardingUI {
  id?: string;
  title: string;
  taskType: TaskTypeEnum | string;
  lifecycleType: TaskLifecycleTypeEnum;
  taskPages: OnboardingPageUI[];

  constructor(data?: OnboardingUIType | Onboarding | null) {
    this.id = data?.id;
    this.title = "Onboarding";
    this.taskType = "onboarding";
    this.lifecycleType = TaskLifecycleTypeEnum.PERSISTENT;
    this.taskPages =
      data?.taskPages?.map(page => new OnboardingPageUI(page)) || [];
  }
}
