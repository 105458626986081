// DTOS
import { OnboardingPageDto } from "../dtos";
// DOMAIN Models
import { OnboardingPage } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type OnbaordingPageResponseDataType = {
  task_page: OnboardingPageDto;
  statusType: ResponseStatusType;
};

export class OnbaordingPageResponse {
  onboardingPage: OnboardingPage;
  statusType: ResponseStatusType;

  constructor(responseData: OnbaordingPageResponseDataType) {
    this.onboardingPage = new OnboardingPage(responseData.task_page);
    this.statusType = ResponseStatusType.OK;
  }
}
