// DTOS
import { TaskDTO } from "../dtos";
// DOMAIN Models
import { Task } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type TaskResponseDataType = {
  task: TaskDTO;
  statusType: ResponseStatusType;
};

export class TaskResponse {
  task: Task;
  statusType: ResponseStatusType;

  constructor(responseData: TaskResponseDataType) {
    this.task = new Task(responseData.task);
    this.statusType = ResponseStatusType.OK;
  }
}
