// UI Models
import { QuizCompletionMessageUI } from "../../app/ui";

export class QuizCompletionMessage {
  id?: string;
  lower_limit: number;
  upper_limit: number;
  completion_message: string;

  constructor(quizCompletionMessageUI: QuizCompletionMessageUI) {
    this.id = quizCompletionMessageUI.id;
    this.lower_limit = quizCompletionMessageUI.lowerLimit;
    this.upper_limit = quizCompletionMessageUI.upperLimit;
    this.completion_message = quizCompletionMessageUI.completionMessage;
  }
}
