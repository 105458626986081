import { EditorState } from "draft-js";

// Enums
import {
  TaskQuestionsTypesEnum,
  QuestionAnswerTypeEnum
} from "../../../enums/app";

// Ui Models
import { TaskQuestionUI } from "../../app/ui";

// Requests Models
import { QuestionAnswerRequest } from "./question-answer.request";

// Utils
import { isEmpty, valueOrNull } from "../../../utils/global.utils";

export class QuestionRequest {
  id?: string;
  type: TaskQuestionsTypesEnum | string;
  title: EditorState | string;
  image: string | null;
  explanation: string | null;
  is_multi_select?: boolean;
  is_skippable?: boolean;
  answer_type: QuestionAnswerTypeEnum;
  answers?: QuestionAnswerRequest[] | null;

  constructor(taskQuestionUI: TaskQuestionUI) {
    this.id = taskQuestionUI.id;
    this.type = taskQuestionUI.type;
    this.title = taskQuestionUI.title;
    this.image = valueOrNull(taskQuestionUI.image);
    this.explanation = valueOrNull(taskQuestionUI.explanation);
    this.is_multi_select = taskQuestionUI.isMultiSelect;
    this.is_skippable = taskQuestionUI.isSkippable;
    this.answer_type = taskQuestionUI.answerType;
    this.answers = !isEmpty(taskQuestionUI.answers)
      ? taskQuestionUI.answers?.map(answer => new QuestionAnswerRequest(answer))
      : null;
  }
}
