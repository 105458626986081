import { useHistory } from "react-router";

// Theme
import { Button, Typography, Card } from "@mui/material";

// Styles
import classes from "./not-found.module.css";

const NotFound = () => {
  const history = useHistory();
  return (
    <div className={classes.page}>
      <Card className={classes.card}>
        <Typography className={classes.title} variant="h2">
          404 Not Found
        </Typography>
        <Button
          onClick={() => history.goBack()}
          className={classes.button}
          variant="contained"
        >
          Go Back
        </Button>
      </Card>
    </div>
  );
};

export default NotFound;
