import Typography from "@mui/material/Typography";

// styles
import classes from "./PageHeader.module.css";

const PageHeader: React.FC<{ title: string }> = (props) => {
  return (
    <header className={classes.header}>
      <Typography className={classes.headerTitle} variant="h1">
        {props.title}
      </Typography>
      {props.children}
    </header>
  );
};

export default PageHeader;
