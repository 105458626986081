import { UserDTO } from "../../api/dtos";

export class User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  accountId: string;
  age?: number;
  height?: number;
  weight?: number;
  currentTaskId?: string;

  constructor(entity: UserDTO) {
    this.id = entity.id;
    this.email = entity.email;
    this.firstName = entity.first_name;
    this.lastName = entity.last_name;
    this.accountId = entity.account_id;
    this.age = entity.age;
    this.height = entity.height;
    this.weight = entity.weight;
    this.currentTaskId = entity.current_task_id;
  }
}
