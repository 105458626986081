// App Enums
import { FitnessTypeEnum, FitnessDifficultyEnum } from "../../enums/app";

type TypeOptions = {
  value: FitnessTypeEnum;
  name: string;
};

export const typeOptions: TypeOptions[] = [
  {
    value: FitnessTypeEnum.EXERCISE,
    name: "Exercise"
  },
  {
    value: FitnessTypeEnum.WORKOUT,
    name: "Workout"
  }
];

type DifficultyOptions = {
  value: FitnessDifficultyEnum;
  name: string;
};

export const difficultyOptions: DifficultyOptions[] = [
  {
    value: FitnessDifficultyEnum.BEGINNER,
    name: "Beginner"
  },
  {
    value: FitnessDifficultyEnum.INTERMEDIATE,
    name: "Intermediate"
  }
];
