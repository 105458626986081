import { useState, useEffect } from "react";
import { FormikHelpers, useFormik } from "formik";
import { toast } from "react-toastify";

//UI Models
import { ShopArticleUI } from "../../../models/app/ui";

// Domain Models
import { ShopArticle } from "../../../models/app/domain";

// components
import ItemForm from "./ItemForm";

// Api Enums
import { ResponseStatusType } from "../../../enums/api";

// Calls
import {
  fetchStoreItemDetails,
  updateStoreItem
} from "../../../api/calls/store/store";

// validations
import { shopFormValidation } from "./ShopForm.validation";

const EditItem: React.FC<{
  updateArticle: (newArticle: ShopArticle) => void;
  handleClose: () => void;
  choosenArticleId: string;
}> = props => {
  const [chosenArticle, setChosenArticle] = useState(new ShopArticleUI());
  /**
   * Fetches shop item details
   * If success sets article for edit form
   * If fails should return notification
   */
  const getChosenArticle = async (): Promise<void> => {
    const response = await fetchStoreItemDetails(props.choosenArticleId);
    if (response.statusType !== ResponseStatusType.OK) {
      return;
    }
    setChosenArticle(new ShopArticleUI(response.shopArticle));
  };

  useEffect(() => {
    getChosenArticle();
  }, []);

  /**
   * Makes updateStoreItem request
   * If success updates shop item in list of items
   * If fails should return notification
   */
  const updateShopItemHandler = async (
    values: ShopArticleUI,
    { setSubmitting }: FormikHelpers<ShopArticleUI>
  ): Promise<void> => {
    setSubmitting(true);
    const response = await updateStoreItem(values, props.choosenArticleId);
    if (response.statusType !== ResponseStatusType.OK) {
      setSubmitting(false);
      return;
    }

    toast.success("Article successfully updated");
    props.updateArticle(response.shopArticle);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: chosenArticle,
    onSubmit: updateShopItemHandler,
    validate: shopFormValidation
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    handleChange,
    handleSubmit,
    isSubmitting
  } = formik;

  return (
    <ItemForm
      formTitle="Edit shop item"
      submitText="Update"
      values={values}
      errors={errors}
      touched={touched}
      setFieldValue={setFieldValue}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      handleClose={props.handleClose}
    />
  );
};

export default EditItem;
