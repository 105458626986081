import { useState } from "react";

// Theme
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

// Icons
import { FileIcon, Trash } from "../../../SVGS";

// Calls
import { fileUpload } from "../../../api/calls/file-upload/fileUpload";

// Api Enums
import { ResponseStatusType } from "../../../enums/api";

// Contracts
import { FileUploadProps } from "./contracts/file-upload.types";

// Utils
import { errorHandler } from "../../../utils/error.utils";

// Styles
import classes from "./FileUpload.module.css";

const FileUpload = ({
  className,
  inputValueClassName,
  buttonsClassName,
  placeholder = "Upload file",
  name,
  id,
  value,
  touched,
  error,
  setFieldValue
}: FileUploadProps) => {
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  /**
   * Clicks hidden file input
   */
  const openFileUpload = (): void => {
    const input = document.getElementById(id);
    if (input) input.click();
  };

  /**
   * Upload File to server
   * Starts Loader
   * Set url from response to state
   * @param e - element
   */
  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const input = document.getElementById(id) as HTMLInputElement;
    const target = e.target as HTMLInputElement;
    const file: File | null = (target.files as FileList)[0];
    setIsUploadingImage(true);
    const response = await fileUpload(file);
    setIsUploadingImage(false);
    if (response.statusType !== ResponseStatusType.OK) {
      errorHandler(response);
      input.value = "";
      return;
    }
    setFieldValue(name, response.url);
  };

  /**
   * Removes url from state nad input value
   */
  const removeFile = () => {
    const input = document.getElementById(id) as HTMLInputElement;
    if (input) {
      input.value = "";
    }
    setFieldValue(name, null);
  };

  return (
    <>
      <div
        className={`${classes.inputContainer} ${
          touched && error && classes.error
        } ${className}`}
      >
        <div className={classes.valueContainer}>
          {value && <FileIcon style={{ marginRight: 8 }} />}
          <div
            className={`${classes.value} ${
              value && classes.valueTextColor
            } ${inputValueClassName}`}
          >
            {isUploadingImage ? (
              <Box
                sx={{
                  display: "flex"
                }}
              >
                <CircularProgress size={"20px"} />
              </Box>
            ) : value ? (
              value
            ) : (
              placeholder
            )}
          </div>
        </div>
        {value ? (
          <Button
            variant="contained"
            sx={{ height: "30px" }}
            color="error"
            onClick={removeFile}
            className={`${classes.delete} ${buttonsClassName}`}
          >
            <Trash />
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{ height: "30px" }}
            color="primary"
            onClick={openFileUpload}
            className={`${buttonsClassName}`}
          >
            + Add File
          </Button>
        )}
      </div>
      <input
        type="file"
        name={name}
        id={id}
        onChange={e => handleChange(e)}
        style={{ display: "none" }}
      />
      <p className={classes.errorMessage}>{touched && error && error}</p>
    </>
  );
};

export default FileUpload;
