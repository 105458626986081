import { AdminUI } from "../../app/ui";

export class UpdateAdminRequest {
  first_name: string;
  last_name: string;
  email: string;

  constructor(adminUI: AdminUI) {
    this.first_name = adminUI.firstName;
    this.last_name = adminUI.lastName;
    this.email = adminUI.email;
  }
}
