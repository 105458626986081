export enum TaskPageElementTypeEnum {
  TEXT = "text",
  IMAGE = "image",
  VIDEO = "video",
  AUDIO = "audio",
  DEEPLINK = "deeplink",
  QUESTION = "question",
  CONTINUE_BUTTON = "continue_button",
  COMPLETE_BUTTON = "complete_button"
}
