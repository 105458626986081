import { EditorState } from "draft-js";

// Enums
import { TaskPageElementTypeEnum } from "../../../enums/app";

// UI Models
import { TaskPageElementUI } from "../../app/ui";

// Request Models
import { QuestionRequest } from "./question.request";

// Utils
import { isEmpty, valueOrNull } from "../../../utils/global.utils";

export class TaskPageElementRequest {
  id?: string;
  sequence_number: number;
  type: TaskPageElementTypeEnum | string;
  content: string | null;
  color: string | null;
  file: string | null;
  cover_image: string | null;
  question: QuestionRequest | null;

  constructor(taskPageElementUI: TaskPageElementUI) {
    this.id = taskPageElementUI.id;
    this.sequence_number = taskPageElementUI.sequenceNumber;
    this.type = taskPageElementUI.type;
    this.color = valueOrNull(taskPageElementUI.color);
    this.content = valueOrNull(taskPageElementUI.content);
    this.file = valueOrNull(taskPageElementUI.file);
    this.cover_image = valueOrNull(taskPageElementUI.coverImage);
    this.question = !isEmpty(taskPageElementUI.question)
      ? taskPageElementUI.question !== null
        ? new QuestionRequest(taskPageElementUI.question)
        : null
      : null;
  }
}
