// DTOS
import { TaskDTO } from "../dtos";
// DOMAIN Models
import { Task } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type TasksResponseDataType = {
  tasks: TaskDTO[];
  statusType: ResponseStatusType;
};

export class TasksResponse {
  tasks: Task[];
  statusType: ResponseStatusType;

  constructor(responseData: TasksResponseDataType) {
    this.tasks = responseData.tasks.map(task => new Task(task));
    this.statusType = ResponseStatusType.OK;
  }
}
