import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

// Theme
import {
  Button,
  Modal,
  TextField,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { CloseOutlined } from "@mui/icons-material";

// Components
import PageHeader from "../../components/pageHeader/PageHeader";

// Enums
import { TaskTypeEnum } from "../../enums/app";
import { ResponseStatusType } from "../../enums/api";

// Calls
import { getTasks, reorderLesson } from "../../api/calls/journey/get-tasks";
import { deleteTask } from "../../api/calls/task/task";

// Config
import { Row, createData, generateColumns } from "./journey-table.config";

// Utils
import { updateObject, isEmpty } from "../../utils/global.utils";

// Styles
import classes from "./journey.module.css";

const Journey = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [reorderState, setReorderState] = useState<{
    isOpen: boolean;
    lesson?: Row;
  }>({
    isOpen: false,
    lesson: undefined
  });
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [taskType, setTaskType] = useState(TaskTypeEnum.LESSON);
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState<Row[]>([]);

  /**
   * Sets page number for paggination
   * @param newPage - number
   */
  const handleChangePage = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  /**
   * Sets number of items in table per page
   * @param event - any
   */
  const handleChangeRowsPerPage = (event: any): void => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /**
   * Removes chosen task from list of tasks
   * Requires users confirmation
   * @param id - string
   */
  const removeTask = async (id: string): Promise<void> => {
    const result = window.confirm("Are u sure u want to delete this task?");
    if (!result) {
      return;
    }
    const response = await deleteTask(id);
    if (response.statusType !== ResponseStatusType.OK) {
      return;
    }
    toast.success(response.message);
    fetchTasks();
  };

  /**
   * Fetch tasks for list of tasks and create data for list
   */
  const fetchTasks = async (): Promise<void> => {
    setIsLoading(true);
    const response = await getTasks(taskType);
    if (response.statusType !== ResponseStatusType.OK) {
      return;
    }
    setRows(
      response.tasks.map(task =>
        createData(
          task.sequenceNumber,
          task.id,
          task.title,
          task.taskType,
          task.lessonLikes,
          task.lessonDislikes
        )
      )
    );
    setIsLoading(false);
  };

  const changeLessonNumber = async (
    number?: number | null,
    id?: string
  ): Promise<void> => {
    if (isEmpty(id) && isEmpty(number)) {
      return;
    }

    const response = await reorderLesson(id, number);
    if (response.statusType !== ResponseStatusType.OK) {
      return;
    }
    toast.success("Reorder completed!");
    fetchTasks();
    setReorderState({
      isOpen: false,
      lesson: undefined
    });
  };

  useEffect(() => {
    fetchTasks();
    setPage(0);
  }, [taskType]);

  return (
    <>
      <PageHeader title="Journey">
        <div>
          <Button
            sx={{ marginLeft: "10px" }}
            onClick={() => history.push(`create-task/${TaskTypeEnum.LESSON}`)}
            variant="contained"
          >
            Add new lesson
          </Button>
          <Button
            sx={{ marginLeft: "10px" }}
            onClick={() => history.push(`create-task/${TaskTypeEnum.QUIZ}`)}
            variant="contained"
          >
            Add new quiz
          </Button>
          <Button
            sx={{ marginLeft: "10px" }}
            onClick={() => history.push(`create-task/${TaskTypeEnum.MESSAGE}`)}
            variant="contained"
          >
            Add new message
          </Button>
          <Button
            sx={{ marginLeft: "10px" }}
            onClick={() => history.push(`create-task/${TaskTypeEnum.KPI}`)}
            variant="contained"
          >
            Add new KPI
          </Button>
        </div>
      </PageHeader>
      <Paper
        sx={{
          width: "100%",
          padding: "20px 10px",
          overflow: "hidden",
          marginTop: "20px"
        }}
      >
        <Typography className={classes.filtersLabel} variant="h6">
          Filters
        </Typography>
        <div className={classes.filtersContainer}>
          <FormControlLabel
            className={classes.checkBox}
            control={
              <Checkbox
                checked={taskType === TaskTypeEnum.LESSON}
                onChange={() => setTaskType(TaskTypeEnum.LESSON)}
                name="Lesson"
              />
            }
            label="Lesson"
          />
          <FormControlLabel
            className={classes.checkBox}
            control={
              <Checkbox
                checked={taskType === TaskTypeEnum.MESSAGE}
                onChange={() => setTaskType(TaskTypeEnum.MESSAGE)}
                name="Message"
              />
            }
            label="Message"
          />
          <FormControlLabel
            className={classes.checkBox}
            control={
              <Checkbox
                checked={taskType === TaskTypeEnum.QUIZ}
                onChange={() => setTaskType(TaskTypeEnum.QUIZ)}
                name="Quiz"
              />
            }
            label="Quiz"
          />
          <FormControlLabel
            className={classes.checkBox}
            control={
              <Checkbox
                checked={taskType === TaskTypeEnum.KPI}
                onChange={() => setTaskType(TaskTypeEnum.KPI)}
                name="Kpi"
              />
            }
            label="Kpi"
          />
        </div>
      </Paper>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100vh - 420px)"
          }}
        >
          <CircularProgress size={"50px"} />
        </Box>
      ) : (
        <>
          <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
            <TableContainer sx={{ maxHeight: "calc(100vh - 220px)" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {generateColumns(taskType).map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {generateColumns(taskType).map((column: any) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "id" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end"
                                    }}
                                  >
                                    {taskType === TaskTypeEnum.LESSON && (
                                      <Button
                                        onClick={() =>
                                          setReorderState({
                                            isOpen: true,
                                            lesson: row
                                          })
                                        }
                                        variant="contained"
                                        color="success"
                                      >
                                        Reorder
                                      </Button>
                                    )}
                                    <Button
                                      onClick={() =>
                                        history.push(`edit-task/${value}`)
                                      }
                                      variant="contained"
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      onClick={() => removeTask(value)}
                                      variant="contained"
                                      color="error"
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[7, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <Modal
            open={reorderState.isOpen}
            onClose={() =>
              setReorderState({
                isOpen: false,
                lesson: undefined
              })
            }
          >
            <Box className={classes.modalBox}>
              <div className={classes.formModalHeader}>
                <Typography variant="h5">
                  {reorderState.lesson?.title}
                </Typography>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setReorderState({
                      isOpen: false,
                      lesson: undefined
                    })
                  }
                >
                  <CloseOutlined />
                </div>
              </div>
              <TextField
                fullWidth
                id="standard-number"
                label="Lesson position"
                type="number"
                name="position"
                variant="standard"
                value={reorderState.lesson?.sequenceNumber}
                onChange={e =>
                  setReorderState(prevState =>
                    updateObject(prevState, {
                      lesson: {
                        ...prevState.lesson,
                        sequenceNumber: e.target.value
                      }
                    })
                  )
                }
              />
              <Button
                onClick={() => {
                  changeLessonNumber(
                    reorderState.lesson?.sequenceNumber,
                    reorderState.lesson?.id
                  );
                }}
                style={{ marginTop: "40px" }}
                variant="contained"
              >
                Save changes
              </Button>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default Journey;
