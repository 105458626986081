import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// Contracts
import { CustomColorPickerProps } from "./contracts/custom-color-picker.types";
import { useEffect } from "react";

const options = ["#E7AFC0 - Pink", "#214357 - Blue", "#A6A6A6 - Gray"];

const CustomColorPicker = ({
  label,
  name,
  onChange,
  onBlur,
  value,
  error
}: CustomColorPickerProps) => {
  useEffect(() => {
    const close = document.getElementsByClassName(
      "MuiAutocomplete-endAdornment"
    )[0];

    const clearColorPicker = (): void => {
      onChange(name, "");
    };

    close.addEventListener("click", clearColorPicker);

    return () => {
      close.removeEventListener("click", clearColorPicker);
    };
  }, []);
  return (
    <Autocomplete
      id="free-solo-demo"
      freeSolo
      value={value}
      onChange={(e, color) => {
        let chosenColor = color;
        if (color) {
          chosenColor = color.substring(0, 7);
        }
        onChange(name, chosenColor);
      }}
      options={options.map(option => option)}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          name={name}
          error={error}
          onBlur={() => onBlur(name, true)}
          onChange={e => onChange(name, e.target.value)}
          fullWidth
          label={label}
        />
      )}
    />
  );
};

export default CustomColorPicker;
