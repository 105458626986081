// UI Models
import { TaskPageElementUI } from "./task-page-element.ui";

// Domain Models
import { TaskPage } from "../domain";

type ITaskPageUI = {
  id?: string;
  pageNumber?: number;
  taskPageElements?: TaskPageElementUI[];
  isFirstPageFront?: boolean;
  isLastPageFront?: boolean;
};

export class TaskPageUI {
  id?: string;
  pageNumber: number;
  taskPageElements?: TaskPageElementUI[];
  isFirstPageFront: boolean;
  isLastPageFront: boolean;

  constructor(data?: ITaskPageUI | TaskPage | null) {
    this.id = data?.id;
    this.pageNumber = data?.pageNumber || 0;
    this.taskPageElements =
      data?.taskPageElements?.map(element => new TaskPageElementUI(element)) ||
      [];
    this.isFirstPageFront = data?.isFirstPageFront || false;
    this.isLastPageFront = data?.isLastPageFront || false;
  }
}
