import * as yup from "yup";

// Enums
import {
  TaskTypeEnum,
  TaskLifecycleTypeEnum,
  TaskQuestionsTypesEnum
} from "../../../../enums/app";

export const taskValidationSchema = () =>
  yup.lazy(values => {
    if (values.taskType === TaskTypeEnum.MESSAGE) {
      if (values.lifecycleType === TaskLifecycleTypeEnum.SCHEDULED) {
        return yup.object().shape({
          title: yup.string().required("* Required"),
          titleColor: yup
            .string()
            .typeError("* Required")
            .required("* Required")
            .test({
              message: "* Not valid color",
              test: function (value) {
                if (value) return /^#[0-9A-F]{6}$/i.test(value);
                return false;
              }
            }),
          scheduledFor: yup
            .date()
            .typeError("* Required")
            .required("* Required"),
          taskPages: yup.array().min(1, "* Task must have at least one page"),
          repetitionCount: yup.number().min(0, "* Minimum is 0"),
          repetitionInterval:
            values.repetitionCount > 0
              ? yup
                  .number()
                  .min(0, "* Minimum is 0")
                  .test(
                    "has text",
                    "* Interval must be bigger then 0 if count is set",
                    value => {
                      if (!value) return false;
                      return value > 0;
                    }
                  )
              : yup.number().min(0, "* Minimum is 0").nullable()
        });
      }

      if (values.lifecycleType === TaskLifecycleTypeEnum.TRIGGERED) {
        return yup.object().shape({
          title: yup.string().required("* Required"),
          titleColor: yup
            .string()
            .typeError("* Required")
            .required("* Required")
            .test({
              message: "* Not valid color",
              test: function (value) {
                if (value) return /^#[0-9A-F]{6}$/i.test(value);
                return false;
              }
            }),
          triggeredByTaskId: yup.string().required("* Required"),
          taskPages: yup.array().min(1, "* Task must have at least one page"),
          repetitionCount: yup.number().min(0, "* Minimum is 0"),
          repetitionInterval:
            values.repetitionCount > 0
              ? yup
                  .number()
                  .min(0, "* Minimum is 0")
                  .test(
                    "has text",
                    "* Interval must be bigger then 0 if count is set",
                    value => {
                      if (!value) return false;
                      return value > 0;
                    }
                  )
              : yup.number().min(0, "* Minimum is 0").nullable()
        });
      }

      return yup.object().shape({
        title: yup.string().required("* Required"),
        titleColor: yup
          .string()
          .typeError("* Required")
          .required("* Required")
          .test({
            message: "* Not valid color",
            test: function (value) {
              if (value) return /^#[0-9A-F]{6}$/i.test(value);
              return false;
            }
          }),
        lifecycleType: yup
          .string()
          .typeError("* Required")
          .required("* Required"),
        taskPages: yup.array().min(1, "* Task must have at least one page"),
        repetitionCount: yup.number().min(0, "* Minimum is 0"),
        repetitionInterval:
          values.repetitionCount > 0
            ? yup
                .number()
                .min(0, "* Minimum is 0")
                .test(
                  "has text",
                  "* Interval must be bigger then 0 if count is set",
                  value => {
                    if (!value) return false;
                    return value > 0;
                  }
                )
            : yup.number().min(0, "Minimum is 0").nullable()
      });
    }

    if (values.taskType === TaskTypeEnum.LESSON) {
      return yup.object().shape({
        title: yup.string().required("* Required"),
        titleColor: yup
          .string()
          .typeError("* Required")
          .required("* Required")
          .test({
            message: "* Not valid color",
            test: function (value) {
              if (value) return /^#[0-9A-F]{6}$/i.test(value);
              return false;
            }
          }),
        taskPages: yup.array().min(1, "* Task must have at least one page")
      });
    }

    if (values.taskType === TaskTypeEnum.QUIZ) {
      if (values.quizTypeFront === TaskQuestionsTypesEnum.POP) {
        return yup.object().shape({
          quizTypeFront: yup.string().required("* Required"),
          titleColor: yup
            .string()
            .typeError("* Required")
            .required("* Required")
            .test({
              message: "* Not valid color",
              test: function (value) {
                if (value) return /^#[0-9A-F]{6}$/i.test(value);
                return false;
              }
            }),
          triggeredByTaskId: yup.string().required("* Required"),
          repetitionCount: yup.number().min(0, "* Minimum is 0"),
          repetitionInterval:
            values.repetitionCount > 0
              ? yup
                  .number()
                  .min(0, "* Minimum is 0")
                  .test(
                    "has text",
                    "* Interval must be bigger then 0 if count is set",
                    value => {
                      if (!value) return false;
                      return value > 0;
                    }
                  )
              : yup.number().min(0, "Minimum is 0").nullable(),
          taskPages: yup.array().min(3, "* Quiz must have at least three pages")
        });
      }

      if (values.quizTypeFront === TaskQuestionsTypesEnum.CONDITIONAL) {
        return yup.object().shape({
          quizTypeFront: yup.string().required("* Required"),
          titleColor: yup
            .string()
            .typeError("* Required")
            .required("* Required")
            .test({
              message: "* Not valid color",
              test: function (value) {
                if (value) return /^#[0-9A-F]{6}$/i.test(value);
                return false;
              }
            }),
          triggeredByTaskId: yup.string().required("* Required"),
          repetitionCount: yup.number().min(0, "* Minimum is 0"),
          repetitionInterval:
            values.repetitionCount > 0
              ? yup
                  .number()
                  .min(0, "* Minimum is 0")
                  .test(
                    "has text",
                    "* Interval must be bigger then 0 if count is set",
                    value => {
                      if (!value) return false;
                      return value > 0;
                    }
                  )
              : yup.number().min(0, "Minimum is 0").nullable(),
          taskPages: yup.array().min(2, "* Quiz must have at least two pages")
        });
      }

      if (values.quizTypeFront === TaskQuestionsTypesEnum.CONSULTATION) {
        return yup.object().shape({
          quizTypeFront: yup.string().required("* Required"),
          title: yup.string().required("* Required"),
          titleColor: yup
            .string()
            .typeError("* Required")
            .required("* Required")
            .test({
              message: "* Not valid color",
              test: function (value) {
                if (value) return /^#[0-9A-F]{6}$/i.test(value);
                return false;
              }
            }),
          triggeredByTaskId: yup.string().required("* Required"),
          repetitionCount: yup.number().min(0, "* Minimum is 0"),
          repetitionInterval:
            values.repetitionCount > 0
              ? yup
                  .number()
                  .min(0, "* Minimum is 0")
                  .test(
                    "has text",
                    "* Interval must be bigger then 0 if count is set",
                    value => {
                      if (!value) return false;
                      return value > 0;
                    }
                  )
              : yup.number().min(0, "Minimum is 0").nullable(),
          taskPages: yup
            .array()
            .min(3, "* Quiz must have at least three pages"),
          quizCompletionMessages: yup
            .array()
            .typeError("* Required")
            .of(
              yup.object().shape({
                lowerLimit: yup
                  .number()
                  .typeError("* Required")
                  .min(0, "* Minimum is 0"),
                upperLimit: yup
                  .number()
                  .typeError("* Required")
                  .min(0, "* Minimum is 0")
                  .test({
                    message: "* Upper limit must be bigger then lower limit",
                    test: function (value) {
                      if (!value) return false;
                      return value > this.parent.lowerLimit;
                    }
                  }),
                completionMessage: yup.string().required("* Required")
              })
            )
            .required("* Required")
            .min(2, "* There must be at least two messages")
        });
      }

      return yup.object().shape({
        quizTypeFront: yup.string().required("* Required")
      });
    }

    if (
      values.taskType !== TaskTypeEnum.QUIZ &&
      values.taskType !== TaskTypeEnum.MESSAGE &&
      values.taskType !== TaskTypeEnum.LESSON
    ) {
      return yup.object().shape({
        title: yup.string().required("* Required"),
        titleColor: yup
          .string()
          .typeError("* Required")
          .required("* Required")
          .test({
            message: "* Not valid color",
            test: function (value) {
              if (value) return /^#[0-9A-F]{6}$/i.test(value);
              return false;
            }
          }),
        kpiType: yup.string().typeError("* Required").required("* Required"),
        triggeredByTaskId: yup.string().required("* Required")
      });
    }

    return yup.object().shape({
      title: yup.string().required("* Required")
    });
  });
