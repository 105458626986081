import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";

import { MenuProps, getStyles } from "./multi-select-drop-down-utils";

// Utils
import { isEmpty } from "../../../utils/global.utils";

// Contracts
import { MultiSelectProps } from "./contracts/multi-select-drop-down.types";

const MultipleSelectChip = ({
  label,
  name,
  value,
  setFieldValue,
  options
}: MultiSelectProps) => {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>(value);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value }
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setFieldValue(name, typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label={label} />}
        renderValue={selected => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5
            }}
          >
            {selected.map(value => (
              <Chip
                key={value}
                label={
                  !isEmpty(options)
                    ? options?.find(option => option.id === value)?.name
                    : "unknown"
                }
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options?.map(option => (
          <MenuItem
            key={option.id}
            value={option.id}
            style={getStyles(name, personName, theme)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelectChip;
