import { ApiResponse } from "apisauce";

// Api
import { api } from "../../api";
import { getGeneralApiProblem } from "../../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem
} from "../../general-api-problems/general-api-problems.types";

// UI Models
import { OnboardingPageUI } from "../../../models/app/ui";

// Request Models
import { UpdateOnboardingRequest } from "../../../models/api/requests";

// Response Models
import {
  OnboardingResponse,
  OnboardingResponseDataType
} from "../../../models/api/responses/onboarding.response";

import {
  OnbaordingPageResponse,
  OnbaordingPageResponseDataType
} from "../../../models/api/responses/onboarding-page.response";

import {
  OnboardingPagesResponse,
  OnboardingPagesResponseDataType
} from "../../../models/api/responses/onboarding-pages.response";

import {
  OnboardingPageElementResponse,
  OnboardingPageElementResponseDataType
} from "../../../models/api/responses/onboarding-page-element.response";

import {
  OnboardingPageElementsResponse,
  OnboardingPageElementsResponseDataType
} from "../../../models/api/responses/onboarding-page-elements.response";

import {
  MessageResponseType,
  MessageResponse
} from "../../../models/api/responses/message.response";

/**
 * Fetch onboarding details
 * @returns OnboardingResponse or GeneralApiProblem
 */
export const fetchOnboarding = async (
  perBatch: string,
  batch: string
): Promise<OnboardingResponse | GeneralApiProblem> => {
  const response: ApiResponse<OnboardingResponseDataType, ExceptionPayload> =
    await api.apisauce.get(
      `/task/onboarding?perBatch=${perBatch}&batch=${batch}`
    );

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }
  const responseData = response.data as OnboardingResponseDataType;
  return new OnboardingResponse(responseData);
};

/**
 * Change page number request
 * @param onboardingPageId - string
 * @param pageNumber - number
 * @returns MessageResponse or GeneralApiProblem
 */
export const reorderOnboardingPage = async (
  onboardingPageId?: string,
  pageNumber?: number
): Promise<OnboardingPagesResponse | GeneralApiProblem> => {
  const response: ApiResponse<
    OnboardingPagesResponseDataType,
    ExceptionPayload
  > = await api.apisauce.post(
    `/task/page/reorder?id=${onboardingPageId}&position=${pageNumber}`
  );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as OnboardingPagesResponseDataType;
  return new OnboardingPagesResponse(responseData);
};

/**
 * Get onboarding page details request
 * @param id - string
 * @returns OnbaordingPageResponse or GeneralApiProblem
 */
export const getOnboardingPage = async (
  id: string
): Promise<OnbaordingPageResponse | GeneralApiProblem> => {
  const response: ApiResponse<
    OnbaordingPageResponseDataType,
    ExceptionPayload
  > = await api.apisauce.get(`/task/page/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }
  const responseData = response.data as OnbaordingPageResponseDataType;
  return new OnbaordingPageResponse(responseData);
};

/**
 * Get task page details request
 * @param id - string
 * @returns TaskPageResponse or GeneralApiProblem
 */
export const getOnboardingPageElement = async (
  id: string
): Promise<OnboardingPageElementResponse | GeneralApiProblem> => {
  const response: ApiResponse<
    OnboardingPageElementResponseDataType,
    ExceptionPayload
  > = await api.apisauce.get(`/task/page-element/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }
  const responseData = response.data as OnboardingPageElementResponseDataType;
  return new OnboardingPageElementResponse(responseData);
};

/**
 * Update onboarding request
 * @param taskUI - onboardingPageUI
 * @param onboardingId - string
 * @param onboardingPageId - string | string
 * @returns MessageResponse or GeneralApiProblem
 */
export const updateOnboarding = async (
  onboardingPageUI: OnboardingPageUI,
  onboardingId: string,
  onboardingPageId?: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const requestPayload = {
    task_pages: [
      new UpdateOnboardingRequest(
        onboardingPageUI,
        onboardingId,
        onboardingPageId
      )
    ]
  };

  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.patch(`/task/onboarding`, requestPayload);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};

/**
 * Change page number request
 * @param elementId - string
 * @param pageNumber - number
 * @returns OnboardingPageElementsResponse or GeneralApiProblem
 */
export const reorderOnboardingPageElements = async (
  elementId?: string,
  pageNumber?: number
): Promise<OnboardingPageElementsResponse | GeneralApiProblem> => {
  const response: ApiResponse<
    OnboardingPageElementsResponseDataType,
    ExceptionPayload
  > = await api.apisauce.post(
    `/task/page-element/reorder?id=${elementId}&position=${pageNumber}`
  );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as OnboardingPageElementsResponseDataType;
  return new OnboardingPageElementsResponse(responseData);
};

/**
 * Delete page request
 * @param id - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const deletePage = async (
  id: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/task/task-page/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};

/**
 * Delete task page element request
 * @param id - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const deletePageElement = async (
  id: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/task/task-page-element/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};

/**
 * Delete task page element question answer request
 * @param id - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const deleteOnboardingPageElementQuestionAnswer = async (
  id: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/task/answer/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};
