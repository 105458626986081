import { RoleDTO } from "../../api/dtos";

export class Role {
  id: string;
  name: string;

  constructor(entity: RoleDTO) {
    this.id = entity.id;
    this.name = entity.name;
  }
}
