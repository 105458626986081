import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

// Components
import PageHeader from "../../components/pageHeader/PageHeader";

// Containers
import TaskForm from "../../containers/forms/task/taskForm/task.form";
import PageForm from "../../containers/forms/task/pageForm/page.form";
import PageElementForm from "../../containers/forms/task/pageElementForm/pageElement.form";

// Enums
import {
  TaskFormTypeEnum,
  TaskTypeEnum,
  TaskPageElementTypeEnum
} from "../../enums/app";
import { ResponseStatusType } from "../../enums/api";

// Ui Models
import {
  TaskUI,
  TaskPageUI,
  TaskPageElementUI,
  TaskQuestionUI,
  TaskQuestionAnswerUI
} from "../../models/app/ui";

// Domain Models
import { TaskOption } from "../../models/app/domain";

// Calls
import { getTaskOptions } from "../../api/calls/dropdown-options/task-options";
import {
  deletePage,
  deletePageElement,
  deleteTaskPageElementQuestionAnswer,
  deleteQuizCompletionMessage
} from "../../api/calls/task/task";

// Utils
import * as utils from "../../utils/global.utils";

// Page types
import { StateStructureType, RouteParams } from "./edit-task.type";

const EditTask = () => {
  const { id } = useParams<RouteParams>();

  const [taskOptions, setTaskOptions] = useState<TaskOption[]>([]);
  const [pageState, setPageState] = useState<StateStructureType>({
    previousFormTab: TaskFormTypeEnum.TASK_FORM,
    formTab: TaskFormTypeEnum.TASK_FORM,
    taskTypeLabel: "",
    chosenTaskPageId: undefined,
    chosenPage: undefined,
    chosenPageElementId: undefined,
    chosenPageElement: undefined,
    quizLastPageId: undefined
  });

  const isQuiz =
    !utils.isEmpty(pageState.taskTypeLabel) &&
    pageState.taskTypeLabel === TaskTypeEnum.QUIZ;

  /**
   * Fetches Lessons and sets response in taskOptions
   */
  const fetchTaskOptions = async (): Promise<void> => {
    const response = await getTaskOptions(
      TaskTypeEnum.LESSON,
      "availableLessons"
    );
    if (response.statusType !== ResponseStatusType.OK) {
      return;
    }
    setTaskOptions(response.options);
  };

  useEffect(() => {
    fetchTaskOptions();
  }, []);

  // Quiz only
  const setQuizLastPageId = (quizLastPageId?: string): void => {
    if (!quizLastPageId) {
      return;
    }
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        quizLastPageId: quizLastPageId
      })
    );
  };

  /**
   * Sets formTab and previousFormTab
   */
  const setTaskParamsToGlobalState = (label: string): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        taskTypeLabel: label
      })
    );
  };

  /**
   * Sets formTab and previousFormTab
   */
  const goBackOneFormHandler = (
    pageId?: string,
    chosenPage?: TaskPageUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        formTab: prevValues.previousFormTab,
        previousFormTab: TaskFormTypeEnum.TASK_FORM,
        chosenTaskPageId: pageId ? pageId : undefined,
        chosenPage: chosenPage ? chosenPage : undefined,
        chosenPageElement: undefined
      })
    );
  };

  // TASK ACTIONS

  /**
   * Saves values from task form in task state
   * Sets chosenPage as new TaskPageUI with predefined page number
   * @param taskValues - TaskUI
   */
  const addNewPage = (taskValues: TaskUI): void => {
    let lastTaskPageNumber = taskValues.taskPages.length || 0;
    if (taskValues.taskType === TaskTypeEnum.QUIZ) {
      lastTaskPageNumber =
        taskValues.taskPages?.filter(page => !page.isLastPageFront).length || 0;
    }

    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        previousFormTab: TaskFormTypeEnum.TASK_FORM,
        formTab: TaskFormTypeEnum.PAGE_FORM,
        chosenPage: new TaskPageUI({ pageNumber: lastTaskPageNumber + 1 })
      })
    );
  };

  /**
   * Redirect to page and set page id
   * @param pageId - string
   */
  const editPageWithId = (page: TaskPageUI): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage: page,
        chosenTaskPageId: page.id,
        previousFormTab: TaskFormTypeEnum.TASK_FORM,
        formTab: TaskFormTypeEnum.EDIT_PAGE_FORM
      })
    );
  };

  /**
   * Removes quizCompletionMessages from list
   * Requires users confirmation
   * @param messageIndex - number
   * @param values - TaskUI
   * @param setFieldValue - formik onChange helper
   * @param messageId - udnefined | string
   */
  const removeQuizCompletionMessage = async (
    messageIndex: number,
    values: TaskUI,
    setFieldValue: (fieldName: string, value: any) => void,
    messageId?: string
  ): Promise<void> => {
    const hasOneMessage =
      values?.quizCompletionMessages?.filter(message => message.id).length ===
      1;

    if (hasOneMessage) {
      toast.info(
        "In order do delete last message you must first create a new message!"
      );
      return;
    }

    const result = window.confirm(
      "Are u sure u want to delete this quiz completion message?"
    );

    if (!result) {
      return;
    }

    if (messageId) {
      const response = await deleteQuizCompletionMessage(messageId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
    }

    values &&
      values.quizCompletionMessages &&
      setFieldValue(
        "quizCompletionMessages",
        values.quizCompletionMessages.filter((message, i) => i !== messageIndex)
      );
  };

  /**
   * Removes chosen page from list of pages
   * Requires users confirmation
   * @param pageIndex - number
   */
  // TODO add call for removing page
  const removePageFromTask = async (
    pageIndex: number,
    pageId?: string,
    values?: TaskUI,
    setFieldValue?: (fieldName: string, values: TaskPageUI[]) => void
  ): Promise<void> => {
    const hasOnePage = values?.taskPages.length === 1;

    if (hasOnePage) {
      toast.info(
        "In order do delete last page you must first create a new page!"
      );
      return;
    }
    const result = window.confirm("Are u sure u want to delete this page?");

    if (!result) {
      return;
    }

    if (pageId) {
      const response = await deletePage(pageId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
    }

    values &&
      setFieldValue &&
      setFieldValue(
        "taskPages",
        values?.taskPages
          .filter((page, i) => i !== pageIndex)
          .map((page, i) =>
            utils.updateInstanceObject(TaskPageUI, page, { pageNumber: i + 1 })
          )
      );
  };

  // PAGE ACTIONS

  /**
   * Adds new page element to chosenPageElement
   * Saves current page form values
   * @param currentFormTab - TaskFormTypeEnum
   * @param pageFormValues - TaskPageUI
   */
  const addNewPageElement = (
    currentFormTab: TaskFormTypeEnum,
    page: TaskPageUI
  ): void => {
    const lastPageElementNumber: number = page.taskPageElements?.length || 0;

    if (
      isQuiz &&
      !pageState?.chosenPage?.isFirstPageFront &&
      !pageState?.chosenPage?.isLastPageFront
    ) {
      return setPageState(prevValues =>
        utils.updateObject(prevValues, {
          chosenPage: page,
          chosenPageElement: new TaskPageElementUI({
            sequenceNumber: lastPageElementNumber + 1,
            type: TaskPageElementTypeEnum.QUESTION,
            question: new TaskQuestionUI()
          }),
          previousFormTab: currentFormTab,
          formTab: TaskFormTypeEnum.PAGE_ELEMENT_FORM
        })
      );
    }
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage: page,
        chosenPageElement: new TaskPageElementUI({
          sequenceNumber: lastPageElementNumber + 1
        }),
        chosenPageElementId: undefined,
        previousFormTab: currentFormTab,
        formTab: TaskFormTypeEnum.PAGE_ELEMENT_FORM
      })
    );
  };

  /**
   * Adds page element from list of elements in page to chosenPageElement
   * Saves current page form values
   * @param currentFormTab - TaskFormTypeEnum
   * @param pageFormValues - TaskPageUI
   */
  const editPageElement = (
    pageFormValues: TaskPageUI,
    pageElelement: TaskPageElementUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage: utils.updateInstanceObject(TaskPageUI, prevValues, {
          ...pageFormValues
        }),
        previousFormTab: prevValues.formTab,
        formTab: TaskFormTypeEnum.EDIT_PAGE_ELEMENT_FORM,
        chosenPageElement: pageElelement
      })
    );
  };

  /**
   * Adds page element from list of elements in page to chosenPageElement
   * Saves current page form values
   * @param chosenPageElementId - string
   */
  const editPageElementWithId = (
    chosenPage: TaskPageUI,
    chosenPageElementId: string
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage: chosenPage,
        previousFormTab: prevValues.formTab,
        formTab: TaskFormTypeEnum.EDIT_PAGE_ELEMENT_FORM,
        chosenPageElementId: chosenPageElementId
      })
    );
  };

  /**
   * Removes chosen page element from list of elements in chosen page
   * Requires users confirmation
   * @param pageIndex - number
   */
  const removePageElementFromPage = async (
    pageElementIndex: number,
    pageElementId?: string,
    values?: TaskPageUI,
    setFieldValue?: (fieldName: string, value: TaskPageElementUI[]) => void
  ): Promise<void> => {
    const result = window.confirm("Are u sure u want to delete this element?");
    if (!result) {
      return;
    }

    if (pageElementId) {
      const response = await deletePageElement(pageElementId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
    }

    values &&
      values.taskPageElements &&
      setFieldValue &&
      setFieldValue(
        "taskPageElements",
        values?.taskPageElements
          ?.filter((element, i) => i !== pageElementIndex)
          .map((element, i) =>
            utils.updateInstanceObject(TaskPageElementUI, element, {
              sequenceNumber: i + 1
            })
          )
      );
  };

  // PAGE ELEMENT ACTIONS

  /**
   * Append new page element to chosen page
   * Redirect to page form with new page element
   * @param pageElement - TaskPageElementUI
   */
  const appendNewPageElementToChosenPage = (
    pageElement: TaskPageElementUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage:
          prevValues.chosenPage &&
          utils.updateInstanceObject(TaskPageUI, prevValues.chosenPage, {
            taskPageElements: prevValues.chosenPage?.taskPageElements
              ? [...prevValues.chosenPage?.taskPageElements, pageElement]
              : [pageElement]
          }),
        chosenPageElement: null,
        previousFormTab: TaskFormTypeEnum.TASK_FORM,
        formTab: prevValues.previousFormTab
      })
    );
  };

  /**
   * Replaces pageElement with chosenPageElement based on sequenceNumber
   * Redirect to page form with updated page element
   * @param pageElement - TaskPageElementUI
   */
  const updatePageElementToChosenPage = (
    pageElement: TaskPageElementUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage:
          prevValues.chosenPage &&
          utils.updateInstanceObject(TaskPageUI, prevValues.chosenPage, {
            taskPageElements:
              prevValues.chosenPage.taskPageElements &&
              prevValues.chosenPage.taskPageElements.map(element =>
                element.sequenceNumber === pageElement.sequenceNumber
                  ? pageElement
                  : element
              )
          }),
        chosenPageElement: null,
        previousFormTab: TaskFormTypeEnum.TASK_FORM,
        formTab: prevValues.previousFormTab
      })
    );
  };

  /**
   * Removes answer
   * Requires users confirmation
   * @param pageIndex - number
   */
  const removeAnswerFromQuestion = async (
    answerIndex: number,
    values: TaskPageElementUI,
    setFieldValue: (fieldName: string, value: any) => void,
    answerId?: string
  ): Promise<void> => {
    const result = window.confirm("Are u sure u want to delete this answer?");
    if (!result) {
      return;
    }

    if (answerId) {
      const response = await deleteTaskPageElementQuestionAnswer(answerId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
    }

    values &&
      values.question &&
      values.question.answers &&
      setFieldValue(
        "question.answers",
        values.question.answers
          .filter((answer, i) => i !== answerIndex)
          .map((answer, i) =>
            utils.updateInstanceObject(TaskQuestionAnswerUI, answer, {
              sequenceNumber: i + 1
            })
          )
      );
  };

  return (
    <>
      {!utils.isEmpty(pageState.taskTypeLabel) && (
        <PageHeader title={`Edit  ${pageState.taskTypeLabel}`} />
      )}
      {pageState.formTab === TaskFormTypeEnum.TASK_FORM && (
        <TaskForm
          isEditForm
          taskId={id}
          taskOptions={taskOptions}
          setTaskParamsToGlobalState={setTaskParamsToGlobalState}
          editPageWithId={editPageWithId}
          addNewPage={addNewPage}
          setQuizLastPageId={setQuizLastPageId}
          removePageFromTask={removePageFromTask}
          removeQuizCompletionMessage={removeQuizCompletionMessage}
        />
      )}
      {(pageState.formTab === TaskFormTypeEnum.PAGE_FORM ||
        pageState.formTab === TaskFormTypeEnum.EDIT_PAGE_FORM) && (
        <PageForm
          isEditForm
          taskId={id}
          chosenTaskPageId={pageState.chosenTaskPageId}
          formTab={pageState.formTab}
          newPage={pageState.chosenPage}
          quizLastPageId={pageState.quizLastPageId && pageState.quizLastPageId}
          addNewPageElement={addNewPageElement}
          goBackOneFormHandler={goBackOneFormHandler}
          editPageElementWithId={editPageElementWithId}
          editPageElement={editPageElement}
          removePageElementFromPage={removePageElementFromPage}
        />
      )}
      {(pageState.formTab === TaskFormTypeEnum.PAGE_ELEMENT_FORM ||
        pageState.formTab === TaskFormTypeEnum.EDIT_PAGE_ELEMENT_FORM) && (
        <PageElementForm
          isEditForm
          taskId={id}
          pageId={pageState.chosenTaskPageId}
          chosenPage={pageState.chosenPage}
          isQuiz={isQuiz}
          taskType={pageState.taskTypeLabel}
          formTab={pageState.formTab}
          newChosenPageElement={
            pageState.chosenPageElement && pageState.chosenPageElement
          }
          chosenPageElementId={pageState.chosenPageElementId}
          goBackOneFormHandler={goBackOneFormHandler}
          appendNewPageElementToChosenPage={appendNewPageElementToChosenPage}
          updatePageElementToChosenPage={updatePageElementToChosenPage}
          removeAnswerFromQuestion={removeAnswerFromQuestion}
        />
      )}
    </>
  );
};

export default EditTask;
