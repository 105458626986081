// DTOS
import { OnboardingPageDto } from "../dtos";
// DOMAIN Models
import { OnboardingPage } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type OnboardingPagesResponseDataType = {
  taskPages: OnboardingPageDto[];
  statusType: ResponseStatusType;
};

export class OnboardingPagesResponse {
  onboardingPages: OnboardingPage[];
  statusType: ResponseStatusType;

  constructor(responseData: OnboardingPagesResponseDataType) {
    this.onboardingPages = responseData.taskPages.map(
      page => new OnboardingPage(page)
    );
    this.statusType = ResponseStatusType.OK;
  }
}
