// Dtos
import { ShopArticleDto } from "../../api/dtos";

// Domain Models
import { FileModel } from "./file.model";

export class ShopArticle {
  id: string;
  name: string;
  image: FileModel;
  price: string;
  link: string;

  constructor(shopArticle: ShopArticleDto) {
    this.id = shopArticle.id;
    this.name = shopArticle.name;
    this.image = new FileModel(shopArticle.image);
    this.price = shopArticle.price;
    this.link = shopArticle.link;
  }
}
