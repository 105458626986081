// Icons
import {
  Store,
  PermMedia,
  AccountCircle,
  SvgIconComponent
} from "@mui/icons-material";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import AccessibilityIcon from "@mui/icons-material/Accessibility";

type NavigationLink = {
  path: string;
  name: string;
  Icon: SvgIconComponent;
};

export const navigation: NavigationLink[] = [
  {
    path: "/",
    name: "Journey",
    Icon: PermMedia
  },
  {
    path: "/fitness",
    name: "Fitness",
    Icon: FitnessCenterIcon
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    Icon: AccessibilityIcon
  },
  {
    path: "/store",
    name: "Store",
    Icon: Store
  },
  {
    path: "/users",
    name: "Users",
    Icon: AccountCircle
  },
  {
    path: "/admins",
    name: "Admins",
    Icon: AccountCircle
  }
];
