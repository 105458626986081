// DTOS
import { FitnessDto } from "../dtos";

// DOMAIN Models
import { Fitness } from "../../app/domain";

//Api Enums
import { ResponseStatusType } from "../../../enums/api";

export type FitnessListResponseDataType = {
  fitness: FitnessDto[];
  statusType: ResponseStatusType;
};

export class FitnessListResponse {
  fitnessList: Fitness[];
  statusType: ResponseStatusType;

  constructor(responseData: FitnessListResponseDataType) {
    this.fitnessList = responseData.fitness.map(
      fitnessDetails => new Fitness(fitnessDetails)
    );
    this.statusType = ResponseStatusType.OK;
  }
}
