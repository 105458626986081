// Domain Models
import { ShopArticle } from "../domain";

// Utils
import { returnUrlFromFile } from "../../../utils/file.utils";

type ShopArticleUIType = {
  id?: string;
  name: string;
  image: string | null;
  price: string;
  link: string;
};

export class ShopArticleUI {
  id?: string;
  name: string;
  image: string | null;
  price: string;
  link: string;

  constructor(data?: ShopArticleUIType | ShopArticle) {
    this.id = data?.id;
    this.name = data?.name || "";
    this.price = data?.price || "";
    this.link = data?.link || "";
    this.image = returnUrlFromFile(data?.image);
  }
}
