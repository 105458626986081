import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

/**
 * Checks is value empty, null or undefined
 * If value is array, checks is length of value greater of zero
 * @param value - The value it checks
 * @returns Is value empty
 */
export const isEmpty = (value: any): boolean => {
  if (
    value === null ||
    value === undefined ||
    value === "" ||
    value === "null" ||
    value === "undefined"
  ) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  return false;
};

/**
 * Checks if value exsists
 * Dependency isEmpty function
 * @param value - any or null
 * @returns value or null
 */
export const valueOrNull = (value: any): any | null => {
  if (isEmpty(value)) {
    return null;
  }

  return value;
};

/**
 * Creates new Instace with updated values
 * @param Instance - Instance
 * @param oldObject - object
 * @param updatedValues - any
 * @returns new Instance with updated values
 */
export const updateInstanceObject = <T>(
  Instance: new (data: any) => T,
  oldObject: object,
  updatedValues: any
): T => {
  const entity = new Instance({
    ...oldObject,
    ...updatedValues
  });

  return entity;
};

/**
 * Create object with updated values
 * @param oldObject - object
 * @param updatedValues - any
 * @returns object with updated values
 */
export const updateObject = <T>(oldObject: T, updatedValues: any): T => {
  return {
    ...oldObject,
    ...updatedValues
  };
};

/**
 * Text Editor can work with Editor State or undefined that is way value is undefined on start
 * If content is EditorState, we return content
 * If content is HTML string we convert it to EditorState and return it
 * If conditions are not met we return undefined
 * @param content - The value it checks
 * @returns EditorState only type text-editor can work with or undefined
 */
export const convertStringToEditorState = (
  content?: EditorState | null | string
) => {
  if (content instanceof EditorState) {
    return content;
  }
  if (typeof content === "string") {
    const blocksFromHtml = htmlToDraft(content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return EditorState.createWithContent(contentState);
  }

  return undefined;
};

/**
 * Truncates string if number of charaters are longer then maxLength and adds dots
 * @param string - string
 * @param maxLength - limit for string
 * @returns full or truncated string
 */
export const truncate = (string: string, maxLength: number = 50): string => {
  if (string.length <= maxLength) return string;
  return `${string.substring(0, maxLength)}...`;
};
