import { AccountDTO } from "../../api/dtos";
// models
import { User } from "./user.model";
import { Role } from "./role.model";
import { Consent } from "./consent.model";

export class Account {
  id: string;
  email: string;
  emailVerifiedAt: Date;
  role: Role | null;
  consents: Consent[];
  user: User;

  constructor(entity: AccountDTO) {
    this.id = entity.id;
    this.email = entity.email;
    this.emailVerifiedAt = entity.email_verified_at;
    this.role = entity.role ? new Role(entity.role) : null;
    this.consents = entity.consents.map(consent => new Consent(consent));
    this.user = new User(entity.user);
  }
}
