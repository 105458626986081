import { EditorState } from "draft-js";
// Enums
import { FitnessPageElementTypeEnum } from "../../../enums/app";

// Domain Models
import { FitnessPageElement } from "../domain";

// Utils
import { returnUrlFromFile } from "../../../utils/file.utils";

type FitnessPageElementUIType = {
  id?: string;
  sequenceNumber?: number;
  type?: FitnessPageElementTypeEnum | string;
  content?: EditorState | string | null;
  color?: string;
  // link?: string | null;
  file?: string | null;
  coverImage?: string | null;
};

export class FitnessPageElementUI {
  id?: string;
  sequenceNumber: number;
  type: FitnessPageElementTypeEnum | string;
  content: EditorState | string | null;
  color: string;
  // link: string | null;
  file: string | null;
  coverImage?: string | null;

  constructor(data?: FitnessPageElementUIType | FitnessPageElement) {
    this.id = data?.id;
    this.sequenceNumber = data?.sequenceNumber || 0;
    this.type = data?.type || "";
    this.content = data?.content || "";
    this.color = data?.color || "";
    // this.link = data?.link || "";
    this.file = returnUrlFromFile(data?.file);
    this.coverImage = returnUrlFromFile(data?.coverImage);
  }
}
