import { TableCellProps } from "@mui/material";

type Column = {
  id: string;
  label: string;
  minWidth: number;
  align?: TableCellProps["align"];
  format?: (value: any) => void;
};

export type Row = {
  id?: string;
  email?: string;
  lastName?: string;
  firstName?: string;
};

export const columns: Column[] = [
  { id: "firstName", label: "First name", minWidth: 200 },
  { id: "lastName", label: "Last name", minWidth: 200 },
  {
    id: "email",
    label: "Email",
    minWidth: 200
  },
  {
    id: "id",
    label: "Actions",
    minWidth: 170,
    align: "right"
  }
];

export const createData = (
  firstName: string,
  id: string,
  lastName: string,
  email: string
): Row => {
  return { firstName, id, lastName, email };
};
