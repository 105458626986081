// DTOS
import { TaskPageElementDTO } from "../dtos";
// DOMAIN Models
import { TaskPageElement } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type TaskPageElementResponseDataType = {
  task_page_element: TaskPageElementDTO;
  statusType: ResponseStatusType;
};

export class TaskPageElementResponse {
  taskPageElement: TaskPageElement;
  statusType: ResponseStatusType;

  constructor(responseData: TaskPageElementResponseDataType) {
    this.taskPageElement = new TaskPageElement(responseData.task_page_element);
    this.statusType = ResponseStatusType.OK;
  }
}
