import { Link, useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
// Styles
import classes from "./sidemenu.module.css";

// Icons
import { Logo } from "../../SVGS";
import { Logout } from "@mui/icons-material";

// Utils
import { removeItem } from "../../utils/localStorage.utils";

// Contracts
import { SideMenuProps } from "./contracts/sidemenu.types";

// Constants
import * as localStoageKeys from "../../constants/localStorage.constants";

// Config
import { navigation } from "../../config/navigation.config";
import { useEffect } from "react";

const SideMenu = ({ handleRoutesBasedOnToken, setHistory }: SideMenuProps) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setHistory(history);
  }, []);
  return (
    <nav className={classes.nav}>
      <Logo className={classes.logo} />
      <div className={classes.linksContainer}>
        {navigation.map(navigation => (
          <Link
            style={{ textDecoration: "none" }}
            key={navigation.path}
            to={navigation.path}
          >
            <div
              className={`${classes.link} ${
                location.pathname === navigation.path && classes.activeLink
              }`}
            >
              <navigation.Icon
                sx={{
                  fill: "#fff",
                  width: "20px",
                  height: "20px",
                  marginRight: "10px"
                }}
              />
              <p className={classes.linkName}>{navigation.name}</p>
            </div>
          </Link>
        ))}
      </div>
      <div
        className={classes.logout}
        onClick={() => {
          removeItem(localStoageKeys.TOKEN);
          removeItem(localStoageKeys.REFRESH_TOKEN);
          removeItem(localStoageKeys.EXPIRES_AT);
          toast.success("You have logged out successfully!");
          handleRoutesBasedOnToken();
          history.push("/");
        }}
      >
        <Logout
          sx={{
            fill: "#fff",
            width: "20px",
            height: "20px",
            marginRight: "10px"
          }}
        />
        <p className={classes.linkName}>Logout</p>
      </div>
    </nav>
  );
};

export default SideMenu;
