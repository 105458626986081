import { useEffect, useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";

// Theme
import {
  Card,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button
} from "@mui/material";
import Typography from "@mui/material/Typography";

// Forms-based-on-type
import TextForm from "./forms-based-on-type/Text.form";
import FileForm from "./forms-based-on-type/File.form";
import ButtonForm from "./forms-based-on-type/Button.form";

// Builders
import { pageElementsTypeOptions } from "../../../../builders/fitness-page-elements/fitness-page-elements.builder";

// Validations
import { fitnessPageElementValidationSchema } from "./fitnessPageElement.validation";

// Enums
import {
  FitnessFormTypeEnum,
  FitnessPageElementTypeEnum
} from "../../../../enums/app";
import { ResponseStatusType } from "../../../../enums/api";

// Models
import { FitnessPageUI, FitnessPageElementUI } from "../../../../models/app/ui";

// Calls
import {
  fetchFitnessPageElement,
  updateFitnessPage
} from "../../../../api/calls/fitness/fitness";

// Utils
import * as utils from "../../../../utils/global.utils";

// Contracts
import { PageElementFormProps } from "./contracts/fitness-page-element-form.types";

// Styles
import classes from "./fitnessPageElements.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const PageElementForm = ({
  formTab,
  chosenPageElement,
  pageElementId,
  pageId,
  page,
  isEditForm,
  fitnessId,
  goBackOneFormHandler,
  appendNewPageElementToChosenPage,
  updatePageElementToChosenPage
}: PageElementFormProps) => {
  const [pageElementForm, setPageElementForm] = useState<
    FitnessPageElementUI | undefined
  >(undefined);

  const setPageElementFormValues = async (): Promise<void> => {
    if (isEditForm && pageElementId) {
      const response = await fetchFitnessPageElement(pageElementId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      setPageElementForm(new FitnessPageElementUI(response.fitnessPageElement));
      return;
    }

    chosenPageElement && setPageElementForm(chosenPageElement);
  };

  useEffect(() => {
    setPageElementFormValues();
  }, []);
  /**
   * This function creates new page element and appends to chosenPage or updates chosen page element in chosenPage
   * Also it here we convert all EditorStates to strings
   * @param values - FitnessPageElementUI
   */
  const pageElementFormHandler = async (
    values: FitnessPageElementUI,
    { setSubmitting }: FormikHelpers<FitnessPageElementUI>
  ): Promise<void> => {
    setSubmitting(true);
    let fomratedContentValue;
    if (
      values.type === FitnessPageElementTypeEnum.TEXT &&
      values.content instanceof EditorState
    ) {
      fomratedContentValue = new FitnessPageElementUI({
        ...values,
        content: draftToHtml(convertToRaw(values.content.getCurrentContent()))
      });
    }

    /**
     * IF condition is met we make update task call on server
     */
    if (isEditForm && page && pageId && fitnessId) {
      const updateFitnessPagePayload = new FitnessPageUI({
        ...page,
        pageElements: [fomratedContentValue ? fomratedContentValue : values]
      });
      const response = await updateFitnessPage(
        fitnessId,
        updateFitnessPagePayload,
        pageId
      );
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }

      toast.success(response.message);
      goBackOneFormHandler(pageId);
      return;
    }

    if (formTab === FitnessFormTypeEnum.PAGE_ELEMENT_FORM) {
      appendNewPageElementToChosenPage(
        fomratedContentValue ? fomratedContentValue : values
      );
      return;
    }

    if (formTab === FitnessFormTypeEnum.EDIT_PAGE_ELEMENT_FORM) {
      updatePageElementToChosenPage(
        fomratedContentValue ? fomratedContentValue : values
      );
      return;
    }

    // Should never happen
    toast.error("Page Element form didn't know witch action to call!");
  };

  /**
   * Modifes task page element title from string to editor state if question exists
   */
  let modefiedChosenPageElement = pageElementForm;
  if (
    pageElementForm &&
    pageElementForm.type === FitnessPageElementTypeEnum.TEXT
  ) {
    modefiedChosenPageElement = utils.updateInstanceObject(
      FitnessPageElementUI,
      pageElementForm,
      {
        content: utils.convertStringToEditorState(pageElementForm.content)
      }
    );
  }

  return (
    <>
      {modefiedChosenPageElement && (
        <Card className={classes.card}>
          <Formik
            enableReinitialize={true}
            initialValues={modefiedChosenPageElement}
            onSubmit={pageElementFormHandler}
            validationSchema={fitnessPageElementValidationSchema}
          >
            {({
              values,
              touched,
              errors,
              isSubmitting,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              resetForm
            }) => {
              /**
               * This function resets form to inital value and sets type
               * @param type - FitnessPageElementTypeEnum || string
               */
              const handleTypeChange = (
                type: FitnessPageElementTypeEnum | string
              ): void => {
                resetForm();
                setFieldValue("type", type);
              };

              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className={classes.formHeader}>
                    <Typography className={classes.headerTitle} variant="h6">
                      Page element {values.sequenceNumber} form
                    </Typography>
                    <Button
                      onClick={() => goBackOneFormHandler(pageId, page)}
                      variant="contained"
                    >
                      Back to Pages Form
                    </Button>
                  </div>

                  {formTab === FitnessFormTypeEnum.PAGE_ELEMENT_FORM && (
                    <div className={classes.taskFormContainer}>
                      <div className={classes.fullWidth}>
                        <FormControl
                          error={touched.type && errors.type ? true : false}
                          variant="standard"
                          sx={{ width: "100%" }}
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={values.type}
                            onBlur={() => setFieldTouched("type", true)}
                            onChange={e => {
                              if (e.target.value !== values.type) {
                                handleTypeChange(e.target.value);
                              }
                            }}
                            label="Type"
                            name="type"
                          >
                            {pageElementsTypeOptions.map(
                              ({ value, label }, i) => (
                                <MenuItem key={i} value={value}>
                                  {label}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                        <p className={classes.errorMessage}>
                          {touched.type && errors.type && errors.type}
                        </p>
                      </div>
                    </div>
                  )}

                  {values.type === FitnessPageElementTypeEnum.TEXT && (
                    <TextForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {(values.type === FitnessPageElementTypeEnum.IMAGE ||
                    values.type === FitnessPageElementTypeEnum.AUDIO ||
                    values.type === FitnessPageElementTypeEnum.VIDEO) && (
                    <FileForm
                      pageElementType={values.type}
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {values.type ===
                    FitnessPageElementTypeEnum.CONTINUE_BUTTON && (
                    <ButtonForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldTouched={setFieldTouched}
                    />
                  )}
                  <div className={classes.actions}>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                    >
                      {formTab === FitnessFormTypeEnum.PAGE_ELEMENT_FORM
                        ? "Add element to page "
                        : "Update page element"}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Card>
      )}
    </>
  );
};

export default PageElementForm;
