import { Editor } from "react-draft-wysiwyg";
import { Field, FieldProps, FieldArray } from "formik";
import { toast } from "react-toastify";

// Theme components
import {
  Card,
  Select,
  Checkbox,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
  FormControlLabel
} from "@mui/material";
import Typography from "@mui/material/Typography";

// Builders
import {
  generateQuestionTypeOptions,
  answerTypeOptions
} from "../../../../../builders/task-page-elements/task-page-elements.builder";

// App Enums
import {
  TaskQuestionsTypesEnum,
  TaskPageElementTypeEnum,
  QuestionAnswerTypeEnum,
  TaskFormTypeEnum,
  TaskTypeEnum
} from "../../../../../enums/app";

// Api Enums
import { ResponseStatusType } from "../../../../../enums/api";

// Models
import { TaskQuestionAnswerUI } from "../../../../../models/app/ui";

// Contracts
import { QuestionFormProps } from "../contracts/forms-based-on-type.types";

// Calls
import { deleteTaskPageElementQuestionAnswer } from "../../../../../api/calls/task/task";

// Utils
import {
  convertStringToEditorState,
  updateInstanceObject
} from "../../../../../utils/global.utils";

// Styles
import classes from "../pageElementesForm.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const QuestionForm = ({
  pageId,
  pageElementId,
  isQuiz,
  setFieldValue,
  taskType,
  errors,
  touched,
  values,
  formTab,
  removeAnswerFromQuestion
}: QuestionFormProps) => {
  const shoudQuestionTypeFiledShow =
    (isQuiz && !(formTab === TaskFormTypeEnum.EDIT_PAGE_ELEMENT_FORM)) ||
    (taskType === TaskTypeEnum.LESSON &&
      formTab === TaskFormTypeEnum.PAGE_ELEMENT_FORM);
  return (
    <>
      {shoudQuestionTypeFiledShow && (
        <div className={classes.taskFormContainer}>
          <div className={classes.fullWidth}>
            <Field name="question.type">
              {({ field, form, meta }: FieldProps) => (
                <FormControl
                  error={meta.touched && meta.error ? true : false}
                  variant="standard"
                  sx={{ width: "100%" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Question type
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                      form.setFieldValue("question.image", null);
                      form.setFieldValue("question.explanation", "");
                      form.setFieldValue(
                        "question.answerType",
                        QuestionAnswerTypeEnum.SINGLE
                      );
                      form.setFieldValue("question.answers", null);
                    }}
                    label="Type"
                    name={field.name}
                  >
                    {generateQuestionTypeOptions(taskType).map(
                      ({ value, label }, i) => (
                        <MenuItem key={i} value={value}>
                          {label}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  <p className={classes.errorMessage}>
                    {meta.touched && meta.error && meta.error}
                  </p>
                </FormControl>
              )}
            </Field>
          </div>
        </div>
      )}

      {values.type === TaskPageElementTypeEnum.QUESTION && (
        <>
          <InputLabel
            style={{ fontSize: "0.8rem" }}
            id="demo-simple-select-standard-label"
          >
            Question Title
          </InputLabel>
          <Field name="question.title">
            {({ field, form, meta }: FieldProps) => (
              <>
                <div
                  className={classes.editorContainer}
                  style={{ minHeight: "200px" }}
                >
                  <Editor
                    onBlur={() => {
                      form.setFieldTouched("question.title", true);
                    }}
                    editorState={convertStringToEditorState(field.value)}
                    onEditorStateChange={editorState =>
                      form.setFieldValue("question.title", editorState)
                    }
                    toolbar={{
                      fontSize: {
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 36, 48, 60, 72, 96],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      fontFamily: {
                        options: [
                          "ITCAvantGardeStd-XLt",
                          "ITCAvantGardeStd-Bk",
                          "ITCAvantGardeStd-Md",
                          "ITCAvantGardeStd-Demi",
                          'ITCAvantGardeStd-BkCnObl'
                        ],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined
                      }
                    }}
                  />
                </div>
                <p className={classes.errorMessage}>
                  {meta.touched && meta.error && meta.error}
                </p>
              </>
            )}
          </Field>

          {/* <div>
            <InputLabel
              style={{ fontSize: "0.8rem" }}
              id="demo-simple-select-standard-label"
            >
              Image
            </InputLabel>
            <Field name="question.image">
              {({ field, form, meta }: FieldProps) => (
                <FileUpload
                  name={field.name}
                  id="file"
                  value={field.value}
                  setFieldValue={form.setFieldValue}
                  touched={meta.touched}
                  error={meta.error}
                />
              )}
            </Field>
          </div> */}

          {values.question?.type === TaskQuestionsTypesEnum.CONDITIONAL && (
            <div className={classes.taskFormContainer}>
              <div className={classes.fullWidth}>
                <Field name="question.answerType">
                  {({ field, form, meta }: FieldProps) => (
                    <FormControl
                      error={meta.touched && meta.error ? true : false}
                      variant="standard"
                      sx={{ width: "100%" }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Answer type
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={field.value}
                        onBlur={field.onBlur}
                        onChange={e => {
                          form.setFieldValue(field.name, e.target.value);
                        }}
                        label="Answer type"
                        name={field.name}
                      >
                        {answerTypeOptions.map(({ value, name }, i) => (
                          <MenuItem key={i} value={value}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      <p className={classes.errorMessage}>
                        {meta.touched && meta.error && meta.error}
                      </p>
                    </FormControl>
                  )}
                </Field>
              </div>
            </div>
          )}

          {values.question?.type === TaskQuestionsTypesEnum.POP && (
            <div className={classes.fullWidth}>
              <Field name="question.explanation">
                {({ field, form, meta }: FieldProps) => (
                  <>
                    <TextField
                      fullWidth
                      id="content"
                      type="text"
                      multiline
                      rows={5}
                      name={field.name}
                      error={meta.touched && meta.error ? true : false}
                      label="Explanation"
                      variant="standard"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    />
                    <p className={classes.errorMessage}>
                      {meta.touched && meta.error && meta.error}
                    </p>
                  </>
                )}
              </Field>
            </div>
          )}
          {values.question?.type &&
            values.question?.type !== TaskQuestionsTypesEnum.JOURNAL && (
              <FieldArray
                name="question.answers"
                render={arrayHelpers => {
                  const addNewAnswer = () => {
                    let lastAnswerSequenceNumber =
                      values.question?.answers?.length || 0;

                    arrayHelpers.push(
                      new TaskQuestionAnswerUI({
                        sequenceNumber: lastAnswerSequenceNumber + 1
                      })
                    );
                  };

                  const deleteAnswer = async (
                    index: number,
                    answer: TaskQuestionAnswerUI
                  ): Promise<void> => {
                    removeAnswerFromQuestion &&
                      removeAnswerFromQuestion(
                        index,
                        values,
                        setFieldValue,
                        answer.id
                      );
                  };

                  const handleIsCorrectChange = (index: number) => {
                    if (!values.question) {
                      return;
                    }
                    setFieldValue(
                      "question.answers",
                      values.question.answers &&
                        values.question?.answers.map((answer, i) =>
                          index === i
                            ? updateInstanceObject(
                                TaskQuestionAnswerUI,
                                answer,
                                {
                                  isCorrect: true
                                }
                              )
                            : updateInstanceObject(
                                TaskQuestionAnswerUI,
                                answer,
                                {
                                  isCorrect: false
                                }
                              )
                        )
                    );
                  };
                  return (
                    <Card className={classes.card}>
                      <div className={classes.answersContainer}>
                        <Typography
                          className={classes.headerTitle}
                          variant="h6"
                        >
                          Answers
                        </Typography>
                        <p className={classes.errorMessage}>
                          {touched?.question?.answers &&
                            !Array.isArray(errors?.question?.answers) &&
                            errors?.question?.answers}
                        </p>
                        <Button onClick={addNewAnswer} variant="contained">
                          Add answer
                        </Button>
                      </div>
                      <div>
                        {values.question?.answers?.map((answer, index) => (
                          <Card className={classes.card} key={index}>
                            <div
                              className={classes.answersContainer}
                              style={{ marginBottom: "20px" }}
                            >
                              <Typography
                                className={classes.headerTitle}
                                variant="h6"
                              >
                                Answer {answer.sequenceNumber}
                              </Typography>
                              <Button
                                onClick={() => deleteAnswer(index, answer)}
                                color="error"
                                variant="contained"
                              >
                                Delete answer
                              </Button>
                            </div>
                            <div className={classes.taskFormContainer}>
                              <div
                                className={
                                  values.question?.type ===
                                  TaskQuestionsTypesEnum.CONSULTATION
                                    ? classes.halfWidth
                                    : classes.fullWidth
                                }
                              >
                                <Field
                                  name={`question.answers[${index}].answer`}
                                >
                                  {({ field, meta }: FieldProps) => (
                                    <>
                                      <TextField
                                        fullWidth
                                        id="content"
                                        type="text"
                                        name={field.name}
                                        error={
                                          meta.touched && meta.error
                                            ? true
                                            : false
                                        }
                                        label="Answer"
                                        variant="standard"
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                      />
                                      <p className={classes.errorMessage}>
                                        {meta.touched &&
                                          meta.error &&
                                          meta.error}
                                      </p>
                                    </>
                                  )}
                                </Field>
                              </div>
                              {values.question?.type ===
                                TaskQuestionsTypesEnum.CONSULTATION && (
                                <div className={classes.halfWidth}>
                                  <Field
                                    name={`question.answers[${index}].answerScore`}
                                  >
                                    {({ field, meta }: FieldProps) => (
                                      <>
                                        <TextField
                                          fullWidth
                                          id="standard-number"
                                          label="Answer score"
                                          type="number"
                                          name={field.name}
                                          error={
                                            meta.touched && meta.error
                                              ? true
                                              : false
                                          }
                                          variant="standard"
                                          value={field.value}
                                          onChange={field.onChange}
                                          onBlur={field.onBlur}
                                        />
                                        <p className={classes.errorMessage}>
                                          {meta.touched &&
                                            meta.error &&
                                            meta.error}
                                        </p>
                                      </>
                                    )}
                                  </Field>
                                </div>
                              )}
                            </div>
                            {values.question?.type ===
                              TaskQuestionsTypesEnum.POP && (
                              <div style={{ marginBottom: "20px" }}>
                                <Field
                                  name={`question.answers[${index}].isCorrect`}
                                >
                                  {({ field, meta }: FieldProps) => (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={field.value}
                                          onChange={() =>
                                            handleIsCorrectChange(index)
                                          }
                                          name={field.name}
                                        />
                                      }
                                      label="Correct"
                                    />
                                  )}
                                </Field>
                              </div>
                            )}
                          </Card>
                        ))}
                      </div>
                    </Card>
                  );
                }}
              />
            )}
        </>
      )}
    </>
  );
};

export default QuestionForm;
