import { useState, useEffect } from "react";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";

// Theme
import {
  Card,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button
} from "@mui/material";
import Typography from "@mui/material/Typography";

// Forms-based-on-type
import TextForm from "./forms-based-on-type/Text.form";
import FileForm from "./forms-based-on-type/File.form";
import ButtonForm from "./forms-based-on-type/Button.form";
import QuestionForm from "./forms-based-on-type/Question.form";

// Builders
import {
  pageElementsTypeOptions,
  fileUploadTypes,
  buttonTypes
} from "../../../../builders/onboarding-page-elements/onboarding-page-elements.builder";

// Validations
import { onboardingPageElementsValidationSchema } from "./onboarding-page-element-form.validation";

// Enums
import {
  OnboardingFormTypeEnum,
  OnboardingPageElementTypeEnum,
  QuestionAnswerTypeEnum,
  AnswerTypeEnum
} from "../../../../enums/app";
import { ResponseStatusType } from "../../../../enums/api";

// Models
import {
  OnboardingPageElementUI,
  OnboardingQuestionUI,
  OnboardingQuestionAnswerUI,
  OnboardingPageUI
} from "../../../../models/app/ui";

// Utils
import * as utils from "../../../../utils/global.utils";

// Calls
import {
  getOnboardingPageElement,
  updateOnboarding
} from "../../../../api/calls/onboarding/onboarding";

// Contracts
import { PageElementFormProps } from "./contracts/onboarding-page-element-form.type";

// Styles
import classes from "./onboardingPageElementForm.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

let openTextAnswer: OnboardingQuestionAnswerUI | undefined = undefined;

const PageElementForm = ({
  pageId,
  page,
  formTab,
  chosenPageElement,
  pageElementId,
  onboardingId,
  appendNewPageElementToChosenPage,
  goBackOneFormHandler,
  updatePageElementToChosenPage,
  removeAnswerFromQuestion
}: PageElementFormProps) => {
  const [pageElementForm, setPageElementForm] = useState<
    OnboardingPageElementUI | undefined
  >(undefined);

  /**
   * Fetch page element details if edit form and chosenPageElementId not undefined
   */
  const setTaskPageElementFormState = async (): Promise<void> => {
    // Add call for task details
    if (
      pageElementId &&
      formTab === OnboardingFormTypeEnum.EDIT_PAGE_ELEMENT_FORM
    ) {
      const response = await getOnboardingPageElement(pageElementId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      setPageElementForm(
        new OnboardingPageElementUI(response.onboardingPageElement)
      );
      return;
    }

    chosenPageElement && setPageElementForm(chosenPageElement);
  };

  // Sets values for taskForm
  useEffect(() => {
    setTaskPageElementFormState();
  }, []);

  /**
   * This function creates new page element and appends to chosenPage or updates chosen page element in chosenPage
   * Also it here we convert all EditorStates to strings
   * @param values - TaskPageElementUI
   */
  const pageElementFormHandler = async (
    values: OnboardingPageElementUI,
    { setSubmitting }: FormikHelpers<OnboardingPageElementUI>
  ): Promise<void> => {
    setSubmitting(true);

    let fomratedContentValue;

    /**
     * Converts values.content from editor state to HTML string if element type is text
     */
    if (
      values.type === OnboardingPageElementTypeEnum.TEXT &&
      values.content instanceof EditorState
    ) {
      fomratedContentValue = new OnboardingPageElementUI({
        ...values,
        content: draftToHtml(convertToRaw(values.content.getCurrentContent()))
      });
    }

    /**
     * Converts values.question.title from editor state to HTML string if element type is question
     */
    if (
      values.type === OnboardingPageElementTypeEnum.QUESTION &&
      values.question &&
      values.question.title instanceof EditorState &&
      values.question.answerType !== QuestionAnswerTypeEnum.SINGLE_AND_OPEN
    ) {
      fomratedContentValue = new OnboardingPageElementUI({
        ...values,
        question: new OnboardingQuestionUI({
          ...values.question,
          title: draftToHtml(
            convertToRaw(values.question.title.getCurrentContent())
          )
        })
      });
    }

    /**
     * If element type is question and question answer type is SINGLE_AND_OPEN cases:
     * Case 1 - If openTextAnswer is undefined it creates new answer at the end and gives it type OPEN_TEXT
     * Case 2 - Adds openTextAnswer with proper sequence number to be at the end
     */
    if (
      values.type === OnboardingPageElementTypeEnum.QUESTION &&
      values.question &&
      values.question.title instanceof EditorState &&
      values.question.answerType === QuestionAnswerTypeEnum.SINGLE_AND_OPEN
    ) {
      fomratedContentValue = new OnboardingPageElementUI({
        ...values,
        question:
          values.question.answers &&
          new OnboardingQuestionUI({
            ...values.question,
            title: draftToHtml(
              convertToRaw(values.question.title.getCurrentContent())
            ),
            answers: openTextAnswer
              ? [
                  ...values.question.answers,
                  new OnboardingQuestionAnswerUI({
                    ...openTextAnswer,
                    sequenceNumber: values.question.answers.length + 1
                  })
                ]
              : [
                  ...values.question.answers,
                  new OnboardingQuestionAnswerUI({
                    answer: "Type here...",
                    type: AnswerTypeEnum.OPEN_TEXT,
                    sequenceNumber: values.question.answers.length + 1
                  })
                ]
          })
      });
    }

    /**
     * IF condition is met we make update task call on server
     */
    if (page && onboardingId && pageId) {
      const updateOnboardingPagePayload = new OnboardingPageUI({
        ...page,
        taskPageElements: [fomratedContentValue ? fomratedContentValue : values]
      });
      const response = await updateOnboarding(
        updateOnboardingPagePayload,
        onboardingId,
        pageId
      );
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }

      toast.success(response.message);
      goBackOneFormHandler(pageId);
      return;
    }

    if (
      appendNewPageElementToChosenPage &&
      formTab === OnboardingFormTypeEnum.PAGE_ELEMENT_FORM
    ) {
      appendNewPageElementToChosenPage(
        fomratedContentValue ? fomratedContentValue : values
      );
      return;
    }

    if (
      updatePageElementToChosenPage &&
      formTab === OnboardingFormTypeEnum.EDIT_PAGE_ELEMENT_FORM
    ) {
      updatePageElementToChosenPage(
        fomratedContentValue ? fomratedContentValue : values
      );
      return;
    }

    // Should never happen
    toast.error("Page Element form didn't know witch action to call!");
  };

  /**
   * modefiedChosenPageElement =  chosenPageElement
   */
  let modefiedChosenPageElement = pageElementForm;
  /**
   * If chosenPageElement type is text
   * convert content from HTML string to EditorState
   * append chosen page elelemnt to modefiedChosenPageElement
   * modefiedChosenPageElement send form
   */
  if (
    pageElementForm &&
    pageElementForm.type === OnboardingPageElementTypeEnum.TEXT
  ) {
    modefiedChosenPageElement = utils.updateInstanceObject(
      OnboardingPageElementUI,
      pageElementForm,
      {
        content: utils.convertStringToEditorState(pageElementForm.content)
      }
    );
  }

  /**
   * If chosenPageElement type is question
   * convert title from HTML string to EditorState
   * if answer with type OPEN_TEXT exists append answer to openTextAnswer param on top
   * removes answer with type OPEN_TEXT from answers
   * appends all question changes to modefiedChosenPageElement and pass it to form
   */
  if (
    pageElementForm &&
    pageElementForm.type === OnboardingPageElementTypeEnum.QUESTION
  ) {
    const openTextFieldAnswer = pageElementForm?.question?.answers?.find(
      answer => answer.type === AnswerTypeEnum.OPEN_TEXT
    );
    modefiedChosenPageElement = utils.updateInstanceObject(
      OnboardingPageElementUI,
      pageElementForm,
      {
        question: pageElementForm.question
          ? utils.updateInstanceObject(
              OnboardingQuestionUI,
              pageElementForm.question,
              {
                title: utils.convertStringToEditorState(
                  pageElementForm.question.title
                ),
                answers: pageElementForm.question.answers?.filter(
                  answer => answer.type !== AnswerTypeEnum.OPEN_TEXT
                )
              }
            )
          : null
      }
    );
    openTextAnswer = openTextFieldAnswer;
  }

  return (
    <>
      {modefiedChosenPageElement && (
        <Card className={classes.card}>
          <Formik
            enableReinitialize={true}
            initialValues={modefiedChosenPageElement}
            onSubmit={pageElementFormHandler}
            validationSchema={onboardingPageElementsValidationSchema}
          >
            {({
              values,
              touched,
              errors,
              isSubmitting,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              resetForm
            }) => {
              /**
               * This function resets form to inital value and sets type
               * If type is question it creates TaskQuestionUI instance
               * @param type - TaskPageElementTypeEnum || string
               */
              const handleTypeChange = (
                type: OnboardingPageElementTypeEnum | string
              ): void => {
                resetForm();
                if (type === OnboardingPageElementTypeEnum.QUESTION) {
                  setFieldValue("question", new OnboardingQuestionUI());
                }
                setFieldValue("type", type);
              };

              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className={classes.formHeader}>
                    <Typography className={classes.headerTitle} variant="h6">
                      Page element {values.sequenceNumber} form
                    </Typography>
                    <Button
                      onClick={() => goBackOneFormHandler(pageId, page)}
                      variant="contained"
                    >
                      Back to Pages Form
                    </Button>
                  </div>

                  {formTab === OnboardingFormTypeEnum.PAGE_ELEMENT_FORM && (
                    <div className={classes.taskFormContainer}>
                      <div className={classes.fullWidth}>
                        <FormControl
                          error={touched.type && errors.type ? true : false}
                          variant="standard"
                          sx={{ width: "100%" }}
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={values.type}
                            onBlur={() => setFieldTouched("type", true)}
                            onChange={e => {
                              if (e.target.value !== values.type) {
                                handleTypeChange(e.target.value);
                              }
                            }}
                            label="Type"
                            name="type"
                          >
                            {pageElementsTypeOptions.map(
                              ({ value, label }, i) => (
                                <MenuItem key={i} value={value}>
                                  {label}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                        <p className={classes.errorMessage}>
                          {touched.type && errors.type && errors.type}
                        </p>
                      </div>
                    </div>
                  )}

                  {values.type === OnboardingPageElementTypeEnum.TEXT && (
                    <TextForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {values.type && fileUploadTypes.includes(values.type) && (
                    <FileForm
                      pageElementType={values.type}
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {values.type && buttonTypes.includes(values.type) && (
                    <ButtonForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldTouched={setFieldTouched}
                    />
                  )}

                  {values.type === OnboardingPageElementTypeEnum.QUESTION &&
                    (formTab === OnboardingFormTypeEnum.PAGE_ELEMENT_FORM ||
                      formTab ===
                        OnboardingFormTypeEnum.EDIT_PAGE_ELEMENT_FORM) && (
                      <QuestionForm
                        formTab={formTab}
                        // pageElementId={chosenPageElement.id}
                        touched={touched}
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        removeAnswerFromQuestion={removeAnswerFromQuestion}
                      />
                    )}

                  <div className={classes.actions}>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                    >
                      {formTab === OnboardingFormTypeEnum.PAGE_ELEMENT_FORM
                        ? "Add element to page "
                        : "Update page element"}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Card>
      )}
    </>
  );
};

export default PageElementForm;
