import { FitnessFilterDto } from "../../api/dtos";

export class FitnessFilter {
  id: string;
  type: string;
  name: string;

  constructor(entity: FitnessFilterDto) {
    this.id = entity.id;
    this.type = entity.type;
    this.name = entity.name;
  }
}
