import { AuthDTO } from "../../api/dtos";

export class Auth {
  accessToken: string;
  refreshToken: string;
  expiresAt: number;
  createdAt: number;

  constructor(entity: AuthDTO) {
    this.accessToken = entity.access_token;
    this.refreshToken = entity.refresh_token;
    this.expiresAt = entity.expires_at;
    this.createdAt = entity.created_at;
  }
}
