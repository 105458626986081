import { TableCellProps } from "@mui/material";

// App Enums
import { TaskTypeEnum } from "../../enums/app";

type Column = {
  id: string;
  label: string;
  minWidth: number;
  align?: TableCellProps["align"];
  format?: (value: any) => void;
};

export type Row = {
  id?: string;
  title?: string;
  type?: string;
  lessonLikes?: number;
  lessonDislikes?: number;
  sequenceNumber?: number | null;
};

/**
 * Generates diffrent column structure based on task type
 * @params taskType - TaskTypeEnum
 * @returns - Column[]
 */

export const generateColumns = (taskType: TaskTypeEnum): Column[] => {
  if (taskType === TaskTypeEnum.LESSON) {
    return [
      { id: "sequenceNumber", label: "Number", minWidth: 170 },
      { id: "title", label: "Title", minWidth: 100 },
      {
        id: "type",
        label: "Type",
        minWidth: 170,
        align: "center"
      },
      {
        id: "lessonLikes",
        label: "Likes",
        minWidth: 170,
        align: "center"
      },
      {
        id: "lessonDislikes",
        label: "Dislikes",
        minWidth: 170,
        align: "center"
      },
      {
        id: "id",
        label: "Actions",
        minWidth: 170,
        align: "right"
      }
    ];
  }

  return [
    { id: "title", label: "Title", minWidth: 200 },
    {
      id: "type",
      label: "Type",
      minWidth: 200,
      align: "center"
    },
    {
      id: "id",
      label: "Actions",
      minWidth: 170,
      align: "right"
    }
  ];
};

export const createData = (
  sequenceNumber: number | null,
  id: string,
  title: string,
  type: string,
  lessonLikes?: number,
  lessonDislikes?: number
): Row => {
  return { sequenceNumber, id, title, type, lessonLikes, lessonDislikes };
};
