import { TextField } from "@mui/material";

// Components
import { CustomColorPicker } from "../../../../../components/UI";

// Contracts
import { ButtonFormProps } from "../contracts/forms-based-on-type.types";

// Styles
import classes from "../fitnessPageElements.module.css";

const ButtonForm = ({
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  values
}: ButtonFormProps) => {
  return (
    <div className={classes.taskFormContainer}>
      <div className={classes.halfWidth}>
        <TextField
          fullWidth
          id="content"
          type="text"
          name="content"
          error={touched.content && errors.content ? true : false}
          label="Button text"
          variant="standard"
          value={values.content}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <p className={classes.errorMessage}>
          {touched.content && errors.content && errors.content}
        </p>
      </div>
      <div className={classes.halfWidth}>
        <CustomColorPicker
          label="Button color"
          name="color"
          error={touched.color && errors.color ? true : false}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          value={values.color}
        />
        <p className={classes.errorMessage}>
          {touched.color && errors.color && errors.color}
        </p>
      </div>
    </div>
  );
};

export default ButtonForm;
