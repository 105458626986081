// DTOS
import { UserDTO } from "../dtos";

// DOMAIN Models
import { User } from "../../app/domain";

//Api Enums
import { ResponseStatusType } from "../../../enums/api";

export type UserResponseDataType = {
  user: UserDTO;
  statusType: ResponseStatusType;
};

export class UserResponse {
  user: User;
  statusType: ResponseStatusType;

  constructor(responseData: UserResponseDataType) {
    this.user = new User(responseData.user);
    this.statusType = ResponseStatusType.OK;
  }
}
