import { useState, useEffect } from "react";
import { Formik, FormikHelpers } from "formik";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

// theme
import {
  Card,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  Box
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";

// components
import { FileUpload, MultiSelectDropDown } from "../../../../components/UI";

// Builders
import {
  typeOptions,
  difficultyOptions
} from "../../../../builders/fitness/fitness.builder";

// Api Enums
import { ResponseStatusType } from "../../../../enums/api";

// UI Models
import { FitnessPageUI, FitnessUI } from "../../../../models/app/ui";

// Validations
import { fitnessValidationSchema } from "./fitnessForm.validation";

// Calls
import {
  createFitness,
  updateFitness,
  fetchFitnessDetails,
  reorderFitnessPage
} from "../../../../api/calls/fitness/fitness";

// Utils
import { updateObject } from "../../../../utils/global.utils";

// Contracts
import { FitnessFormProps } from "./contracts/fitness-form-type";

// Styles
import classes from "./fitnessForm.module.css";

const FitnessForm = ({
  addNewPage,
  editPage,
  editPageWithId,
  removePageFromFitness,
  fitness,
  fitnessId,
  filters,
  isEditForm = false
}: FitnessFormProps) => {
  const history = useHistory();
  const [fitnessForm, setFitnessForm] = useState<FitnessUI | undefined>(
    undefined
  );
  const [reorderState, setReorderState] = useState<{
    isOpen: boolean;
    page?: FitnessPageUI;
  }>({
    isOpen: false,
    page: undefined
  });

  const setFitnessFormValues = async (): Promise<void> => {
    if (isEditForm && fitnessId) {
      const response = await fetchFitnessDetails(fitnessId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      setFitnessForm(new FitnessUI(response.fitness));
    }

    fitness && setFitnessForm(fitness);
  };

  useEffect(() => {
    setFitnessFormValues();
  }, []);
  /**
   * Create new fitness or update chosen fitness on server and redirects to fitness page
   * @param values - FitnessUI
   */
  const fitnessFormHandler = async (
    values: FitnessUI,
    { setSubmitting }: FormikHelpers<FitnessUI>
  ): Promise<void> => {
    setSubmitting(true);
    if (isEditForm && values.id) {
      const response = await updateFitness(values, values.id);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
      history.push("/fitness");
      return;
    }

    if (!isEditForm) {
      const response = await createFitness(values);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
      history.push("/fitness");
      return;
    }

    setSubmitting(false);
    // Should never happen
    toast.error("Task form didn't know witch action to call!");
  };

  return (
    <>
      {fitnessForm && (
        <Card className={classes.taskCard}>
          <Formik
            enableReinitialize={true}
            initialValues={fitnessForm}
            onSubmit={fitnessFormHandler}
            validationSchema={fitnessValidationSchema}
          >
            {({
              values,
              touched,
              errors,
              isSubmitting,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue
            }) => {
              const handleReorderPage = async (
                page?: FitnessPageUI
              ): Promise<void> => {
                if (isEditForm && page?.id) {
                  const response = await reorderFitnessPage(
                    page?.id,
                    page?.pageNumber
                  );
                  if (response.statusType !== ResponseStatusType.OK) {
                    return;
                  }
                  setFieldValue("pages", response.fitnessPages);
                  toast.success("Reorder completed");
                }

                setReorderState({
                  isOpen: false,
                  page: undefined
                });
              };
              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Typography className={classes.headerTitle} variant="h6">
                    Fitness form
                  </Typography>
                  <div className={classes.taskFormContainer}>
                    <div className={classes.fullWidth}>
                      <TextField
                        fullWidth
                        id="title"
                        type="text"
                        name="name"
                        label="Title"
                        variant="standard"
                        error={touched.name && errors.name ? true : false}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p className={classes.errorMessage}>
                        {touched.name && errors.name && errors.name}
                      </p>
                    </div>
                  </div>
                  <div className={classes.taskFormContainer}>
                    <div className={classes.halfWidth}>
                      <FormControl
                        error={touched.type && errors.type ? true : false}
                        variant="standard"
                        sx={{ width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={values.type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Type"
                          name="type"
                        >
                          {typeOptions.map(({ value, name }) => (
                            <MenuItem value={value} key={value}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <p className={classes.errorMessage}>
                        {touched.type && errors.type && errors.type}
                      </p>
                    </div>
                    <div className={classes.halfWidth}>
                      <FormControl
                        error={
                          touched.difficulty && errors.difficulty ? true : false
                        }
                        variant="standard"
                        sx={{ width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Difficulty
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={values.difficulty}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Type"
                          name="difficulty"
                        >
                          {difficultyOptions.map(({ value, name }) => (
                            <MenuItem value={value} key={value}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <p className={classes.errorMessage}>
                        {touched.difficulty &&
                          errors.difficulty &&
                          errors.difficulty}
                      </p>
                    </div>
                  </div>
                  <div className={classes.taskFormContainer}>
                    <div className={classes.fullWidth}>
                      <TextField
                        fullWidth
                        id="standard-number"
                        label="Duration (in minutes)"
                        type="number"
                        name="duration"
                        variant="standard"
                        value={values.duration}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <p className={classes.errorMessage}>
                        {touched.duration && errors.duration && errors.duration}
                      </p>
                    </div>
                  </div>
                  <div className={classes.taskFormContainer}>
                    <div className={classes.fullWidth}>
                      <FileUpload
                        name="image"
                        id="image"
                        value={values.image}
                        setFieldValue={setFieldValue}
                        touched={touched.image}
                        error={errors.image}
                        placeholder="Upload Cover Image"
                      />
                    </div>
                  </div>
                  <div className={classes.taskFormContainer}>
                    <div className={classes.fullWidth}>
                      <MultiSelectDropDown
                        label="Exercises"
                        name="exercises"
                        value={values.exercises}
                        setFieldValue={setFieldValue}
                        options={filters.exercises}
                      />
                    </div>
                  </div>
                  <div className={classes.taskFormContainer}>
                    <div className={classes.fullWidth}>
                      <MultiSelectDropDown
                        label="Equipments"
                        name="equipments"
                        value={values.equipments}
                        setFieldValue={setFieldValue}
                        options={filters.equipments}
                      />
                    </div>
                  </div>
                  <div className={classes.taskFormContainer}>
                    <div className={classes.fullWidth}>
                      <MultiSelectDropDown
                        label="Targets"
                        name="targets"
                        value={values.targets}
                        setFieldValue={setFieldValue}
                        options={filters.targets}
                      />
                    </div>
                  </div>
                  <div className={classes.pages}>
                    <div className={classes.fullWidth}>
                      <div className={classes.pageElementsLabel}>
                        <Typography
                          className={classes.headerTitle}
                          variant="h6"
                        >
                          Pages
                        </Typography>
                        <Button
                          onClick={() => {
                            addNewPage(values);
                          }}
                          variant="contained"
                        >
                          Add new page
                        </Button>
                      </div>
                      <List
                        component="nav"
                        aria-label="secondary mailbox folder"
                      >
                        {values.pages?.map((page, i) => (
                          <ListItemButton key={i}>
                            <ListItemText primary={`Page ${page.pageNumber}`} />
                            {isEditForm && (
                              <Button
                                onClick={() =>
                                  setReorderState({
                                    isOpen: true,
                                    page: page
                                  })
                                }
                                variant="contained"
                                color="success"
                              >
                                Reorder
                              </Button>
                            )}
                            <Button
                              onClick={() => {
                                if (isEditForm && editPageWithId && page.id) {
                                  editPageWithId(page.id);
                                  return;
                                }
                                editPage(values, page);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Edit
                            </Button>

                            <Button
                              onClick={() =>
                                removePageFromFitness(
                                  i,
                                  page.id,
                                  values,
                                  setFieldValue
                                )
                              }
                              variant="contained"
                              color="error"
                            >
                              Delete
                            </Button>
                          </ListItemButton>
                        ))}
                      </List>
                      <p className={classes.errorMessage}>
                        {touched.pages && errors.pages && errors.pages}
                      </p>
                    </div>
                  </div>
                  <div>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      {isEditForm ? "Update fitness" : "Create fitness"}
                    </Button>
                  </div>
                  <Modal
                    open={reorderState.isOpen}
                    onClose={() =>
                      setReorderState({
                        isOpen: false,
                        page: undefined
                      })
                    }
                  >
                    <Box className={classes.modalBox}>
                      <div className={classes.formModalHeader}>
                        <Typography variant="h5">
                          Page {reorderState.page?.pageNumber}
                        </Typography>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setReorderState({
                              isOpen: false,
                              page: undefined
                            })
                          }
                        >
                          <CloseOutlined />
                        </div>
                      </div>
                      <TextField
                        fullWidth
                        id="standard-number"
                        label="Lesson position"
                        type="number"
                        name="position"
                        variant="standard"
                        value={reorderState.page?.pageNumber}
                        onChange={e =>
                          setReorderState(prevState =>
                            updateObject(prevState, {
                              page: {
                                ...prevState.page,
                                pageNumber: e.target.value
                              }
                            })
                          )
                        }
                      />
                      <Button
                        onClick={() => {
                          handleReorderPage(reorderState.page);
                        }}
                        style={{ marginTop: "40px" }}
                        variant="contained"
                      >
                        Save changes
                      </Button>
                    </Box>
                  </Modal>
                </form>
              );
            }}
          </Formik>
        </Card>
      )}
    </>
  );
};

export default FitnessForm;
