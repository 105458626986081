import { EditorState } from "draft-js";
// Enums
import { TaskPageElementTypeEnum } from "../../../enums/app";

// UI Models
import { TaskQuestionUI } from "./task-question.ui";

// Domain Models
import { TaskPageElement } from "../domain";

// Utils
import { returnUrlFromFile } from "../../../utils/file.utils";

type ITaskPageElementUI = {
  id?: string;
  sequenceNumber?: number;
  type?: TaskPageElementTypeEnum | string;
  content?: EditorState | string | null;
  color?: string | null;
  file?: string | null;
  coverImage?: string | null;
  question?: TaskQuestionUI | null;
};

export class TaskPageElementUI {
  id?: string;
  sequenceNumber: number;
  type: TaskPageElementTypeEnum | string;
  content?: EditorState | string;
  color?: string;
  file: string | null;
  coverImage: string | null;
  question: TaskQuestionUI | null;

  constructor(data?: ITaskPageElementUI | TaskPageElement) {
    this.id = data?.id;
    this.sequenceNumber = data?.sequenceNumber || 0;
    this.type = data?.type || "";
    this.content = data?.content || "";
    this.color = data?.color || "";
    this.file = returnUrlFromFile(data?.file);
    this.coverImage = returnUrlFromFile(data?.coverImage);
    this.question = data?.question ? new TaskQuestionUI(data.question) : null;
  }
}
