// Dtos
import { ShopArticleDto } from "../dtos";

// Domain Models
import { ShopArticle } from "../../app/domain";

// Api Enums
import { ResponseStatusType } from "../../../enums/api";

export type ShopArticlesDataType = {
  shop_items: ShopArticleDto[];
};

export class ShopArticlesResponse {
  shopArticles: ShopArticle[];
  statusType: ResponseStatusType;

  constructor(responseData: ShopArticlesDataType) {
    this.shopArticles = responseData.shop_items.map(
      item => new ShopArticle(item)
    );
    this.statusType = ResponseStatusType.OK;
  }
}
