type LoginUIType = {
  email: string;
  password: string;
};

export class LoginUI {
  email: string;
  password: string;

  constructor(data?: LoginUIType) {
    this.email = data?.email || "ivan.pantelic+100@bstorm.co";
    this.password = data?.password || "ivan.pantelic";
  }
}
