// DTOS
import { TaskOptionDto } from "../dtos";
// DOMAIN Models
import { TaskOption } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type TaskOptionsResponseDataType = {
  tasks: TaskOptionDto[];
  statusType: ResponseStatusType;
};

export class TaskOptionsResponse {
  options: TaskOption[];
  statusType: ResponseStatusType;

  constructor(responseData: TaskOptionsResponseDataType) {
    this.options = responseData.tasks.map(lesson => new TaskOption(lesson));
    this.statusType = ResponseStatusType.OK;
  }
}
