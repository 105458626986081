// DTOS
import { AccountDTO, AuthDTO } from "../dtos";
// DOMAIN Models
import { Account, Auth } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type LoginResponseDataType = {
  account: AccountDTO;
  auth: AuthDTO;
  statusType: ResponseStatusType;
};

export class LoginResponse {
  account: Account;
  auth: Auth;
  statusType: ResponseStatusType;

  constructor(responseData: LoginResponseDataType) {
    this.account = new Account(responseData.account);
    this.auth = new Auth(responseData.auth);
    this.statusType = ResponseStatusType.OK;
  }
}
