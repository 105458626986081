// DTOS
import { FitnessPageDto } from "../dtos";
// DOMAIN Models
import { FitnessPage } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type FitnessPageResponseDataType = {
  fitness_page: FitnessPageDto;
  statusType: ResponseStatusType;
};

export class FitnessPageResponse {
  fitnessPage: FitnessPage;
  statusType: ResponseStatusType;

  constructor(responseData: FitnessPageResponseDataType) {
    this.fitnessPage = new FitnessPage(responseData.fitness_page);
    this.statusType = ResponseStatusType.OK;
  }
}
