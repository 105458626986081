type AdminUIType = {
  id?: string;
  accountId?: string;
  firstName: string;
  lastName: string | null;
  email: string;
  password?: string;
};

export class AdminUI {
  id?: string;
  accountId?: string;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;

  constructor(data?: AdminUIType) {
    this.id = data?.id;
    this.accountId = data?.accountId;
    this.firstName = data?.firstName || "";
    this.lastName = data?.lastName || "";
    this.email = data?.email || "";
    this.password = data?.password || "";
  }
}
