import { EditorState } from "draft-js";

// Enums
import {
  TaskQuestionsTypesEnum,
  QuestionAnswerTypeEnum
} from "../../../enums/app";

// Ui Models
import { TaskQuestionAnswerUI } from "./task-question-answer.ui";

// Domain Models
import { TaskQuestion } from "../domain";

type TaskQuestionUIType = {
  id?: string;
  type?: TaskQuestionsTypesEnum | string;
  title?: EditorState | string;
  image?: string | null;
  explanation?: string | null;
  isMultiSelect?: boolean;
  isSkippable?: boolean;
  answerType?: QuestionAnswerTypeEnum;
  answers?: TaskQuestionAnswerUI[] | null;
};

export class TaskQuestionUI {
  id?: string;
  type: TaskQuestionsTypesEnum | string;
  title: EditorState | string;
  //TODO The image will be entity
  image?: string | null;
  explanation?: string | null;
  isMultiSelect?: boolean;
  isSkippable: boolean;
  answerType: QuestionAnswerTypeEnum;
  answers: TaskQuestionAnswerUI[] | null;

  constructor(data?: TaskQuestionUIType | TaskQuestion) {
    this.id = data?.id;
    this.type = data?.type || "";
    this.title = data?.title || "";
    this.image = data?.image || null;
    this.explanation = data?.explanation || "";
    this.isMultiSelect = data?.isMultiSelect || false;
    this.isSkippable = data?.isSkippable || false;
    this.answerType = data?.answerType || QuestionAnswerTypeEnum.SINGLE;
    this.answers =
      data?.answers?.map(answer => new TaskQuestionAnswerUI(answer)) || [];
  }
}
