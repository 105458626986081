// components
import LoginForm from "../../containers/forms/login/LoginForm";

type LoginProps = {
  handleRoutesBasedOnToken(token: string): void;
};

const Login = ({ handleRoutesBasedOnToken }: LoginProps) => {
  return <LoginForm handleRoutesBasedOnToken={handleRoutesBasedOnToken} />;
};

export default Login;
