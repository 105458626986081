//UI Models
import { OnboardingPageUI } from "../../../../models/app/ui";

// Utils
import { isEmpty } from "../../../../utils/global.utils";

export const onboardingPageFormValidation = ({
  taskPageElements
}: OnboardingPageUI): any => {
  const errors: any = {};

  if (isEmpty(taskPageElements)) {
    errors.taskPageElements = "* Page must have at least one page element";
  }

  return errors;
};
