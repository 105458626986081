// Ui Models
import { OnboardingQuestionAnswerUI } from "../../app/ui";

// App Enum
import { AnswerTypeEnum } from "../../../enums/app";

export class OnboardingQuestionAnswerRequest {
  id?: string;
  answer?: string;
  sequence_number: number;
  type: AnswerTypeEnum;
  is_correct?: boolean;
  answer_score?: number | null;

  constructor(onboardingQuestionAnswerUI: OnboardingQuestionAnswerUI) {
    this.id = onboardingQuestionAnswerUI.id;
    this.answer = onboardingQuestionAnswerUI.answer;
    this.type = onboardingQuestionAnswerUI.type;
    this.sequence_number = onboardingQuestionAnswerUI.sequenceNumber;
    this.is_correct = onboardingQuestionAnswerUI.isCorrect;
    this.answer_score = onboardingQuestionAnswerUI.answerScore;
  }
}
