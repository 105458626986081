import { ApiResponse } from "apisauce";

// Api
import { api } from "../../api";
import { getGeneralApiProblem } from "../../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem
} from "../../general-api-problems/general-api-problems.types";

// UI Models
import { FitnessUI, FitnessPageUI } from "../../../models/app/ui";

// Requests
import {
  FitnessRequest,
  UpdateFitnessRequest,
  UpdateFitnessPageRequest
} from "../../../models/api/requests";

// Response
import {
  FitnessListResponseDataType,
  FitnessListResponse
} from "../../../models/api/responses/fitness-list.response";
import {
  FitnessResponseDataType,
  FitnessResponse
} from "../../../models/api/responses/fitness.response";
import {
  FitnessFiltersResponse,
  FitnessFiltersResponseDataType
} from "../../../models/api/responses/fitness-filters.response";
import {
  FitnessPageResponse,
  FitnessPageResponseDataType
} from "../../../models/api/responses/fitness-page.response";
import {
  FitnessPagesResponse,
  FitnessPagesResponseDataType
} from "../../../models/api/responses/fitness-pages.response";
import {
  FitnessPageElementResponse,
  FitnessPageElementResponseDataType
} from "../../../models/api/responses/fitness-page-element.response";
import {
  FitnessPageElementsResponse,
  FitnessPageElementsResponseDataType
} from "../../../models/api/responses/fitness-page-elements.response";
import {
  MessageResponseType,
  MessageResponse
} from "../../../models/api/responses/message.response";

/**
 * Fetch fitness list
 * @returns - FitnessesResponse or GeneralApiProblem
 */
export const fetchFitnessList = async (): Promise<
  FitnessListResponse | GeneralApiProblem
> => {
  const response: ApiResponse<FitnessListResponseDataType, ExceptionPayload> =
    await api.apisauce.get(`/admin/content-library/fitness`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  const responseData = response.data as FitnessListResponseDataType;
  return new FitnessListResponse(responseData);
};

/**
 * Fetch fitness filters
 * @returns - FitnessesResponse or GeneralApiProblem
 */
// TODO Add params when u get some sleep
export const fetchFitnessFilters = async (): Promise<
  FitnessFiltersResponse | GeneralApiProblem
> => {
  const response: ApiResponse<
    FitnessFiltersResponseDataType,
    ExceptionPayload
  > = await api.apisauce.get(
    `/content-library/filters?exercises=true&equipments=true&targets=true`
  );

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  const responseData = response.data as FitnessFiltersResponseDataType;
  return new FitnessFiltersResponse(responseData);
};

/**
 * Create fitness request
 * @param fitnessUI - FitnessUI
 * @returns MessageResponse or GeneralApiProblem
 */
export const createFitness = async (
  fitnessUI: FitnessUI
): Promise<MessageResponse | GeneralApiProblem> => {
  const requestPayload = new FitnessRequest(fitnessUI);

  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.post("/admin/content-library/fitness", requestPayload);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;

  return new MessageResponse(responseData);
};

/**
 * Create fitness request
 * @param id - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const fetchFitnessDetails = async (
  id: string
): Promise<FitnessResponse | GeneralApiProblem> => {
  const response: ApiResponse<FitnessResponseDataType, ExceptionPayload> =
    await api.apisauce.get(`/admin/content-library/fitness/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as FitnessResponseDataType;

  return new FitnessResponse(responseData);
};

/**
 * Update fitness request
 * @param fitnessUI - FitnessUI
 * @param fitnessId - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const updateFitness = async (
  fitnessUI: FitnessUI,
  fitnessId: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const requestPayload = new UpdateFitnessRequest(fitnessUI, fitnessId);

  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.patch(
      `/admin/content-library/fitness/${fitnessId}`,
      requestPayload
    );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;

  return new MessageResponse(responseData);
};

/**
 * Fetch fitness list
 * @returns - FitnessesResponse or GeneralApiProblem
 */
export const deleteFetnessItem = async (
  id: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/admin/content-library/fitness/${id}`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};

/**
 * Fetch fitness page details request
 * @param id - string
 * @returns FitnessPageResponse or GeneralApiProblem
 */
export const fetchFitnessPage = async (
  id: string
): Promise<FitnessPageResponse | GeneralApiProblem> => {
  const response: ApiResponse<FitnessPageResponseDataType, ExceptionPayload> =
    await api.apisauce.get(`/admin/content-library/fitness/page/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as FitnessPageResponseDataType;

  return new FitnessPageResponse(responseData);
};

/**
 * Delete fitness page
 * @returns - FitnessesResponse or GeneralApiProblem
 */
export const deleteFitnessPage = async (
  id: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/admin/content-library/fitness-page/${id}`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};

/**
 * Fetch fitness page element details request
 * @param id - string
 * @returns FitnessPageResponse or GeneralApiProblem
 */
export const fetchFitnessPageElement = async (
  id: string
): Promise<FitnessPageElementResponse | GeneralApiProblem> => {
  const response: ApiResponse<
    FitnessPageElementResponseDataType,
    ExceptionPayload
  > = await api.apisauce.get(
    `/admin/content-library/fitness/page-element/${id}`
  );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as FitnessPageElementResponseDataType;

  return new FitnessPageElementResponse(responseData);
};

/**
 * Update fitness page details request
 * @param id - string
 * @param page - FitnessPageUI
 * @returns FitnessPageResponse or GeneralApiProblem
 */
export const updateFitnessPage = async (
  fitnessId: string,
  page: FitnessPageUI,
  id?: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const requestPayload = {
    fitness_pages: [new UpdateFitnessPageRequest(page, id)]
  };
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.patch(
      `/admin/content-library/fitness/${fitnessId}`,
      requestPayload
    );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;

  return new MessageResponse(responseData);
};

/**
 * Change page number request
 * @param fitnessPageId - string
 * @param pageNumber - number
 * @returns MessageResponse or GeneralApiProblem
 */
export const reorderFitnessPage = async (
  fitnessPageId?: string,
  pageNumber?: number
): Promise<FitnessPagesResponse | GeneralApiProblem> => {
  const response: ApiResponse<FitnessPagesResponseDataType, ExceptionPayload> =
    await api.apisauce.post(
      `/admin/content-library/fitness/page/reorder?id=${fitnessPageId}&position=${pageNumber}`
    );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as FitnessPagesResponseDataType;
  return new FitnessPagesResponse(responseData);
};

/**
 * Change page number request
 * @param taskPageId - string
 * @param pageNumber - number
 * @returns MessageResponse or GeneralApiProblem
 */
export const reorderFitnessPageElements = async (
  elementId?: string,
  pageNumber?: number
): Promise<FitnessPageElementsResponse | GeneralApiProblem> => {
  const response: ApiResponse<
    FitnessPageElementsResponseDataType,
    ExceptionPayload
  > = await api.apisauce.post(
    `/admin/content-library/fitness/page-element/reorder?id=${elementId}&position=${pageNumber}`
  );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as FitnessPageElementsResponseDataType;
  return new FitnessPageElementsResponse(responseData);
};

/**
 * Delete fitness page element
 * @returns - FitnessesResponse or GeneralApiProblem
 */
export const deleteFitnessPageElement = async (
  id: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(
      `/admin/content-library/fitness-page-element/${id}`
    );

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};
