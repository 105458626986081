import { useState, useEffect } from "react";
import { FormikHelpers, Formik } from "formik";
import { toast } from "react-toastify";

// Theme
import {
  Card,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  TextField,
  Box
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";

// Validations
import { taskPageFormValidation } from "./TaskPageForm.validation";

// Enums
import {
  TaskFormTypeEnum,
  TaskQuestionsTypesEnum,
  TaskTypeEnum
} from "../../../../enums/app";

// Api Enums
import { ResponseStatusType } from "../../../../enums/api";

// UI Models
import { TaskPageElementUI, TaskPageUI } from "../../../../models/app/ui";

// Calls
import {
  getTaskPage,
  updateTaskPage,
  reorderTaskPage,
  reorderTaskPageElements
} from "../../../../api/calls/task/task";

// Utils
import { updateObject } from "../../../../utils/global.utils";

// Contracts
import { PageFormProps } from "./contracts/page-form.types";

// Styles
import classes from "./pageForm.module.css";

const PageForm = ({
  isEditForm = false,
  taskId,
  isQuiz,
  taskType,
  quizType,
  formTab,
  newPage,
  chosenTaskPageId,
  addNewPageElement,
  goBackOneFormHandler,
  appendNewPageToTask,
  removePageElementFromPage,
  editPageElementWithId,
  editPageElement,
  updatePage,
  quizLastPageId
}: PageFormProps) => {
  const [pageForm, setPageForm] = useState<TaskPageUI | null>(null);
  const [reorderState, setReorderState] = useState<{
    isOpen: boolean;
    element?: TaskPageElementUI;
  }>({
    isOpen: false,
    element: undefined
  });
  /**
   * Fetch page details if chosenTaskPageId is not undefined
   * @param values - TaskPageUI
   */
  const setPageFormState = async (): Promise<void> => {
    // Add call for task page details
    if (isEditForm && chosenTaskPageId) {
      const response = await getTaskPage(chosenTaskPageId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      setPageForm(new TaskPageUI(response.taskPage));
      return;
    }

    newPage && setPageForm(newPage);
  };

  useEffect(() => {
    setPageFormState();
  }, [newPage]);
  /**
   * This function creates new page or updates existing one.
   * @param values - TaskPageUI
   */
  const pageFormHandler = async (
    values: TaskPageUI,
    { setSubmitting }: FormikHelpers<TaskPageUI>
  ): Promise<void> => {
    setSubmitting(true);

    if (isEditForm && taskId && formTab === TaskFormTypeEnum.PAGE_FORM) {
      const response = await updateTaskPage(values, taskId, values.id);

      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }

      toast.success(response.message);
      if (quizLastPageId) {
        const reorderLastPage = await reorderTaskPage(
          quizLastPageId,
          values.pageNumber + 1
        );
      }

      goBackOneFormHandler();
      return;
    }

    if (!isEditForm) {
      if (formTab === TaskFormTypeEnum.PAGE_FORM) {
        appendNewPageToTask && appendNewPageToTask(values);
        return;
      }
      if (formTab === TaskFormTypeEnum.EDIT_PAGE_FORM) {
        updatePage && updatePage(values);
        return;
      }
    }

    // Should never happen
    toast.error("Page form didn't know witch action to call!");
  };

  return (
    <>
      {pageForm && (
        <Card className={classes.card}>
          <Formik
            enableReinitialize={true}
            initialValues={pageForm}
            onSubmit={pageFormHandler}
            validate={taskPageFormValidation}
          >
            {({
              values,
              touched,
              errors,
              isSubmitting,
              setFieldValue,
              handleSubmit
            }) => {
              const handleReorderElement = async (
                element?: TaskPageElementUI
              ): Promise<void> => {
                if (isEditForm && element?.id) {
                  const response = await reorderTaskPageElements(
                    element?.id,
                    element?.sequenceNumber
                  );
                  if (response.statusType !== ResponseStatusType.OK) {
                    return;
                  }
                  setFieldValue("taskPageElements", response.taskPageElements);
                  toast.success("Reorder completed");
                }

                setReorderState({
                  isOpen: false,
                  element: undefined
                });
              };
              // ONLY QUIZ TASK
              // conditional for hiding add page element button because it can only be one question per page

              const isConsultationLastPage =
                quizType === TaskQuestionsTypesEnum.CONSULTATION &&
                newPage?.isLastPageFront;
              const shouldShowAddPageElementButton =
                !isConsultationLastPage ||
                taskType === TaskTypeEnum.MESSAGE ||
                taskType === TaskTypeEnum.LESSON;

              return (
                <form onSubmit={handleSubmit}>
                  <div className={classes.formHeader}>
                    <Typography className={classes.headerTitle} variant="h6">
                      Page {values.pageNumber} form
                    </Typography>
                    <Button
                      onClick={() => goBackOneFormHandler()}
                      variant="contained"
                    >
                      Back to Task Form
                    </Button>
                  </div>
                  <div className={classes.pageElementsLabel}>
                    <Typography className={classes.headerTitle} variant="h6">
                      Page {values.pageNumber} elements
                    </Typography>
                    {shouldShowAddPageElementButton && (
                      <Button
                        onClick={() => {
                          addNewPageElement &&
                            addNewPageElement(formTab, values);
                        }}
                        variant="contained"
                      >
                        Add new page element
                      </Button>
                    )}
                  </div>
                  <List component="nav" aria-label="secondary mailbox folder">
                    {values.taskPageElements?.map((element, i) => (
                      <ListItemButton key={i}>
                        <ListItemText
                          primary={`Element ${element.sequenceNumber}`}
                        />
                        <ListItemText primary={element.type} />
                        {isEditForm &&
                          formTab === TaskFormTypeEnum.EDIT_PAGE_FORM && (
                            <Button
                              onClick={() =>
                                setReorderState({
                                  isOpen: true,
                                  element: element
                                })
                              }
                              variant="contained"
                              color="success"
                            >
                              Reorder
                            </Button>
                          )}
                        <Button
                          onClick={() => {
                            if (element.id && editPageElementWithId) {
                              editPageElementWithId(values, element.id);
                              return;
                            }

                            editPageElement && editPageElement(values, element);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => {
                            removePageElementFromPage &&
                              removePageElementFromPage(
                                i,
                                element.id,
                                values,
                                setFieldValue
                              );
                          }}
                          variant="contained"
                          color="error"
                        >
                          Delete
                        </Button>
                      </ListItemButton>
                    ))}
                  </List>
                  <p className={classes.errorMessage}>
                    {touched.taskPageElements &&
                      Array.isArray(touched.taskPageElements) &&
                      touched.taskPageElements.length < 1 &&
                      errors.taskPageElements &&
                      errors.taskPageElements}
                  </p>
                  {(!isEditForm || formTab === TaskFormTypeEnum.PAGE_FORM) && (
                    <div className={classes.actions}>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="contained"
                      >
                        {formTab === TaskFormTypeEnum.PAGE_FORM
                          ? "Add page"
                          : "Update page"}
                      </Button>
                    </div>
                  )}
                  <Modal
                    open={reorderState.isOpen}
                    onClose={() =>
                      setReorderState({
                        isOpen: false,
                        element: undefined
                      })
                    }
                  >
                    <Box className={classes.modalBox}>
                      <div className={classes.formModalHeader}>
                        <Typography variant="h5">
                          Page {reorderState.element?.sequenceNumber}
                        </Typography>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setReorderState({
                              isOpen: false,
                              element: undefined
                            })
                          }
                        >
                          <CloseOutlined />
                        </div>
                      </div>
                      <TextField
                        fullWidth
                        id="standard-number"
                        label="Lesson position"
                        type="number"
                        name="position"
                        variant="standard"
                        value={reorderState.element?.sequenceNumber}
                        onChange={e =>
                          setReorderState(prevState =>
                            updateObject(prevState, {
                              element: {
                                ...prevState.element,
                                sequenceNumber: e.target.value
                              }
                            })
                          )
                        }
                      />
                      <Button
                        onClick={() => {
                          handleReorderElement(reorderState.element);
                        }}
                        style={{ marginTop: "40px" }}
                        variant="contained"
                      >
                        Save changes
                      </Button>
                    </Box>
                  </Modal>
                </form>
              );
            }}
          </Formik>
        </Card>
      )}
    </>
  );
};

export default PageForm;
