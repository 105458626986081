import { EditorState } from "draft-js";

// Enums
import {
  OnboardingQuestionsTypesEnum,
  QuestionAnswerTypeEnum
} from "../../../enums/app";

// Ui Models
import { OnboardingQuestionUI } from "../../app/ui";

// Requests Models
import { OnboardingQuestionAnswerRequest } from "./onboarding-question-answer.request";

// Utils
import { isEmpty, valueOrNull } from "../../../utils/global.utils";

export class QuestionRequest {
  id?: string;
  type: OnboardingQuestionsTypesEnum | string;
  title: EditorState | string;
  image: string | null;
  explanation: string | null;
  // is_skippable?: boolean;
  answer_type: QuestionAnswerTypeEnum;
  answers?: OnboardingQuestionAnswerRequest[] | null;

  constructor(onboardingQuestionUI: OnboardingQuestionUI) {
    this.id = onboardingQuestionUI.id;
    this.type = onboardingQuestionUI.type;
    this.title = onboardingQuestionUI.title;
    this.image = valueOrNull(onboardingQuestionUI.image);
    this.explanation = valueOrNull(onboardingQuestionUI.explanation);
    // this.is_skippable = onboardingQuestionUI.isSkippable;
    this.answer_type = onboardingQuestionUI.answerType;
    this.answers = !isEmpty(onboardingQuestionUI.answers)
      ? onboardingQuestionUI.answers?.map(
          answer => new OnboardingQuestionAnswerRequest(answer)
        )
      : null;
  }
}
