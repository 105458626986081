// DTOS
import { UserDTO } from "../dtos";

// DOMAIN Models
import { User } from "../../app/domain";

//Api Enums
import { ResponseStatusType } from "../../../enums/api";

export type UsersResponseDataType = {
  users: UserDTO[];
  statusType: ResponseStatusType;
};

export class UsersResponse {
  users: User[];
  statusType: ResponseStatusType;

  constructor(responseData: UsersResponseDataType) {
    this.users = responseData.users.map(user => new User(user));
    this.statusType = ResponseStatusType.OK;
  }
}
