// Dtos
import { OnboardingQuestionAnswerDto } from "../../api/dtos";

// App Enums
import { AnswerTypeEnum } from "../../../enums/app";

export class OnboardingQuestionAnswer {
  id: string;
  answer: string;
  type: AnswerTypeEnum;
  answerScore: number;
  isCorrect: boolean;
  sequenceNumber: number;

  constructor(entity: OnboardingQuestionAnswerDto) {
    this.id = entity.id;
    this.type = entity.type;
    this.answer = entity.answer;
    this.answerScore = entity.answer_score;
    this.isCorrect = entity.is_correct;
    this.sequenceNumber = entity.sequence_number;
  }
}
