import { ApiResponse } from "apisauce";

// Api
import { api } from "../../api";
import { getGeneralApiProblem } from "../../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem
} from "../../general-api-problems/general-api-problems.types";

// UI Models
import { ShopArticleUI } from "../../../models/app/ui";

// Requests
import {
  CreateShopItemRequest,
  UpdateShopItemRequest
} from "../../../models/api/requests";

// Response
import {
  ShopArticlesResponse,
  ShopArticlesDataType
} from "../../../models/api/responses/shop-articles.response";
import {
  ShopArticleResponse,
  ShopArticleResponseDataType
} from "../../../models/api/responses/shop-article.response";
import {
  MessageResponse,
  MessageResponseType
} from "../../../models/api/responses/message.response";

/**
 * fetch store items from backend
 * @returns ShopArticlesResponse or GeneralApiProblem
 */
export const fetchStoreItems = async (): Promise<
  ShopArticlesResponse | GeneralApiProblem
> => {
  const response: ApiResponse<ShopArticlesDataType, ExceptionPayload> =
    await api.apisauce.get("/shop");

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as ShopArticlesDataType;
  return new ShopArticlesResponse(responseData);
};

/**
 * create new store items on backend
 * @returns ShopArticleResponse or GeneralApiProblem
 */
export const createStoreItem = async (
  shopArticleUI: ShopArticleUI
): Promise<ShopArticleResponse | GeneralApiProblem> => {
  const requestPayload = new CreateShopItemRequest(shopArticleUI);
  const response: ApiResponse<ShopArticleResponseDataType, ExceptionPayload> =
    await api.apisauce.post("/shop", requestPayload);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as ShopArticleResponseDataType;
  return new ShopArticleResponse(responseData);
};

/**
 * fetch store item details from backend
 * @param id - string
 * @returns ShopArticleResponse or GeneralApiProblem
 */
export const fetchStoreItemDetails = async (
  id: string
): Promise<ShopArticleResponse | GeneralApiProblem> => {
  const response: ApiResponse<ShopArticleResponseDataType, ExceptionPayload> =
    await api.apisauce.get(`/shop/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as ShopArticleResponseDataType;
  return new ShopArticleResponse(responseData);
};

/**
 * Update chosen store item on backend
 * @param shopArticleUI - ShopArticleUI
 * @param id - string
 * @returns ShopArticleResponse or GeneralApiProblem
 */
export const updateStoreItem = async (
  shopArticleUI: ShopArticleUI,
  id: string
): Promise<ShopArticleResponse | GeneralApiProblem> => {
  const requestPayload = new UpdateShopItemRequest(shopArticleUI, id);

  const response: ApiResponse<ShopArticleResponseDataType, ExceptionPayload> =
    await api.apisauce.patch(`/shop/${id}`, requestPayload);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as ShopArticleResponseDataType;
  return new ShopArticleResponse(responseData);
};

/**
 * Delete shop item request
 * @param id - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const deleteTask = async (
  id: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/shop/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};
