// Theme
import { TextField, Button, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

// Components
import { FileUpload } from "../../../components/UI";

// UI Models
import { ShopArticleUI } from "../../../models/app/ui";

// Styles
import classes from "./ItemForm.module.css";

const ItemForm: React.FC<{
  formTitle: string;
  submitText: string;
  values: ShopArticleUI;
  errors: any;
  touched: any;
  setFieldValue: (fieldName: string, value: any) => void;
  handleBlur: (e: React.FormEvent) => void;
  handleChange: (e: React.FormEvent) => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
  handleClose: () => void;
}> = props => {
  return (
    <>
      <form onSubmit={props.handleSubmit} autoComplete="new-password">
        <div className={classes.formModalHeader}>
          <Typography variant="h5">{props.formTitle}</Typography>
          <div style={{ cursor: "pointer" }} onClick={props.handleClose}>
            <CloseOutlined />
          </div>
        </div>

        <div className={classes.textFieldContainer}>
          <TextField
            fullWidth
            id="name"
            type="text"
            name="name"
            label="Name"
            error={props.touched.name && props.errors.name ? true : false}
            value={props.values.name}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
          <p className={classes.errorMessage}>
            {props.touched.name && props.errors.name && props.errors.name}
          </p>
        </div>
        <div className={classes.textFieldContainer}>
          <TextField
            fullWidth
            id="price"
            type="text"
            name="price"
            label="Price"
            error={props.touched.price && props.errors.price ? true : false}
            value={props.values.price}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
          <p className={classes.errorMessage}>
            {props.touched.price && props.errors.price && props.errors.price}
          </p>
        </div>

        <div className={classes.textFieldContainer}>
          <TextField
            fullWidth
            id="link"
            type="text"
            name="link"
            label="Link"
            error={props.touched.link && props.errors.link ? true : false}
            value={props.values.link}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
          <p className={classes.errorMessage}>
            {props.touched.link && props.errors.link && props.errors.link}
          </p>
        </div>

        <div className={classes.textFieldContainer}>
          <FileUpload
            name="image"
            id="image"
            value={props.values.image}
            setFieldValue={props.setFieldValue}
            touched={props.touched.image}
            error={props.errors.image}
            placeholder="Upload Image"
            className={classes.fileUpload}
            inputValueClassName={classes.valueStyles}
            buttonsClassName={classes.fileUploadButtons}
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            type="submit"
            disabled={props.isSubmitting}
          >
            {props.submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ItemForm;
