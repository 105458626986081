// DTOS
import { TaskPageDTO } from "../dtos";
// DOMAIN Models
import { TaskPage } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type TaskPagesResponseDataType = {
  taskPages: TaskPageDTO[];
  statusType: ResponseStatusType;
};

export class TaskPagesResponse {
  taskPages: TaskPage[];
  statusType: ResponseStatusType;

  constructor(responseData: TaskPagesResponseDataType) {
    this.taskPages = responseData.taskPages.map(page => new TaskPage(page));
    this.statusType = ResponseStatusType.OK;
  }
}
