import { useState, useEffect } from "react";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";

// Theme
import {
  Card,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button
} from "@mui/material";
import Typography from "@mui/material/Typography";

// Forms-based-on-type
import TextForm from "./forms-based-on-type/Text.form";
import FileForm from "./forms-based-on-type/File.form";
import ButtonForm from "./forms-based-on-type/Button.form";
import QuestionForm from "./forms-based-on-type/Question.form";

// Builders
import {
  generatePageElementTypeOptions,
  fileUploadTypes,
  buttonTypes
} from "../../../../builders/task-page-elements/task-page-elements.builder";

// Validations
import { taskPageElementsValidationSchema } from "./TaskPageElementForm.validation";

//App Enums
import {
  TaskFormTypeEnum,
  TaskPageElementTypeEnum
} from "../../../../enums/app";

// Api Enums
import { ResponseStatusType } from "../../../../enums/api";

// Models
import {
  TaskPageElementUI,
  TaskPageUI,
  TaskQuestionUI
} from "../../../../models/app/ui";

// Utils
import * as utils from "../../../../utils/global.utils";

// Calls
import {
  updateTaskPage,
  getTaskPageElement
} from "../../../../api/calls/task/task";

// Contracts
import { PageElementFormProps } from "./contracts/page-element-form.types";

// Styles
import classes from "./pageElementesForm.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const PageElementForm = ({
  taskId,
  isEditForm = false,
  isQuiz,
  pageId,
  chosenPage,
  taskType,
  formTab,
  newChosenPageElement,
  chosenPageElementId,
  goBackOneFormHandler,
  appendNewPageElementToChosenPage,
  updatePageElementToChosenPage,
  removeAnswerFromQuestion
}: PageElementFormProps) => {
  const isFirstPage = chosenPage?.isFirstPageFront;
  const isLastPage = chosenPage?.isLastPageFront;
  const [pageElementForm, setPageElementForm] =
    useState<TaskPageElementUI | null>(null);
  let modifiedPageElementForm;

  /**
   * Fetch page element details if edit form and chosenPageElementId not undefined
   */
  const setTaskPageElementFormState = async (): Promise<void> => {
    // Add call for task details
    if (
      isEditForm &&
      chosenPageElementId &&
      formTab === TaskFormTypeEnum.EDIT_PAGE_ELEMENT_FORM
    ) {
      const response = await getTaskPageElement(chosenPageElementId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      setPageElementForm(new TaskPageElementUI(response.taskPageElement));
      return;
    }

    newChosenPageElement && setPageElementForm(newChosenPageElement);
  };

  // Sets values for taskForm
  useEffect(() => {
    setTaskPageElementFormState();
  }, []);

  /**
   * This function creates new page element and appends to chosenPage or updates chosen page element in chosenPage
   * Also it here we convert all EditorStates to strings
   * @param values - TaskPageElementUI
   */
  const pageElementFormHandler = async (
    values: TaskPageElementUI,
    { setSubmitting }: FormikHelpers<TaskPageElementUI>
  ): Promise<void> => {
    setSubmitting(true);

    /**
     * Formats all Editor states to HTML strings
     * and appends to fomratedContentValue
     */
    let fomratedContentValue;
    if (
      values.type === TaskPageElementTypeEnum.TEXT &&
      values.content instanceof EditorState
    ) {
      fomratedContentValue = new TaskPageElementUI({
        ...values,
        content: draftToHtml(convertToRaw(values.content.getCurrentContent()))
      });
    }

    if (
      values.type === TaskPageElementTypeEnum.QUESTION &&
      values.question &&
      values.question.title instanceof EditorState
    ) {
      fomratedContentValue = new TaskPageElementUI({
        ...values,
        question: new TaskQuestionUI({
          ...values.question,
          title: draftToHtml(
            convertToRaw(values.question.title.getCurrentContent())
          )
        })
      });
    }

    /**
     * IF condition is met we make update task call on server
     */
    if (isEditForm && chosenPage && pageId && taskId) {
      const updateTaskPagePayload = new TaskPageUI({
        ...chosenPage,
        taskPageElements: [fomratedContentValue ? fomratedContentValue : values]
      });
      const response = await updateTaskPage(
        updateTaskPagePayload,
        taskId,
        pageId
      );
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }

      toast.success(response.message);
      goBackOneFormHandler(pageId, chosenPage);
      return;
    }

    /**
     * IF it isn't edit task form or pageId = unedfiend we do appending logic
     */
    if (!isEditForm || !pageId) {
      if (
        formTab === TaskFormTypeEnum.PAGE_ELEMENT_FORM &&
        appendNewPageElementToChosenPage
      ) {
        appendNewPageElementToChosenPage(
          fomratedContentValue ? fomratedContentValue : values
        );
        return;
      }

      if (
        formTab === TaskFormTypeEnum.EDIT_PAGE_ELEMENT_FORM &&
        updatePageElementToChosenPage
      ) {
        updatePageElementToChosenPage(
          fomratedContentValue ? fomratedContentValue : values
        );
        return;
      }
    }

    // Should never happen
    toast.error("Page Element form didn't know witch action to call!");
  };

  if (pageElementForm) {
    /**
     * modefiedChosenPageElement =  pageElementForm
     */
    modifiedPageElementForm = pageElementForm;
    /**
     * If pageElementForm type is text
     * convert content from HTML string to EditorState
     * append chosen page elelemnt to modefiedChosenPageElement
     * modefiedChosenPageElement send form
     */
    if (pageElementForm.type === TaskPageElementTypeEnum.TEXT) {
      modifiedPageElementForm = utils.updateInstanceObject(
        TaskPageElementUI,
        pageElementForm,
        {
          content: utils.convertStringToEditorState(pageElementForm.content)
        }
      );
    }

    /**
     * If pageElementForm type is question
     * convert title from HTML string to EditorState
     * if answer with type OPEN_TEXT exists append answer to openTextAnswer param on top
     * removes answer with type OPEN_TEXT from answers
     * appends all question changes to modefiedChosenPageElement and pass it to form
     */
    if (pageElementForm.type === TaskPageElementTypeEnum.QUESTION) {
      modifiedPageElementForm = utils.updateInstanceObject(
        TaskPageElementUI,
        pageElementForm,
        {
          question: pageElementForm.question
            ? utils.updateInstanceObject(
                TaskQuestionUI,
                pageElementForm.question,
                {
                  title: utils.convertStringToEditorState(
                    pageElementForm.question.title
                  )
                }
              )
            : null
        }
      );
    }
  }

  return (
    <>
      {modifiedPageElementForm && (
        <Card className={classes.card}>
          <Formik
            enableReinitialize={true}
            initialValues={modifiedPageElementForm}
            onSubmit={pageElementFormHandler}
            validationSchema={taskPageElementsValidationSchema}
          >
            {({
              values,
              touched,
              errors,
              isSubmitting,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldTouched,
              resetForm
            }) => {
              /**
               * This function resets form to inital value and sets type
               * If type is question it creates TaskQuestionUI instance
               * @param type - TaskPageElementTypeEnum || string
               */
              const handleTypeChange = (
                type: TaskPageElementTypeEnum | string
              ): void => {
                resetForm();
                if (type === TaskPageElementTypeEnum.QUESTION) {
                  setFieldValue("question", new TaskQuestionUI());
                }
                setFieldValue("type", type);
              };

              return (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className={classes.formHeader}>
                    <Typography className={classes.headerTitle} variant="h6">
                      Page element {values.sequenceNumber} form
                    </Typography>
                    <Button
                      onClick={() => goBackOneFormHandler(pageId, chosenPage)}
                      variant="contained"
                    >
                      Back to Pages Form
                    </Button>
                  </div>
                  {(!isQuiz || isFirstPage || isLastPage) &&
                    formTab === TaskFormTypeEnum.PAGE_ELEMENT_FORM && (
                      <div className={classes.taskFormContainer}>
                        <div className={classes.fullWidth}>
                          <FormControl
                            error={touched.type && errors.type ? true : false}
                            variant="standard"
                            sx={{ width: "100%" }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={values.type}
                              onBlur={() => setFieldTouched("type", true)}
                              onChange={e => {
                                if (e.target.value !== values.type) {
                                  handleTypeChange(e.target.value);
                                }
                              }}
                              label="Type"
                              name="type"
                            >
                              {generatePageElementTypeOptions(
                                taskType,
                                isFirstPage,
                                isLastPage
                              ).map(({ value, label }, i) => (
                                <MenuItem key={i} value={value}>
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <p className={classes.errorMessage}>
                            {touched.type && errors.type && errors.type}
                          </p>
                        </div>
                      </div>
                    )}

                  {values.type === TaskPageElementTypeEnum.TEXT && (
                    <TextForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {values.type && fileUploadTypes.includes(values.type) && (
                    <FileForm
                      pageElementType={values.type}
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {values.type && buttonTypes.includes(values.type) && (
                    <ButtonForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldTouched={setFieldTouched}
                    />
                  )}

                  {values.type === TaskPageElementTypeEnum.QUESTION &&
                    (formTab === TaskFormTypeEnum.PAGE_ELEMENT_FORM ||
                      formTab === TaskFormTypeEnum.EDIT_PAGE_ELEMENT_FORM) && (
                      <QuestionForm
                        formTab={formTab}
                        pageId={pageId}
                        pageElementId={values.id}
                        isQuiz={isQuiz}
                        taskType={taskType}
                        touched={touched}
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        removeAnswerFromQuestion={removeAnswerFromQuestion}
                      />
                    )}
                  <div className={classes.actions}>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                    >
                      {formTab === TaskFormTypeEnum.PAGE_ELEMENT_FORM
                        ? "Add element to page "
                        : "Update page element"}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Card>
      )}
    </>
  );
};

export default PageElementForm;
