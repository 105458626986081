import { ApiResponse } from "apisauce";

// Api
import { api } from "../../api";
import { getGeneralApiProblem } from "../../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem
} from "../../general-api-problems/general-api-problems.types";

// Enums
import { ResponseStatusType } from "../../../enums/api";

// UI Models
import { AdminUI } from "../../../models/app/ui";

// Response Models
import {
  UsersResponseDataType,
  UsersResponse
} from "../../../models/api/responses/users.response";
import {
  UserResponseDataType,
  UserResponse
} from "../../../models/api/responses/user.response";
import {
  MessageResponseType,
  MessageResponse
} from "../../../models/api/responses/message.response";

// Requests Model
import {
  CreateAdminRequest,
  UpdateAdminRequest
} from "../../../models/api/requests";

/**
 * Fetch users with chosen role
 * @param role - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const fetchUsers = async (
  role: string
): Promise<UsersResponse | GeneralApiProblem> => {
  const response: ApiResponse<UsersResponseDataType, ExceptionPayload> =
    await api.apisauce.get(`/user?role=${role}`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  const responseData = response.data as UsersResponseDataType;

  return new UsersResponse(responseData);
};

/**
 * Fetch users with chosen role
 * @param adminUI - AdminUI
 * @returns MessageResponse or GeneralApiProblem
 */
export const createAdminUser = async (
  adminUI: AdminUI
): Promise<MessageResponse | GeneralApiProblem> => {
  const payload = new CreateAdminRequest(adminUI);
  const response: ApiResponse<UserResponseDataType, ExceptionPayload> =
    await api.apisauce.post(`/admin-auth/registration`, payload);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  return new MessageResponse({
    message: "Admin successfully created!",
    statusType: ResponseStatusType.OK
  });
};

/**
 * Fetch chosen user
 * @param id - string
 * @returns UserResponse or GeneralApiProblem
 */
export const fetchChosenAdmin = async (
  id: string
): Promise<UserResponse | GeneralApiProblem> => {
  const response: ApiResponse<UserResponseDataType, ExceptionPayload> =
    await api.apisauce.get(`/user/${id}`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  const responseData = response.data as UserResponseDataType;
  return new UserResponse(responseData);
};

/**
 * Fetch users with chosen role
 * @param adminUI - AdminUI
 * @returns MessageResponse or GeneralApiProblem
 */
export const updateAdminUser = async (
  adminUI: AdminUI,
  accountId: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const payload = new UpdateAdminRequest(adminUI);
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.patch(`/account/${accountId}`, payload);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};

/**
 * Delete user request
 * @param id - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const deleteUser = async (
  id: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/account/${id}`);

  if (!response.ok) {
    return getGeneralApiProblem(response);
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};
