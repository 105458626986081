// Enums
import { OnboardingPageElementTypeEnum } from "../../../enums/app";

// Dtos
import { OnboardingPageElementDto } from "../../api/dtos";

// Domain models
import { OnboardingQuestion } from "./onboarding-question.model";
import { FileModel } from "./file.model";

// Utils
import { isEmpty } from "../../../utils/global.utils";

export class OnboardingPageElement {
  id: string;
  sequenceNumber: number;
  type: OnboardingPageElementTypeEnum;
  content?: string;
  color?: string;
  // link?: string;
  file: FileModel | null;
  coverImage: FileModel | null;
  question: OnboardingQuestion | null;

  constructor(entity: OnboardingPageElementDto) {
    this.id = entity.id;
    this.sequenceNumber = entity.sequence_number;
    this.type = entity.type;
    this.content = entity.content;
    this.color = entity.color;
    // this.link = entity.link;
    this.file = !isEmpty(entity.file) ? new FileModel(entity.file) : null;
    this.coverImage = !isEmpty(entity.file?.cover_image)
      ? new FileModel(entity.file.cover_image)
      : null;
    this.question = entity.question
      ? new OnboardingQuestion(entity.question)
      : null;
  }
}
