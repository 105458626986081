import { ConsentDTO } from "../../api/dtos";

export class Consent {
  id: string;
  name: string;

  constructor(entity: ConsentDTO) {
    this.id = entity.id;
    this.name = entity.name;
  }
}
