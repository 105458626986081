// App Enums
import { KpiTypeEnum } from "../../enums/app";

type KpiTypeOptions = {
  type: KpiTypeEnum;
  name: string;
};

export const kpiTypeOptions: KpiTypeOptions[] = [
  {
    type: KpiTypeEnum.WATER_INTAKE,
    name: "Water Intake"
  },
  {
    type: KpiTypeEnum.EXERCISE_TRACKER,
    name: "Exercise Traker"
  },
  {
    type: KpiTypeEnum.MEDITATION_TRACKER,
    name: "Meditation Tracker"
  },
  {
    type: KpiTypeEnum.CONFIDENCE_TRACKER,
    name: "Confidence Tracker"
  },
  {
    type: KpiTypeEnum.STRESS_TRACKER,
    name: "Stress Tracker"
  }
];
