// UI Models
import { OnboardingPageUI } from "../../app/ui";

// REQUEST Models
import { OnboardingPageElementRequest } from "./onboarding-page-element.request";

export class UpdateOnboardingRequest {
  id?: string;
  task_id: string;
  page_number: number;
  task_page_elements?: OnboardingPageElementRequest[];

  constructor(
    onboardingPageUI: OnboardingPageUI,
    onboardingId: string,
    onboardingPageId?: string
  ) {
    this.id = onboardingPageId;
    this.task_id = onboardingId;
    this.page_number = onboardingPageUI.pageNumber;
    this.task_page_elements = onboardingPageUI.taskPageElements?.map(
      element => new OnboardingPageElementRequest(element)
    );
  }
}
