// DTOS
import { OnboardingPageElementDto } from "../dtos";
// DOMAIN Models
import { OnboardingPageElement } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type OnboardingPageElementsResponseDataType = {
  task_page_elements: OnboardingPageElementDto[];
  statusType: ResponseStatusType;
};

export class OnboardingPageElementsResponse {
  onboardingPageElements: OnboardingPageElement[];
  statusType: ResponseStatusType;

  constructor(responseData: OnboardingPageElementsResponseDataType) {
    this.onboardingPageElements = responseData.task_page_elements.map(
      element => new OnboardingPageElement(element)
    );
    this.statusType = ResponseStatusType.OK;
  }
}
