import { useState, useEffect } from "react";
import { FormikHelpers, useFormik } from "formik";
import { toast } from "react-toastify";

// Theme
import {
  Card,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  TextField,
  Box
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";

// Validations
import { fitnessPageFormValidation } from "./fitnessPageForm.validation";

// Enums
import { FitnessFormTypeEnum } from "../../../../enums/app";
import { ResponseStatusType } from "../../../../enums/api";

// UI Models
import { FitnessPageElementUI, FitnessPageUI } from "../../../../models/app/ui";

// Calls
import {
  fetchFitnessPage,
  updateFitnessPage,
  reorderFitnessPageElements
} from "../../../../api/calls/fitness/fitness";

// Utils
import { updateObject } from "../../../../utils/global.utils";

// Contracts
import { PageFormProps } from "./contracts/fitness-page-form.type";

// Styles
import classes from "./fitnessPageForm.module.css";

const FitnessPageForm = ({
  pageId,
  page,
  formTab,
  fitnessId,
  goBackOneFormHandler,
  addNewPageElement,
  editPageElement,
  appendNewPageToFitness,
  updatePage,
  editPageElementWithId,
  removePageElementFromPage,
  isEditForm = false
}: PageFormProps) => {
  const [pageForm, setPageForm] = useState<FitnessPageUI | undefined>(
    undefined
  );
  const [reorderState, setReorderState] = useState<{
    isOpen: boolean;
    element?: FitnessPageElementUI;
  }>({
    isOpen: false,
    element: undefined
  });

  const setPageFormValues = async (): Promise<void> => {
    if (isEditForm && pageId) {
      const response = await fetchFitnessPage(pageId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      setPageForm(new FitnessPageUI(response.fitnessPage));
      return;
    }

    page && setPageForm(page);
  };

  useEffect(() => {
    setPageFormValues();
  }, []);
  /**
   * Append new page or updates existing one to fitness form based on formTab.
   * @param values - FitnessPageUI
   */
  const pageFormHandler = async (
    values: FitnessPageUI,
    { setSubmitting }: FormikHelpers<FitnessPageUI>
  ): Promise<void> => {
    setSubmitting(true);

    if (isEditForm && fitnessId && formTab === FitnessFormTypeEnum.PAGE_FORM) {
      const response = await updateFitnessPage(fitnessId, values, values.id);

      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }

      toast.success(response.message);
    }
    if (formTab === FitnessFormTypeEnum.PAGE_FORM) {
      appendNewPageToFitness(values);
      return;
    }
    if (formTab === FitnessFormTypeEnum.EDIT_PAGE_FORM) {
      updatePage(values);
      return;
    }

    // Should never happen
    toast.error("Page form didn't know witch action to call!");
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: pageForm ? pageForm : new FitnessPageUI(),
    onSubmit: pageFormHandler,
    validate: fitnessPageFormValidation
  });

  const { values, errors, touched, handleSubmit, setFieldValue, isSubmitting } =
    formik;

  const handleReorderElement = async (
    element?: FitnessPageElementUI
  ): Promise<void> => {
    if (isEditForm && element?.id) {
      const response = await reorderFitnessPageElements(
        element?.id,
        element?.sequenceNumber
      );
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      setFieldValue("pageElements", response.fitnessPageElements);
      toast.success("Reorder completed");
    }

    setReorderState({
      isOpen: false,
      element: undefined
    });
  };

  return (
    <>
      {pageForm && (
        <Card className={classes.card}>
          <form onSubmit={handleSubmit}>
            <div className={classes.formHeader}>
              <Typography className={classes.headerTitle} variant="h6">
                Page {values.pageNumber} form
              </Typography>
              <Button
                onClick={() => goBackOneFormHandler()}
                variant="contained"
              >
                Back to Task Form
              </Button>
            </div>

            <div className={classes.pageElementsLabel}>
              <Typography className={classes.headerTitle} variant="h6">
                Page {values.pageNumber} elements
              </Typography>
              <Button
                onClick={() => {
                  addNewPageElement(formTab, values);
                }}
                variant="contained"
              >
                Add new page element
              </Button>
            </div>
            <List component="nav" aria-label="secondary mailbox folder">
              {values.pageElements?.map((element, i) => (
                <ListItemButton key={i}>
                  <ListItemText primary={`Element ${element.sequenceNumber}`} />
                  <ListItemText primary={`${element.type}`} />
                  {isEditForm &&
                    formTab === FitnessFormTypeEnum.EDIT_PAGE_FORM && (
                      <Button
                        onClick={() =>
                          setReorderState({
                            isOpen: true,
                            element: element
                          })
                        }
                        variant="contained"
                        color="success"
                      >
                        Reorder
                      </Button>
                    )}
                  <Button
                    onClick={() => {
                      if (isEditForm && editPageElementWithId && element.id) {
                        editPageElementWithId(element.id, values);
                        return;
                      }
                      editPageElement(values, element);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() =>
                      removePageElementFromPage(
                        i,
                        element.id,
                        values,
                        setFieldValue
                      )
                    }
                    variant="contained"
                    color="error"
                  >
                    Delete
                  </Button>
                </ListItemButton>
              ))}
            </List>
            <p className={classes.errorMessage}>
              {touched.pageElements &&
                Array.isArray(touched.pageElements) &&
                touched.pageElements.length < 1 &&
                errors.pageElements &&
                errors.pageElements}
            </p>
            {!pageId && (
              <div className={classes.actions}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                >
                  {formTab === FitnessFormTypeEnum.PAGE_FORM
                    ? "Add page"
                    : "Update page"}
                </Button>
              </div>
            )}
            <Modal
              open={reorderState.isOpen}
              onClose={() =>
                setReorderState({
                  isOpen: false,
                  element: undefined
                })
              }
            >
              <Box className={classes.modalBox}>
                <div className={classes.formModalHeader}>
                  <Typography variant="h5">
                    Page {reorderState.element?.sequenceNumber}
                  </Typography>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setReorderState({
                        isOpen: false,
                        element: undefined
                      })
                    }
                  >
                    <CloseOutlined />
                  </div>
                </div>
                <TextField
                  fullWidth
                  id="standard-number"
                  label="Lesson position"
                  type="number"
                  name="position"
                  variant="standard"
                  value={reorderState.element?.sequenceNumber}
                  onChange={e =>
                    setReorderState(prevState =>
                      updateObject(prevState, {
                        element: {
                          ...prevState.element,
                          sequenceNumber: e.target.value
                        }
                      })
                    )
                  }
                />
                <Button
                  onClick={() => {
                    handleReorderElement(reorderState.element);
                  }}
                  style={{ marginTop: "40px" }}
                  variant="contained"
                >
                  Save changes
                </Button>
              </Box>
            </Modal>
          </form>
        </Card>
      )}
    </>
  );
};

export default FitnessPageForm;
