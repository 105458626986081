import { TableCellProps } from "@mui/material";

type Column = {
  id: string;
  label: string;
  minWidth: number;
  align?: TableCellProps["align"];
  format?: (value: any) => void;
};

export type Row = {
  id?: string;
  name?: string;
  type?: string;
};

export const columns: Column[] = [
  { id: "name", label: "Name", minWidth: 200 },
  { id: "type", label: "Type", align: "center", minWidth: 200 },
  {
    id: "id",
    label: "Actions",
    minWidth: 170,
    align: "right"
  }
];

export const createData = (name: string, id: string, type: string): Row => {
  return { name, id, type };
};
