// Theme
import { TextField, Button, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

// UI Models
import { AdminUI } from "../../../models/app/ui";

// Styles
import classes from "./ItemForm.module.css";

const ItemForm: React.FC<{
  isEditForm?: boolean;
  formTitle: string;
  submitText: string;
  values: AdminUI;
  errors: any;
  touched: any;
  setFieldValue: (fieldName: string, value: any) => void;
  handleBlur: (e: React.FormEvent) => void;
  handleChange: (e: React.FormEvent) => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
  handleClose: () => void;
}> = props => {
  return (
    <>
      <form onSubmit={props.handleSubmit} autoComplete="new-password">
        <div className={classes.formModalHeader}>
          <Typography variant="h5">{props.formTitle}</Typography>
          <div style={{ cursor: "pointer" }} onClick={props.handleClose}>
            <CloseOutlined />
          </div>
        </div>

        <div className={classes.textFieldContainer}>
          <TextField
            fullWidth
            id="firstName"
            type="text"
            name="firstName"
            label="First name"
            error={
              props.touched.firstName && props.errors.firstName ? true : false
            }
            value={props.values.firstName}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
          <p className={classes.errorMessage}>
            {props.touched.firstName &&
              props.errors.firstName &&
              props.errors.firstName}
          </p>
        </div>

        <div className={classes.textFieldContainer}>
          <TextField
            fullWidth
            id="lastName"
            type="text"
            name="lastName"
            label="Last name"
            error={
              props.touched.lastName && props.errors.lastName ? true : false
            }
            value={props.values.lastName}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
          <p className={classes.errorMessage}>
            {props.touched.lastName &&
              props.errors.lastName &&
              props.errors.lastName}
          </p>
        </div>

        <div className={classes.textFieldContainer}>
          <TextField
            fullWidth
            id="email"
            type="text"
            name="email"
            label="Email"
            error={props.touched.email && props.errors.email ? true : false}
            value={props.values.email}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          />
          <p className={classes.errorMessage}>
            {props.touched.email && props.errors.email && props.errors.email}
          </p>
        </div>

        {!props.isEditForm && (
          <div className={classes.textFieldContainer}>
            <TextField
              fullWidth
              id="password"
              type="password"
              name="password"
              label="Password"
              error={
                props.touched.password && props.errors.password ? true : false
              }
              value={props.values.password}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
            />
            <p className={classes.errorMessage}>
              {props.touched.password &&
                props.errors.password &&
                props.errors.password}
            </p>
          </div>
        )}
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            type="submit"
            disabled={props.isSubmitting}
          >
            {props.submitText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ItemForm;
