import { useEffect, useState } from "react";
import { Formik, FormikHelpers, Field, FieldProps, FieldArray } from "formik";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

// theme
import {
  Card,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  Box
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import DateAdapter from "@mui/lab/AdapterMoment";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

// Components
import { CustomColorPicker } from "../../../../components/UI";

// Builders
import { generateQuestionTypeOptions } from "../../../../builders/task-page-elements/task-page-elements.builder";
import { kpiTypeOptions } from "../../../../builders/task/task.builder";

// Validations
import { taskValidationSchema } from "./TaskForm.validation";

// Ui Models
import {
  TaskUI,
  TaskPageUI,
  TaskPageElementUI,
  QuizCompletionMessageUI
} from "../../../../models/app/ui";

// Domain Models
import { TaskOption } from "../../../../models/app/domain";

// Contracts
import { TaskFormProps } from "./contracts/task-form.types";

// Enums
import {
  TaskTypeEnum,
  TaskLifecycleTypeEnum,
  TaskQuestionsTypesEnum,
  TaskPageElementTypeEnum,
  KpiTypeEnum
} from "../../../../enums/app";

import { ResponseStatusType } from "../../../../enums/api";

// Utils
import { isEmpty, updateObject } from "../../../../utils/global.utils";

// Calls
import {
  createTask,
  updateTask,
  getTask,
  reorderTaskPage
} from "../../../../api/calls/task/task";

// Styles
import classes from "./taskForm.module.css";

const TaskForm = ({
  taskId,
  isKpiForm,
  isEditForm = false,
  taskOptions,
  newTask,
  setTaskParamsToGlobalState,
  addNewPage,
  editPage,
  editPageWithId,
  removePageFromTask,
  setQuizLastPageId,
  removeQuizCompletionMessage
}: TaskFormProps) => {
  const history = useHistory();
  const [taskForm, setTaskForm] = useState<TaskUI | null>(null);
  const [triggeredByTask, setTriggeredByTask] = useState<TaskOption | null>(null);
  const [extendedTaskOptions, setExtendedTaskOptions] = useState<TaskOption[]>(taskOptions);
  const [reorderState, setReorderState] = useState<{
    isOpen: boolean;
    page?: TaskPageUI;
  }>({
    isOpen: false,
    page: undefined
  });
  /**
   * Fetch task details and set some global params if edit form
   */
  const setTaskFormState = async (): Promise<void> => {
    // Add call for task details
    if (isEditForm && taskId) {
      const response = await getTask(taskId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      const taskUI = new TaskUI(response.task);
      let lastPageId = undefined;
      if (taskUI.taskType === TaskTypeEnum.QUIZ) {
        lastPageId = taskUI.taskPages.find(page => page.isLastPageFront)?.id;
        setQuizLastPageId && setQuizLastPageId(lastPageId);
      }
      setTaskForm(taskUI);

      fetchLessonWhichTriggeredTheTask(taskUI);

      setTaskParamsToGlobalState &&
        setTaskParamsToGlobalState(
          taskUI.lifecycleType !== TaskLifecycleTypeEnum.KPI
            ? taskUI.taskType
            : taskUI.lifecycleType
        );

      return;
    }

    newTask && setTaskForm(newTask);
  };

  /**
   * Fetches the Lesson from which the task is created
   */
  const fetchLessonWhichTriggeredTheTask = async (task:TaskUI|null):Promise<void> => {
    if (task?.triggeredByTaskId) {
      const response = await getTask(task.triggeredByTaskId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }

      const taskWhichTriggeredTheTask = new TaskOption({
        ...response.task,
        task_type: response.task.taskType
      });

      setTriggeredByTask(taskWhichTriggeredTheTask);
    }
  }

  // Sets values for taskForm
  useEffect(() => {
    setTaskFormState();
  }, []);

  // Get the lesson which triggered the task and set it to triggeredByTask when taskForm changes
  useEffect(() => {
    fetchLessonWhichTriggeredTheTask(taskForm);
  },[taskForm])

  // Expand the taskOptions list of task with the lesson that triggered the task on change of taskOptoins or triggeredByTask
  useEffect(() => {
    if (taskOptions.length > 0) {
      if (triggeredByTask !== null) {
        setExtendedTaskOptions([...taskOptions, triggeredByTask]);
      } else {
        setExtendedTaskOptions(taskOptions);
      }
    }
  },[taskOptions, triggeredByTask])

  /**
   * This function creates new task or updates chosen task on server and redirects to "/"
   * @param values - TaskPageUI
   */
  const taskFormHandler = async (
    values: TaskUI,
    { setSubmitting }: FormikHelpers<TaskUI>
  ): Promise<void> => {
    setSubmitting(true);
    if (isEditForm && taskId) {
      const response = await updateTask(values, taskId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
      history.push("/");
      return;
    }

    if (!isEditForm) {
      const response = await createTask(values);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
      history.push("/");
      return;
    }

    setSubmitting(false);
    // Should never happen
    toast.error("Task form didn't know witch action to call!");
  };

  return (
    <>
      {taskForm && (
        <>
          <Card className={classes.taskCard}>
            <Formik
              enableReinitialize={true}
              initialValues={taskForm}
              onSubmit={taskFormHandler}
              validationSchema={taskValidationSchema}
            >
              {({
                values,
                touched,
                errors,
                isSubmitting,
                handleSubmit,
                handleBlur,
                handleChange,
                setFieldValue,
                setFieldTouched
              }) => {
                const handleReorderPage = async (
                  page?: TaskPageUI
                ): Promise<void> => {
                  if (isEditForm && page?.id) {
                    const response = await reorderTaskPage(
                      page?.id,
                      page?.pageNumber
                    );
                    if (response.statusType !== ResponseStatusType.OK) {
                      return;
                    }
                    setFieldValue("taskPages", response.taskPages);
                    toast.success("Reorder completed");
                  }

                  setReorderState({
                    isOpen: false,
                    page: undefined
                  });
                };
                // ONLY QUIZ TASK
                // conditional for quiz hides fileds if quiz type isn't chosen
                const areQuizConditionsFulfiled =
                  values.taskType === TaskTypeEnum.QUIZ && values.quizTypeFront;

                // ONLY QUIZ TASK
                const handleQuizType = (
                  quizType: TaskQuestionsTypesEnum | string
                ): void => {
                  setFieldValue("taskPages", [
                    new TaskPageUI({
                      pageNumber: 1,
                      isFirstPageFront: true,
                      taskPageElements: [
                        new TaskPageElementUI({
                          sequenceNumber: 1,
                          type: TaskPageElementTypeEnum.TEXT,
                          content: "<p>Type here...</p>\n"
                        })
                      ]
                    }),
                    new TaskPageUI({
                      pageNumber: 2,
                      isLastPageFront: true,
                      taskPageElements: [
                        new TaskPageElementUI({
                          sequenceNumber: 1,
                          type: TaskPageElementTypeEnum.COMPLETE_BUTTON,
                          content: "Complete quiz",
                          color: "#E7AFC0"
                        })
                      ]
                    })
                  ]);

                  setFieldValue("quizTypeFront", quizType);
                };

                // ONLY MESSAGE TASK
                /**
                 * This function resets scheduledFor and triggeredByTaskId and sets lifecycleType
                 * @param type - TaskLifecycleTypeEnum
                 */
                const handleLifeCycleTypeChange = (
                  type: TaskLifecycleTypeEnum
                ) => {
                  if (type === TaskLifecycleTypeEnum.SCHEDULED) {
                    setFieldValue("triggeredByTaskId", "");
                    setFieldValue("triggerIn", 0);
                    setFieldValue("availableFor", 0);
                    setFieldValue("repetitionCount", 0);
                    setFieldValue("repetitionInterval", 0);
                  } else if (type === TaskLifecycleTypeEnum.TRIGGERED) {
                    setFieldValue("scheduledFor", null);
                    setFieldValue("availableTo", null);
                    setFieldValue("repetitionCount", 0);
                    setFieldValue("repetitionInterval", 0);
                  }

                  setFieldValue("lifecycleType", type);
                };

                // ONLY KPI TASK
                // conditional for kpi fiels
                const areKpiConditionsFulfiled =
                  isKpiForm || !isEmpty(values.kpiType);

                // ONLY KPI TASK
                /**
                 * Sets kpiType and taskType based on chosen kpiType
                 * @param kpiType - TaskLifecycleTypeEnum
                 */
                const handleKpiTypeChange = (
                  kpiType: KpiTypeEnum | null | string
                ): void => {
                  if (
                    kpiType === KpiTypeEnum.WATER_INTAKE ||
                    kpiType === KpiTypeEnum.EXERCISE_TRACKER
                  ) {
                    setFieldValue("taskType", TaskTypeEnum.PROGRESSIVE_KPI);
                  }
                  if (
                    kpiType === KpiTypeEnum.STRESS_TRACKER ||
                    kpiType === KpiTypeEnum.CONFIDENCE_TRACKER
                  ) {
                    setFieldValue("taskType", TaskTypeEnum.LEVEL_KPI);
                  }
                  if (kpiType === KpiTypeEnum.MEDITATION_TRACKER) {
                    setFieldValue("taskType", TaskTypeEnum.FULFILMENT_KPI);
                  }
                  setFieldValue("kpiType", kpiType);
                };

                return (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Typography className={classes.headerTitle} variant="h6">
                      Task form
                    </Typography>

                    {values.taskType === TaskTypeEnum.QUIZ && !isEditForm && (
                      <div className={classes.taskFormContainer}>
                        <div className={classes.fullWidth}>
                          <FormControl
                            error={
                              touched.quizTypeFront && errors.quizTypeFront
                                ? true
                                : false
                            }
                            variant="standard"
                            sx={{ width: "100%" }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Quiz type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={values.quizTypeFront}
                              onBlur={handleBlur}
                              onChange={e => handleQuizType(e.target.value)}
                              label="Type"
                              name="quizTypeFront"
                            >
                              {generateQuestionTypeOptions(values.taskType).map(
                                ({ value, label }, i) => (
                                  <MenuItem key={i} value={value}>
                                    {label}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            <p className={classes.errorMessage}>
                              {touched.quizTypeFront &&
                                errors.quizTypeFront &&
                                errors.quizTypeFront}
                            </p>
                          </FormControl>
                        </div>
                      </div>
                    )}

                    {areKpiConditionsFulfiled && !isEditForm && (
                      <div className={classes.taskFormContainer}>
                        <div className={classes.fullWidth}>
                          <FormControl
                            error={
                              touched.kpiType && errors.kpiType ? true : false
                            }
                            variant="standard"
                            sx={{ width: "100%" }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              KPI type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={values.kpiType}
                              onBlur={handleBlur}
                              onChange={e =>
                                handleKpiTypeChange(e.target.value)
                              }
                              label="KPI type"
                              name="kpiType"
                            >
                              {kpiTypeOptions.map(({ type, name }, i) => (
                                <MenuItem key={i} value={type}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                            <p className={classes.errorMessage}>
                              {touched.kpiType &&
                                errors.kpiType &&
                                errors.kpiType}
                            </p>
                          </FormControl>
                        </div>
                      </div>
                    )}

                    {(areQuizConditionsFulfiled ||
                      values.taskType === TaskTypeEnum.MESSAGE ||
                      values.taskType === TaskTypeEnum.LESSON ||
                      areKpiConditionsFulfiled) && (
                      <div className={classes.taskFormContainer}>
                        <div className={classes.halfWidth}>
                          <TextField
                            fullWidth
                            id="title"
                            type="text"
                            name="title"
                            label="Title"
                            variant="standard"
                            error={touched.title && errors.title ? true : false}
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <p className={classes.errorMessage}>
                            {touched.title && errors.title && errors.title}
                          </p>
                        </div>
                        <div className={classes.halfWidth}>
                          <CustomColorPicker
                            label="Title color"
                            name="titleColor"
                            error={
                              touched.titleColor && errors.titleColor
                                ? true
                                : false
                            }
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            value={values.titleColor}
                          />
                          <p className={classes.errorMessage}>
                            {touched.titleColor &&
                              errors.titleColor &&
                              errors.titleColor}
                          </p>
                        </div>
                      </div>
                    )}

                    <div className={classes.taskFormContainer}>
                      {values.taskType === TaskTypeEnum.MESSAGE && (
                        <>
                          <div className={classes.halfWidth}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    values.lifecycleType ===
                                    TaskLifecycleTypeEnum.TRIGGERED
                                  }
                                  onChange={() =>
                                    handleLifeCycleTypeChange(
                                      TaskLifecycleTypeEnum.TRIGGERED
                                    )
                                  }
                                  name="TRIGGERED"
                                />
                              }
                              label="TRIGGERED"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    values.lifecycleType ===
                                    TaskLifecycleTypeEnum.SCHEDULED
                                  }
                                  onChange={() =>
                                    handleLifeCycleTypeChange(
                                      TaskLifecycleTypeEnum.SCHEDULED
                                    )
                                  }
                                  name="SCHEDULED"
                                />
                              }
                              label="SCHEDULED"
                            />
                          </div>
                          <p className={classes.errorMessage}>
                            {touched.lifecycleType &&
                              errors.lifecycleType &&
                              errors.lifecycleType}
                          </p>
                        </>
                      )}

                      {values.lifecycleType ===
                        TaskLifecycleTypeEnum.SCHEDULED &&
                        values.taskType === TaskTypeEnum.MESSAGE && (
                          <div className={classes.halfWidth}>
                            <LocalizationProvider dateAdapter={DateAdapter}>
                              <DateTimePicker
                                label="Schedule"
                                value={values.scheduledFor}
                                onChange={newValue =>
                                  setFieldValue(
                                    "scheduledFor",
                                    newValue?.toISOString()
                                  )
                                }
                                renderInput={props => (
                                  <TextField
                                    fullWidth
                                    error={
                                      touched.scheduledFor &&
                                      errors.scheduledFor
                                        ? true
                                        : false
                                    }
                                    onBlur={() =>
                                      setFieldTouched("scheduledFor", true)
                                    }
                                    variant="standard"
                                    {...props}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            <p className={classes.errorMessage}>
                              {touched.scheduledFor &&
                                errors.scheduledFor &&
                                errors.scheduledFor}
                            </p>
                          </div>
                        )}
                      {(areQuizConditionsFulfiled ||
                        values.taskType === TaskTypeEnum.MESSAGE ||
                        areKpiConditionsFulfiled) &&
                        (values.lifecycleType ===
                          TaskLifecycleTypeEnum.TRIGGERED ||
                          values.lifecycleType ===
                            TaskLifecycleTypeEnum.KPI) && (
                          <div
                            className={
                              areQuizConditionsFulfiled ||
                              areKpiConditionsFulfiled
                                ? classes.fullWidth
                                : classes.halfWidth
                            }
                          >
                            <FormControl
                              error={
                                touched.triggeredByTaskId &&
                                errors.triggeredByTaskId
                                  ? true
                                  : false
                              }
                              variant="standard"
                              sx={{ width: "100%" }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Triggered by task
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={values.triggeredByTaskId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label="Triggered by task"
                                name="triggeredByTaskId"
                              >
                                {extendedTaskOptions.map(({ value, name }) => (
                                  <MenuItem value={value} key={value} disabled={taskForm.triggeredByTaskId === value}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <p className={classes.errorMessage}>
                              {touched.triggeredByTaskId &&
                                errors.triggeredByTaskId &&
                                errors.triggeredByTaskId}
                            </p>
                          </div>
                        )}

                      {values.taskType === TaskTypeEnum.LESSON && (
                        <>
                          <div className={classes.fullWidth}>
                            <TextField
                              fullWidth
                              id="standard-number"
                              label="Estimated time for this lesson in seconds"
                              type="number"
                              variant="standard"
                              name="durationInSeconds"
                              error={
                                touched.durationInSeconds &&
                                errors.durationInSeconds
                                  ? true
                                  : false
                              }
                              value={values.durationInSeconds}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className={classes.errorMessage}>
                              {touched.durationInSeconds &&
                                errors.durationInSeconds &&
                                errors.durationInSeconds}
                            </p>
                          </div>
                        </>
                      )}
                    </div>

                    {(values.quizTypeFront ||
                      values.taskType === TaskTypeEnum.MESSAGE) &&
                      values.lifecycleType ===
                        TaskLifecycleTypeEnum.TRIGGERED && (
                        <div className={classes.taskFormContainer}>
                          <div className={classes.halfWidth}>
                            <TextField
                              fullWidth
                              id="standard-number"
                              label="Number of days for task to show after lesson complete"
                              type="number"
                              name="triggerIn"
                              variant="standard"
                              value={values.triggerIn}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className={classes.errorMessage}>
                              {touched.triggerIn &&
                                errors.triggerIn &&
                                errors.triggerIn}
                            </p>
                          </div>
                          <div className={classes.halfWidth}>
                            <TextField
                              fullWidth
                              id="standard-number"
                              label="Number of days for task to expire after it’s triggered"
                              type="number"
                              variant="standard"
                              name="availableFor"
                              value={values.availableFor}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <p className={classes.errorMessage}>
                              {touched.availableFor &&
                                errors.availableFor &&
                                errors.availableFor}
                            </p>
                          </div>
                        </div>
                      )}

                    {(areQuizConditionsFulfiled ||
                      values.taskType === TaskTypeEnum.MESSAGE) && (
                      <div className={classes.taskFormContainer}>
                        <div className={classes.halfWidth}>
                          <TextField
                            fullWidth
                            id="standard-number"
                            label="Number of repeats"
                            type="number"
                            name="repetitionCount"
                            variant="standard"
                            value={values.repetitionCount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <p className={classes.errorMessage}>
                            {touched.repetitionCount &&
                              errors.repetitionCount &&
                              errors.repetitionCount}
                          </p>
                        </div>
                        <div className={classes.halfWidth}>
                          <TextField
                            fullWidth
                            id="standard-number"
                            label="Repeat intervals in days"
                            type="number"
                            variant="standard"
                            name="repetitionInterval"
                            value={values.repetitionInterval}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <p className={classes.errorMessage}>
                            {touched.repetitionInterval &&
                              errors.repetitionInterval &&
                              errors.repetitionInterval}
                          </p>
                        </div>
                      </div>
                    )}

                    {(areQuizConditionsFulfiled ||
                      values.taskType === TaskTypeEnum.MESSAGE) && (
                      <div
                        className={classes.taskFormContainer}
                        style={{ height: "unset" }}
                      >
                        <div className={classes.halfWidth} style={{ order: 2 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.visibleInHistory === true}
                                onChange={() =>
                                  setFieldValue("visibleInHistory", true)
                                }
                                name="SHOW"
                              />
                            }
                            label="SHOW"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.visibleInHistory === false}
                                onChange={() =>
                                  setFieldValue("visibleInHistory", false)
                                }
                                name="HIDE"
                              />
                            }
                            label="HIDE"
                          />
                          <p className={classes.errorMessage}>
                            {touched.visibleInHistory &&
                              errors.visibleInHistory &&
                              errors.visibleInHistory}
                          </p>
                        </div>
                        {values.lifecycleType ===
                          TaskLifecycleTypeEnum.SCHEDULED && (
                          <div
                            className={classes.fullWidth}
                            style={{ order: 1 }}
                          >
                            <LocalizationProvider dateAdapter={DateAdapter}>
                              <DateTimePicker
                                label="Expiration date"
                                value={values.availableTo}
                                onChange={newValue =>
                                  setFieldValue(
                                    "availableTo",
                                    newValue?.toISOString()
                                  )
                                }
                                renderInput={props => (
                                  <TextField
                                    fullWidth
                                    error={
                                      touched.availableTo && errors.availableTo
                                        ? true
                                        : false
                                    }
                                    onBlur={() =>
                                      setFieldTouched("availableTo", true)
                                    }
                                    variant="standard"
                                    {...props}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            <p className={classes.errorMessage}>
                              {touched.availableTo &&
                                errors.availableTo &&
                                errors.availableTo}
                            </p>
                          </div>
                        )}
                      </div>
                    )}

                    {values.taskType === TaskTypeEnum.LESSON && (
                      <div className={classes.taskFormContainer}>
                        <div className={classes.halfWidth}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.visibleInMindfulness}
                                onChange={() =>
                                  setFieldValue(
                                    "visibleInMindfulness",
                                    !values.visibleInMindfulness
                                  )
                                }
                                name="visibleInMindfulness"
                              />
                            }
                            label="VISIBLE IN MINDFULNESS"
                          />
                          <p className={classes.errorMessage}>
                            {touched.visibleInHistory &&
                              errors.visibleInHistory &&
                              errors.visibleInHistory}
                          </p>
                        </div>
                      </div>
                    )}

                    {values.taskType === TaskTypeEnum.MESSAGE && (
                      <div className={classes.taskFormContainer}>
                        <div
                          className={classes.halfWidth}
                          style={{ marginTop: "" }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.dispatchNotification}
                                onChange={() =>
                                  setFieldValue(
                                    "dispatchNotification",
                                    !values.dispatchNotification
                                  )
                                }
                                name="dispatchNotification"
                              />
                            }
                            label="SEND NOTIFICATION"
                          />
                          <p className={classes.errorMessage}>
                            {touched.visibleInHistory &&
                              errors.visibleInHistory &&
                              errors.visibleInHistory}
                          </p>
                        </div>
                      </div>
                    )}

                    {((values.taskType === TaskTypeEnum.QUIZ &&
                      values.quizTypeFront ===
                        TaskQuestionsTypesEnum.CONSULTATION) ||
                      !isEmpty(values.quizCompletionMessages)) && (
                      <FieldArray
                        name="quizCompletionMessages"
                        render={arrayHelpers => {
                          const deleteMessage = async (
                            index: number,
                            message: QuizCompletionMessageUI
                          ): Promise<void> => {
                            removeQuizCompletionMessage &&
                              removeQuizCompletionMessage(
                                index,
                                values,
                                setFieldValue,
                                message.id
                              );
                          };

                          return (
                            <Card className={classes.quizComepletionMessages}>
                              <div
                                className={
                                  classes.quizComepletionMessagesHeader
                                }
                              >
                                <Typography
                                  className={classes.headerTitle}
                                  variant="h6"
                                >
                                  Quiz completion messages
                                </Typography>
                                <p className={classes.errorMessage}>
                                  {touched.quizCompletionMessages &&
                                    !Array.isArray(
                                      errors?.quizCompletionMessages
                                    ) &&
                                    errors?.quizCompletionMessages}
                                </p>
                                <Button
                                  onClick={() =>
                                    arrayHelpers.push(
                                      new QuizCompletionMessageUI()
                                    )
                                  }
                                  variant="contained"
                                >
                                  Add quiz completion message
                                </Button>
                              </div>
                              <div>
                                {values.quizCompletionMessages?.map(
                                  (qM, index) => (
                                    <Card
                                      className={classes.quizCoplemetionMessage}
                                      key={index}
                                    >
                                      <div
                                        className={
                                          classes.quizComepletionMessagesHeader
                                        }
                                      >
                                        <Typography
                                          className={classes.headerTitle}
                                          variant="h6"
                                        >
                                          Message {index + 1}
                                        </Typography>
                                        <Button
                                          onClick={() =>
                                            deleteMessage(index, qM)
                                          }
                                          variant="contained"
                                          color="error"
                                        >
                                          Delete quiz completion message
                                        </Button>
                                      </div>
                                      <div>
                                        <div
                                          className={classes.taskFormContainer}
                                        >
                                          <div className={classes.halfWidth}>
                                            <Field
                                              name={`quizCompletionMessages[${index}].lowerLimit`}
                                            >
                                              {({
                                                field,
                                                meta
                                              }: FieldProps) => (
                                                <>
                                                  <TextField
                                                    fullWidth
                                                    id="standard-number"
                                                    label="Lower limit"
                                                    type="number"
                                                    name={field.name}
                                                    error={
                                                      meta.touched && meta.error
                                                        ? true
                                                        : false
                                                    }
                                                    variant="standard"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                  />
                                                  <p
                                                    className={
                                                      classes.errorMessage
                                                    }
                                                  >
                                                    {meta.touched &&
                                                      meta.error &&
                                                      meta.error}
                                                  </p>
                                                </>
                                              )}
                                            </Field>
                                          </div>
                                          <div className={classes.halfWidth}>
                                            <Field
                                              name={`quizCompletionMessages[${index}].upperLimit`}
                                            >
                                              {({
                                                field,
                                                meta
                                              }: FieldProps) => (
                                                <>
                                                  <TextField
                                                    fullWidth
                                                    id="standard-number"
                                                    label="Upper limit"
                                                    type="number"
                                                    name={field.name}
                                                    error={
                                                      meta.touched && meta.error
                                                        ? true
                                                        : false
                                                    }
                                                    variant="standard"
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                  />
                                                  <p
                                                    className={
                                                      classes.errorMessage
                                                    }
                                                  >
                                                    {meta.touched &&
                                                      meta.error &&
                                                      meta.error}
                                                  </p>
                                                </>
                                              )}
                                            </Field>
                                          </div>
                                        </div>
                                        <div className={classes.fullWidth}>
                                          <Field
                                            name={`quizCompletionMessages[${index}].completionMessage`}
                                          >
                                            {({ field, meta }: FieldProps) => (
                                              <>
                                                <TextField
                                                  fullWidth
                                                  id="standard-number"
                                                  label="Message"
                                                  type="text"
                                                  multiline
                                                  rows={5}
                                                  name={field.name}
                                                  error={
                                                    meta.touched && meta.error
                                                      ? true
                                                      : false
                                                  }
                                                  variant="standard"
                                                  value={field.value}
                                                  onChange={field.onChange}
                                                  onBlur={field.onBlur}
                                                />
                                                <p
                                                  className={
                                                    classes.errorMessage
                                                  }
                                                >
                                                  {meta.touched &&
                                                    meta.error &&
                                                    meta.error}
                                                </p>
                                              </>
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                    </Card>
                                  )
                                )}
                              </div>
                            </Card>
                          );
                        }}
                      />
                    )}

                    {(areQuizConditionsFulfiled ||
                      values.taskType === TaskTypeEnum.MESSAGE ||
                      values.taskType === TaskTypeEnum.LESSON) && (
                      <div className={classes.taskActions}>
                        <div className={classes.fullWidth}>
                          <div className={classes.pageElementsLabel}>
                            <Typography
                              className={classes.headerTitle}
                              variant="h6"
                            >
                              Pages
                            </Typography>
                            {((values.taskType === TaskTypeEnum.MESSAGE &&
                              values.taskPages.length < 1) ||
                              values.taskType === TaskTypeEnum.QUIZ ||
                              values.taskType === TaskTypeEnum.LESSON) && (
                              <Button
                                onClick={() => {
                                  addNewPage && addNewPage(values);
                                }}
                                variant="contained"
                              >
                                Add new page
                              </Button>
                            )}
                          </div>
                          <List
                            component="nav"
                            aria-label="secondary mailbox folder"
                          >
                            {values.taskPages?.map((taskPage, i) => (
                              <ListItemButton key={i}>
                                <ListItemText
                                  primary={`Page ${taskPage.pageNumber}`}
                                />
                                {/* <ListItemText primary={taskPage.type} /> */}
                                {isEditForm &&
                                  taskPage.isFirstPageFront === false &&
                                  taskPage.isLastPageFront === false &&
                                  values.taskType !== TaskTypeEnum.MESSAGE && (
                                    <Button
                                      onClick={() =>
                                        setReorderState({
                                          isOpen: true,
                                          page: taskPage
                                        })
                                      }
                                      variant="contained"
                                      color="success"
                                    >
                                      Reorder
                                    </Button>
                                  )}
                                <Button
                                  onClick={() => {
                                    if (
                                      isEditForm &&
                                      taskPage.id &&
                                      editPageWithId
                                    ) {
                                      editPageWithId(taskPage);
                                    }
                                    if (!isEditForm && editPage) {
                                      editPage(values, taskPage);
                                    }
                                  }}
                                  variant="contained"
                                  color="primary"
                                >
                                  Edit
                                </Button>
                                {!taskPage.isFirstPageFront &&
                                  !taskPage.isLastPageFront && (
                                    <Button
                                      onClick={() => {
                                        removePageFromTask &&
                                          removePageFromTask(
                                            i,
                                            taskPage.id,
                                            values,
                                            setFieldValue
                                          );
                                      }}
                                      variant="contained"
                                      color="error"
                                    >
                                      Delete
                                    </Button>
                                  )}
                              </ListItemButton>
                            ))}
                          </List>
                          <p className={classes.errorMessage}>
                            {touched.taskPages &&
                              errors.taskPages &&
                              errors.taskPages}
                          </p>
                        </div>
                      </div>
                    )}

                    {(areQuizConditionsFulfiled ||
                      values.taskType === TaskTypeEnum.MESSAGE ||
                      values.taskType === TaskTypeEnum.LESSON ||
                      areKpiConditionsFulfiled) && (
                      <div>
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                        >
                          {isEditForm ? "Update" : "Create"}{" "}
                          {areKpiConditionsFulfiled ? "Kpi" : values.taskType}{" "}
                          task
                        </Button>
                      </div>
                    )}
                    <Modal
                      open={reorderState.isOpen}
                      onClose={() =>
                        setReorderState({
                          isOpen: false,
                          page: undefined
                        })
                      }
                    >
                      <Box className={classes.modalBox}>
                        <div className={classes.formModalHeader}>
                          <Typography variant="h5">
                            Page {reorderState.page?.pageNumber}
                          </Typography>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setReorderState({
                                isOpen: false,
                                page: undefined
                              })
                            }
                          >
                            <CloseOutlined />
                          </div>
                        </div>
                        <TextField
                          fullWidth
                          id="standard-number"
                          label="Lesson position"
                          type="number"
                          name="position"
                          variant="standard"
                          value={reorderState.page?.pageNumber}
                          onChange={e =>
                            setReorderState(prevState =>
                              updateObject(prevState, {
                                page: {
                                  ...prevState.page,
                                  pageNumber: e.target.value
                                }
                              })
                            )
                          }
                        />
                        <Button
                          onClick={() => {
                            handleReorderPage(reorderState.page);
                          }}
                          style={{ marginTop: "40px" }}
                          variant="contained"
                        >
                          Save changes
                        </Button>
                      </Box>
                    </Modal>
                  </form>
                );
              }}
            </Formik>
          </Card>
        </>
      )}
    </>
  );
};

export default TaskForm;
