import { FormikHelpers, useFormik } from "formik";
import { toast } from "react-toastify";

//UI Models
import { ShopArticleUI } from "../../../models/app/ui";

// Domain Models
import { ShopArticle } from "../../../models/app/domain";

// components
import ItemForm from "./ItemForm";

// Api Enums
import { ResponseStatusType } from "../../../enums/api";

// Calls
import { createStoreItem } from "../../../api/calls/store/store";

// validations
import { shopFormValidation } from "./ShopForm.validation";

const AddItem: React.FC<{
  addNewArticle: (newArticle: ShopArticle) => void;
  handleClose: () => void;
}> = props => {
  /**
   * Request for creating new article
   * If success adds new article to end of list and close modal
   * If fails should return notification
   * @param values - ShopArticleUI
   */
  // TODO add handle errors
  const createShopItemHandler = async (
    values: ShopArticleUI,
    { setSubmitting }: FormikHelpers<ShopArticleUI>
  ): Promise<void> => {
    setSubmitting(true);

    const response = await createStoreItem(values);
    if (response.statusType !== ResponseStatusType.OK) {
      setSubmitting(false);
      return;
    }

    toast.success("New Artice successfully added to store!");
    props.addNewArticle(response.shopArticle);
  };

  const formik = useFormik({
    initialValues: new ShopArticleUI(),
    onSubmit: createShopItemHandler,
    validate: shopFormValidation
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting
  } = formik;

  return (
    <ItemForm
      formTitle="Add new shop item"
      submitText="Create"
      values={values}
      errors={errors}
      touched={touched}
      setFieldValue={setFieldValue}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      handleClose={props.handleClose}
    />
  );
};

export default AddItem;
