import { useState, useEffect } from "react";
import { toast } from "react-toastify";
// Theme
import { Button, Modal, Box } from "@mui/material";

// styles
import classes from "./StorePage.module.css";

// components
import PageHeader from "../../components/pageHeader/PageHeader";
import ShopItem from "../../components/listItems/shopItem/Shop.item";
import AddItem from "../../containers/forms/shop/AddItem";
import EditItem from "../../containers/forms/shop/EditItem";

// Api Enums
import { ResponseStatusType } from "../../enums/api";

// Calls
import { fetchStoreItems, deleteTask } from "../../api/calls/store/store";

// Utils
import * as utils from "../../utils/global.utils";

// models
import { ShopArticle } from "../../models/app/domain";

type StorePageState = {
  articles: ShopArticle[];
  isOpen: boolean;
  choosenArticle?: ShopArticle;
  articleId?: string;
};

const StorePage = () => {
  const [pageState, setPageState] = useState<StorePageState>({
    articles: [],
    isOpen: false,
    choosenArticle: undefined,
    articleId: undefined
  });

  /**
   * Opens Add Article Modal
   */
  const openAddArticle = (): void =>
    setPageState(prevValues =>
      utils.updateObject(prevValues, { isOpen: true })
    );

  /**
   * Opens Edit Article Modal
   */
  const openEditArticle = (id: string): void =>
    setPageState(prevValues =>
      utils.updateObject(prevValues, { isOpen: true, articleId: id })
    );

  /**
   * Closes modal
   */
  const handleCloseModal = (): void =>
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        isOpen: false,
        choosenArticle: undefined,
        articleId: undefined
      })
    );

  /**
   * Adds new article at the end of list
   * @param newArticle - ShopArticle
   */
  const addNewArticleHandler = (newArticle: ShopArticle): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        articles: [...prevValues.articles, newArticle],
        isOpen: false
      })
    );
  };

  /**
   * Updates chosen article in list of articles
   * @param updatedArticle - ShopArticle
   */
  const updateArticleHandler = (updatedArticle: ShopArticle): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        articles: prevValues.articles.map(article =>
          article.id === updatedArticle.id ? updatedArticle : article
        ),
        isOpen: false,
        articleId: undefined
      })
    );
  };

  /**
   * Removes chosen article from list of articles
   * Requires users confirmation
   * @param articleId - string
   */
  const removeArticle = async (articleId: string): Promise<void> => {
    const result = window.confirm("Are u sure u want to delete this item?");
    if (!result) {
      return;
    }
    const response = await deleteTask(articleId);
    if (response.statusType !== ResponseStatusType.OK) {
      return;
    }

    toast.success(response.message);
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        articles: prevValues.articles.filter(
          article => article.id !== articleId
        )
      })
    );
  };

  /**
   * Fetches articles from server
   * Sets fetched articles in state
   */
  const getShopItems = async (): Promise<void> => {
    const response = await fetchStoreItems();
    if (response.statusType !== ResponseStatusType.OK) {
      return;
    }

    setPageState(prevValues =>
      utils.updateObject(prevValues, { articles: response.shopArticles })
    );
  };

  useEffect(() => {
    getShopItems();
  }, []);

  return (
    <>
      <PageHeader title="Shop">
        <Button variant="contained" onClick={openAddArticle}>
          Add new item
        </Button>
      </PageHeader>
      <div className={classes.storeContainer}>
        {pageState.articles.map((shopArticle, i) => (
          <ShopItem
            key={i}
            item={shopArticle}
            handleEdit={() => openEditArticle(shopArticle.id)}
            handleRemove={() => removeArticle(shopArticle.id)}
          />
        ))}
      </div>
      {
        <Modal open={pageState.isOpen} onClose={handleCloseModal}>
          <Box className={classes.modalBox}>
            {pageState.articleId ? (
              <EditItem
                updateArticle={updateArticleHandler}
                handleClose={handleCloseModal}
                choosenArticleId={pageState.articleId}
              />
            ) : (
              <AddItem
                addNewArticle={addNewArticleHandler}
                handleClose={handleCloseModal}
              />
            )}
          </Box>
        </Modal>
      }
    </>
  );
};

export default StorePage;
