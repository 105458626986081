// DTOS
import { TaskOptionDto } from "../../api/dtos";

/**
 * Api - calls - dropdownOptions - taskOptions maps this model
 * This model is used for dropdown menu in task form.
 */
export class TaskOption {
  value: string;
  name: string;
  taskType: string;

  constructor(entity: TaskOptionDto) {
    this.value = entity.id;
    this.name = entity.title;
    this.taskType = entity.task_type;
  }
}
