import * as yup from "yup";
import { EditorState, convertToRaw } from "draft-js";

// App Enums
import { FitnessPageElementTypeEnum } from "../../../../enums/app";

export const fitnessPageElementValidationSchema = () =>
  yup.lazy(values => {
    if (values.type === FitnessPageElementTypeEnum.TEXT) {
      return yup.object().shape({
        type: yup.string().required("* Required"),
        content: yup
          .object()
          .typeError("* Required")
          .test("has text", "* Required", value => {
            if (!(value instanceof EditorState)) {
              return true;
            }
            const contentValue = convertToRaw(value.getCurrentContent());
            return !(contentValue.blocks[0].text.trim().length === 0);
          })
          .required("* Required")
      });
    }

    if (
      values.type === FitnessPageElementTypeEnum.AUDIO ||
      values.type === FitnessPageElementTypeEnum.VIDEO
    ) {
      return yup.object().shape({
        coverImage: yup.string().typeError("* Required").required("* Required"),
        type: yup.string().typeError("* Required").required("* Required"),
        file: yup.string().typeError("* Required").required("* Required")
      });
    }

    if (values.type === FitnessPageElementTypeEnum.IMAGE) {
      return yup.object().shape({
        type: yup.string().typeError("* Required").required("* Required"),
        file: yup.string().typeError("* Required").required("* Required")
      });
    }

    if (values.type === FitnessPageElementTypeEnum.CONTINUE_BUTTON) {
      return yup.object().shape({
        type: yup.string().required("* Required"),
        color: yup.string().required("* Required"),
        content: yup.string().required("* Required")
      });
    }

    return yup.object().shape({
      type: yup.string().required("* Required")
    });
  });
