import { FitnessPageDto } from "../../api/dtos";

// Domain Models
import { FitnessPageElement } from "./fitness-page-element.model";

export class FitnessPage {
  id: string;
  pageNumber: number;
  pageElements?: FitnessPageElement[];

  constructor(entity: FitnessPageDto) {
    this.id = entity.id;
    this.pageNumber = entity.page_number;
    this.pageElements = entity.page_elements?.map(
      element => new FitnessPageElement(element)
    );
  }
}
