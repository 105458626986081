// DTOS
import { TaskPageElementDTO } from "../dtos";
// DOMAIN Models
import { TaskPageElement } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type TaskPageElementsResponseDataType = {
  task_page_elements: TaskPageElementDTO[];
  statusType: ResponseStatusType;
};

export class TaskPageElementsResponse {
  taskPageElements: TaskPageElement[];
  statusType: ResponseStatusType;

  constructor(responseData: TaskPageElementsResponseDataType) {
    this.taskPageElements = responseData.task_page_elements.map(
      element => new TaskPageElement(element)
    );
    this.statusType = ResponseStatusType.OK;
  }
}
