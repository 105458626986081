// Dtos
import { FitnessPageElementDto } from "../../api/dtos";

// Domain models
import { FileModel } from "./file.model";

// Utils
import { isEmpty } from "../../../utils/global.utils";

export class FitnessPageElement {
  id: string;
  sequenceNumber: number;
  type: string; // enum
  content?: string | null;
  color?: string | null;
  // link?: string | null;
  file: FileModel | null;
  coverImage: FileModel | null;

  constructor(entity: FitnessPageElementDto) {
    this.id = entity.id;
    this.sequenceNumber = entity.sequence_number;
    this.type = entity.type;
    this.content = entity.content;
    this.color = entity.color;
    // this.link = entity.link;
    this.file = !isEmpty(entity.file) ? new FileModel(entity?.file) : null;
    this.coverImage = !isEmpty(entity.file?.cover_image)
      ? new FileModel(entity.file.cover_image)
      : null;
  }
}
