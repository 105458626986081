import { useState, useEffect } from "react";
import { FormikHelpers, useFormik } from "formik";
import { toast } from "react-toastify";

// Theme
import {
  Card,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  TextField,
  Box
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";

// Validations
import { onboardingPageFormValidation } from "./onboarding-page-form.validation";

// Enums
import { OnboardingFormTypeEnum } from "../../../../enums/app";
import { ResponseStatusType } from "../../../../enums/api";

// UI Models
import {
  OnboardingPageElementUI,
  OnboardingPageUI
} from "../../../../models/app/ui";

// Calls
import {
  getOnboardingPage,
  updateOnboarding,
  reorderOnboardingPageElements
} from "../../../../api/calls/onboarding/onboarding";

// Utils
import { updateObject } from "../../../../utils/global.utils";

// Contracts
import { PageFormProps } from "./contracts/onboarding-page-form.types";

// Styles
import classes from "./onboardingPageForm.module.css";

const OnboardingPageForm = ({
  addNewPageElement,
  goBackOneFormHandler,
  appendNewPageToOnboarding,
  editPageElement,
  updatePage,
  removePageElementFromPage,
  formTab,
  page,
  pageId,
  onboardingId
}: PageFormProps) => {
  const [pageForm, setPageForm] = useState<OnboardingPageUI | null>(null);
  const [reorderState, setReorderState] = useState<{
    isOpen: boolean;
    element?: OnboardingPageElementUI;
  }>({
    isOpen: false,
    element: undefined
  });

  /**
   * Fetch page details if chosenTaskPageId is not undefined
   * @param values - TaskPageUI
   */
  const setPageFormState = async (): Promise<void> => {
    // Add call for task page details
    if (formTab === OnboardingFormTypeEnum.EDIT_PAGE_FORM && pageId) {
      const response = await getOnboardingPage(pageId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      setPageForm(new OnboardingPageUI(response.onboardingPage));
      return;
    }

    page && setPageForm(page);
  };

  useEffect(() => {
    setPageFormState();
  }, [page]);
  /**
   * This function creates new page or updates existing one.
   * @param values - OnboardingPageUI
   */
  const pageFormHandler = async (
    values: OnboardingPageUI,
    { setSubmitting }: FormikHelpers<OnboardingPageUI>
  ): Promise<void> => {
    setSubmitting(true);

    if (values && onboardingId) {
      const response = await updateOnboarding(values, onboardingId, values.id);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
      goBackOneFormHandler();
      return;
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: pageForm ? pageForm : new OnboardingPageUI(),
    onSubmit: pageFormHandler,
    validate: onboardingPageFormValidation
  });

  const { values, errors, touched, handleSubmit, setFieldValue, isSubmitting } =
    formik;

  const handleReorderElement = async (
    element?: OnboardingPageElementUI
  ): Promise<void> => {
    if (element?.id) {
      const response = await reorderOnboardingPageElements(
        element?.id,
        element?.sequenceNumber
      );
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      setFieldValue("taskPageElements", response.onboardingPageElements);
      toast.success("Reorder completed");
    }

    setReorderState({
      isOpen: false,
      element: undefined
    });
  };
  return (
    <>
      {pageForm && (
        <Card className={classes.card}>
          <form onSubmit={handleSubmit}>
            <div className={classes.formHeader}>
              <Typography className={classes.headerTitle} variant="h6">
                Page {values.pageNumber} form
              </Typography>
              <Button
                onClick={() => goBackOneFormHandler()}
                variant="contained"
              >
                Back to Task Form
              </Button>
            </div>

            <div className={classes.pageElementsLabel}>
              <Typography className={classes.headerTitle} variant="h6">
                Page {values.pageNumber} elements
              </Typography>
              <Button
                onClick={() => {
                  addNewPageElement(formTab, values);
                }}
                variant="contained"
              >
                Add new page element
              </Button>
            </div>
            <List component="nav" aria-label="secondary mailbox folder">
              {values.taskPageElements?.map((element, i) => (
                <ListItemButton key={i}>
                  <ListItemText primary={`Element ${element.sequenceNumber}`} />
                  <ListItemText primary={`${element.type}`} />
                  {formTab === OnboardingFormTypeEnum.EDIT_PAGE_FORM && (
                    <Button
                      onClick={() =>
                        setReorderState({
                          isOpen: true,
                          element: element
                        })
                      }
                      variant="contained"
                      color="success"
                    >
                      Reorder
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      editPageElement(values, element);
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() =>
                      removePageElementFromPage(
                        i,
                        element.id,
                        values,
                        setFieldValue
                      )
                    }
                    variant="contained"
                    color="error"
                  >
                    Delete
                  </Button>
                </ListItemButton>
              ))}
            </List>
            <p className={classes.errorMessage}>
              {touched.taskPageElements &&
                Array.isArray(touched.taskPageElements) &&
                touched.taskPageElements.length < 1 &&
                errors.taskPageElements &&
                errors.taskPageElements}
            </p>
            {formTab === OnboardingFormTypeEnum.PAGE_FORM && (
              <div className={classes.actions}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                >
                  {formTab === OnboardingFormTypeEnum.PAGE_FORM
                    ? "Add page"
                    : "Update page"}
                </Button>
              </div>
            )}
            <Modal
              open={reorderState.isOpen}
              onClose={() =>
                setReorderState({
                  isOpen: false,
                  element: undefined
                })
              }
            >
              <Box className={classes.modalBox}>
                <div className={classes.formModalHeader}>
                  <Typography variant="h5">
                    Page {reorderState.element?.sequenceNumber}
                  </Typography>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setReorderState({
                        isOpen: false,
                        element: undefined
                      })
                    }
                  >
                    <CloseOutlined />
                  </div>
                </div>
                <TextField
                  fullWidth
                  id="standard-number"
                  label="Lesson position"
                  type="number"
                  name="position"
                  variant="standard"
                  value={reorderState.element?.sequenceNumber}
                  onChange={e =>
                    setReorderState(prevState =>
                      updateObject(prevState, {
                        element: {
                          ...prevState.element,
                          sequenceNumber: e.target.value
                        }
                      })
                    )
                  }
                />
                <Button
                  onClick={() => {
                    handleReorderElement(reorderState.element);
                  }}
                  style={{ marginTop: "40px" }}
                  variant="contained"
                >
                  Save changes
                </Button>
              </Box>
            </Modal>
          </form>
        </Card>
      )}
    </>
  );
};

export default OnboardingPageForm;
