// DTOS
import { FitnessPageDto } from "../dtos";
// DOMAIN Models
import { FitnessPage } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type FitnessPagesResponseDataType = {
  fitness_pages: FitnessPageDto[];
  statusType: ResponseStatusType;
};

export class FitnessPagesResponse {
  fitnessPages: FitnessPage[];
  statusType: ResponseStatusType;

  constructor(responseData: FitnessPagesResponseDataType) {
    this.fitnessPages = responseData.fitness_pages.map(
      page => new FitnessPage(page)
    );
    this.statusType = ResponseStatusType.OK;
  }
}
