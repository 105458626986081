// App Enums
import { FitnessPageElementTypeEnum } from "../../enums/app";

// Page Elements Type Field options
type pageElementsTypeOptionsType = {
  value: FitnessPageElementTypeEnum;
  label: string;
};

export const pageElementsTypeOptions: pageElementsTypeOptionsType[] = [
  {
    value: FitnessPageElementTypeEnum.TEXT,
    label: "Text"
  },
  {
    value: FitnessPageElementTypeEnum.IMAGE,
    label: "Image"
  },
  {
    value: FitnessPageElementTypeEnum.VIDEO,
    label: "Video"
  },
  {
    value: FitnessPageElementTypeEnum.AUDIO,
    label: "Audio"
  },
  {
    value: FitnessPageElementTypeEnum.CONTINUE_BUTTON,
    label: "Continue button"
  }
];
