// enums
import {
  TaskLifecycleTypeEnum,
  TaskTypeEnum,
  KpiTypeEnum
} from "../../../enums/app";

// UI Models
import { TaskUI } from "../../app/ui";

// REQUEST Models
import { TaskPageRequest } from "./task-page.request";
import { QuizCompletionMessage } from "./quiz-completion-message.request";

// Utils
import { isEmpty, valueOrNull } from "../../../utils/global.utils";
export class UpdateTaskRequest {
  id: string;
  title: string | null;
  task_type: TaskTypeEnum | string;
  task_lifecycle_type: TaskLifecycleTypeEnum | null;
  kpi_type?: KpiTypeEnum | null;
  visible_in_history: boolean;
  visible_in_mindfulness: boolean;
  dispatch_notification: boolean;
  triggered_by_task_id: string | null;
  sequence_number: number | null;
  duration_in_seconds: number;
  title_color: string;
  trigger_in: number;
  available_for: number | null;
  repetition_count: number | null;
  repetition_interval: number | null;
  scheduled_for: Date | null;
  available_to: Date | null;
  task_pages: TaskPageRequest[];
  quiz_completion_messages?: QuizCompletionMessage[] | null;

  constructor(taskUI: TaskUI, taskId: string) {
    this.id = taskId;
    this.title = taskUI.title;
    this.task_type = taskUI.taskType;
    this.task_lifecycle_type = taskUI.lifecycleType;
    this.kpi_type = valueOrNull(taskUI.kpiType);
    this.visible_in_history = taskUI.visibleInHistory;
    this.visible_in_mindfulness = taskUI.visibleInMindfulness;
    this.dispatch_notification = taskUI.dispatchNotification;
    this.triggered_by_task_id = valueOrNull(taskUI.triggeredByTaskId);
    this.sequence_number =
      taskUI.lifecycleType === TaskLifecycleTypeEnum.KPI
        ? null
        : taskUI.sequenceNumber;
    this.duration_in_seconds = taskUI.durationInSeconds;
    this.title_color = taskUI.titleColor;
    this.trigger_in = taskUI.triggerIn;
    this.available_for =
      taskUI.lifecycleType === TaskLifecycleTypeEnum.KPI
        ? null
        : taskUI.availableFor === 0
        ? null
        : taskUI.availableFor;
    this.repetition_count =
      taskUI.lifecycleType === TaskLifecycleTypeEnum.KPI
        ? null
        : taskUI.repetitionCount === 0
        ? null
        : taskUI.repetitionCount;
    this.repetition_interval =
      taskUI.lifecycleType === TaskLifecycleTypeEnum.KPI
        ? null
        : taskUI.repetitionInterval === 0
        ? null
        : taskUI.repetitionInterval;
    this.scheduled_for =
      taskUI.lifecycleType === TaskLifecycleTypeEnum.KPI
        ? null
        : valueOrNull(taskUI.scheduledFor);
    this.available_to =
      taskUI.lifecycleType === TaskLifecycleTypeEnum.KPI
        ? null
        : valueOrNull(taskUI.availableTo);
    this.task_pages = taskUI.taskPages.map(page => new TaskPageRequest(page));
    this.quiz_completion_messages = !isEmpty(taskUI.quizCompletionMessages)
      ? taskUI.quizCompletionMessages?.map(
          message => new QuizCompletionMessage(message)
        )
      : null;
  }
}
