//UI Models
import { TaskPageUI } from "../../../../models/app/ui";

// Utils
import { isEmpty } from "../../../../utils/global.utils";

export const taskPageFormValidation = ({
  pageNumber,
  taskPageElements
}: TaskPageUI): any => {
  const errors: any = {};

  if (!pageNumber) {
    errors.pageNumber = "* Required";
  }

  if (isEmpty(taskPageElements)) {
    errors.taskPageElements = "* Page must have at least one page element";
  }

  return errors;
};
