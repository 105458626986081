import { LoginUI } from "../../app/ui";

export class LoginRequest {
  email: string;
  password: string;

  constructor(loginUI: LoginUI) {
    this.email = loginUI.email;
    this.password = loginUI.password;
  }
}
