import { EditorState } from "draft-js";
// Enums
import { TaskPageElementTypeEnum } from "../../../enums/app";

// UI Models
import { OnboardingQuestionUI } from "./onboarding-question.ui";

// Domain Models
import { OnboardingPageElement } from "../domain";

// Utils
import { returnUrlFromFile } from "../../../utils/file.utils";

type OnboardingPageElementUIType = {
  id?: string;
  sequenceNumber?: number;
  type?: TaskPageElementTypeEnum | string;
  content?: EditorState | string | null;
  color?: string;
  // link?: string | null;
  file?: string | null;
  coverImage?: string | null;
  question?: OnboardingQuestionUI | null;
};

export class OnboardingPageElementUI {
  id?: string;
  sequenceNumber: number;
  type: TaskPageElementTypeEnum | string;
  content: EditorState | string | null;
  color: string;
  // link: string | null;
  file: string | null;
  coverImage?: string | null;
  question: OnboardingQuestionUI | null;

  constructor(data?: OnboardingPageElementUIType | OnboardingPageElement) {
    this.id = data?.id;
    this.sequenceNumber = data?.sequenceNumber || 0;
    this.type = data?.type || "";
    this.content = data?.content || "";
    this.color = data?.color || "";
    // this.link = data?.link || "";
    this.file = returnUrlFromFile(data?.file);
    this.coverImage = returnUrlFromFile(data?.coverImage);
    this.question = data?.question
      ? new OnboardingQuestionUI(data.question)
      : null;
  }
}
