// DTOS
import { OnboardingPageDto } from "../../api/dtos";

// DOMAIN Models
import { OnboardingPageElement } from "./onboarding-page-element.model";

// Utils
import { isEmpty } from "../../../utils/global.utils";

export class OnboardingPage {
  id: string;
  pageNumber: number;
  taskPageElements?: OnboardingPageElement[] | null;

  constructor(entity: OnboardingPageDto) {
    this.id = entity.id;
    this.pageNumber = entity.page_number;
    this.taskPageElements = !isEmpty(entity.page_elements)
      ? entity.page_elements?.map(element => new OnboardingPageElement(element))
      : null;
  }
}
