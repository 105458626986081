import { Card, CardContent, Typography, IconButton } from "@mui/material";
import { Edit, Delete, Preview } from "@mui/icons-material";

// models
import { ShopArticle } from "../../../models/app/domain";

// Utils
import { truncate } from "../../../utils/global.utils";

// styles
import classes from "./ShopItem.module.css";

const ShopItem: React.FC<{
  item: ShopArticle;
  handleEdit: () => void;
  handleRemove: () => void;
}> = props => {
  return (
    <Card className={classes.aricleCard}>
      <CardContent className={classes.articleContent}>
        <div className={classes.articleImageContainer}>
          <img
            className={classes.articleImage}
            src={props?.item?.image?.url}
            alt="articleImage"
          />
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Typography className={classes.articleName}>
              {truncate(props.item.name, 75)}
            </Typography>
          </div>
          <div className={classes.actions}>
            <Typography className={classes.articlePrice}>
              {props.item.price}
            </Typography>
            <div className={classes.buttonsContainer}>
              <IconButton
                className={classes.iconButton}
                href={props.item.link}
                target="_blank"
                size="small"
                color="success"
              >
                <Preview />
              </IconButton>
              <IconButton
                className={classes.iconButton}
                size="small"
                color="primary"
                onClick={props.handleEdit}
              >
                <Edit />
              </IconButton>
              <IconButton
                className={classes.iconButton}
                size="small"
                color="error"
                onClick={props.handleRemove}
              >
                <Delete />
              </IconButton>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ShopItem;
