import { ApiResponse } from "apisauce";

// Api
import { api } from "../../api";
import { getGeneralApiProblem } from "../../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem
} from "../../general-api-problems/general-api-problems.types";

// Response
import {
  TaskOptionsResponse,
  TaskOptionsResponseDataType
} from "../../../models/api/responses/task-options.response";

// App Enums
import { TaskTypeEnum } from "../../../enums/app";

/**
 * Get tasks based on type request
 * @param type - TaskTypeEnum
 * @returns TaskOptionsResponse or GeneralApiProblem
 */
export const getTaskOptions = async (
  type: TaskTypeEnum,
  availableLessons?: string
): Promise<TaskOptionsResponse | GeneralApiProblem> => {
  let path = `/task/search?task_type=${type}`;
  if (availableLessons) {
    path = `/task/search?task_type=${type}&triggers_task=null`;
  }
  const response: ApiResponse<TaskOptionsResponseDataType, ExceptionPayload> =
    await api.apisauce.get(path);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as TaskOptionsResponseDataType;
  return new TaskOptionsResponse(responseData);
};
