import * as yup from "yup";
import { EditorState, convertToRaw } from "draft-js";

// Enums
import {
  OnboardingPageElementTypeEnum,
  OnboardingQuestionsTypesEnum
} from "../../../../enums/app";

export const onboardingPageElementsValidationSchema = () =>
  yup.lazy(values => {
    if (
      values.type === OnboardingPageElementTypeEnum.AUDIO ||
      values.type === OnboardingPageElementTypeEnum.VIDEO
    ) {
      return yup.object().shape({
        coverImage: yup.string().typeError("* Required").required("* Required"),
        type: yup.string().typeError("* Required").required("* Required"),
        file: yup.string().typeError("* Required").required("* Required")
      });
    }

    if (values.type === OnboardingPageElementTypeEnum.IMAGE) {
      return yup.object().shape({
        type: yup.string().typeError("* Required").required("* Required"),
        file: yup.string().typeError("* Required").required("* Required")
      });
    }

    if (values.type === OnboardingPageElementTypeEnum.TEXT) {
      return yup.object().shape({
        content: yup
          .object()
          .typeError("* Required")
          .test("has text", "* Required", value => {
            if (!(value instanceof EditorState)) {
              return true;
            }
            const contentValue = convertToRaw(value.getCurrentContent());
            return !(contentValue.blocks[0].text.trim().length === 0);
          })
          .required("* Required")
      });
    }

    if (values.type === OnboardingPageElementTypeEnum.COMPLETE_BUTTON) {
      return yup.object().shape({
        type: yup.string().required("* Required"),
        color: yup.string().required("* Required"),
        content: yup.string().required("* Required")
      });
    }

    if (values.type === OnboardingPageElementTypeEnum.CONTINUE_BUTTON) {
      return yup.object().shape({
        type: yup.string().required("* Required"),
        color: yup.string().required("* Required"),
        content: yup.string().required("* Required")
      });
    }

    if (values.type === OnboardingPageElementTypeEnum.QUESTION) {
      if (
        values.question &&
        values.question.type === OnboardingQuestionsTypesEnum.JOURNAL
      ) {
        return yup.object().shape({
          type: yup.string().required("* Required"),
          question: yup.object().shape({
            type: yup.string().required("* Required"),
            answerType: yup.string().required("* Required"),
            title: yup
              .object()
              .typeError("* Required")
              .test("has text", "* Required", value => {
                if (!(value instanceof EditorState)) {
                  return true;
                }
                const contentValue = convertToRaw(value.getCurrentContent());
                return !(contentValue.blocks[0].text.trim().length === 0);
              })
              .required("* Required")
          })
        });
      }

      if (
        values.question &&
        values.question.type === OnboardingQuestionsTypesEnum.CONDITIONAL
      ) {
        return yup.object().shape({
          type: yup.string().required("* Required"),
          question: yup.object().shape({
            type: yup.string().required("* Required"),
            answerType: yup.string().required("* Required"),
            title: yup
              .object()
              .typeError("* Required")
              .test("has text", "* Required", value => {
                if (!(value instanceof EditorState)) {
                  return true;
                }
                const contentValue = convertToRaw(value.getCurrentContent());
                return !(contentValue.blocks[0].text.trim().length === 0);
              })
              .required("* Required"),
            answers: yup
              .array()
              .typeError("* Required")
              .of(
                yup.object().shape({
                  answer: yup.string().required("* Required")
                })
              )
              .required("* Required")
              .min(2, "Question must contain 2 answers")
          })
        });
      }

      return yup.object().shape({
        type: yup.string().required("* Required"),
        question: yup.object().shape({
          type: yup.string().required("* Required"),
          answerType: yup.string().required("* Required")
        })
      });
    }

    return yup.object().shape({
      type: yup.string().required("* Required")
    });
  });
