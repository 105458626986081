// Dtos
import { FileDto } from "../../api/dtos";

// Utils
import { isEmpty } from "../../../utils/global.utils";

export class FileModel {
  coverImage: FileModel | null;
  id: string;
  mimeType: string;
  type: string;
  url: string;

  constructor(entity: FileDto) {
    this.coverImage = !isEmpty(entity.cover_image)
      ? new FileModel(entity.cover_image)
      : null;
    this.id = entity?.id;
    this.mimeType = entity?.mime_type;
    this.type = entity?.type;
    this.url = entity.url;
  }
}
