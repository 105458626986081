import { useState, useEffect } from "react";
import { useParams } from "react-router";

// Components
import PageHeader from "../../components/pageHeader/PageHeader";

// Containers
import TaskForm from "../../containers/forms/task/taskForm/task.form";
import PageForm from "../../containers/forms/task/pageForm/page.form";
import PageElementForm from "../../containers/forms/task/pageElementForm/pageElement.form";

// Enums
import {
  TaskFormTypeEnum,
  TaskTypeEnum,
  TaskLifecycleTypeEnum,
  TaskPageElementTypeEnum
} from "../../enums/app";
import { ResponseStatusType } from "../../enums/api";

// Ui Models
import {
  TaskUI,
  TaskPageUI,
  TaskPageElementUI,
  TaskQuestionUI,
  TaskQuestionAnswerUI,
  QuizCompletionMessageUI
} from "../../models/app/ui";

// Domain Models
import { TaskOption } from "../../models/app/domain";

// Calls
import { getTaskOptions } from "../../api/calls/dropdown-options/task-options";

// Utils
import * as utils from "../../utils/global.utils";

// Contracts
import { StateStructureType, RouteParams } from "./contracts/create-task.type";

const CreateTask = () => {
  const params = useParams<RouteParams>();
  /**
   * Sets inital state for Task based on taskType from params
   * @param type - TaskLifecycleTypeEnum
   * @return - TaskUI
   */
  const setTaskInitStateBasedOnTaskType = (): TaskUI => {
    if (params.taskType === TaskTypeEnum.QUIZ) {
      return new TaskUI({
        taskType: params.taskType,
        lifecycleType: TaskLifecycleTypeEnum.TRIGGERED
      });
    }
    if (params.taskType === TaskTypeEnum.LESSON) {
      return new TaskUI({
        taskType: params.taskType,
        lifecycleType: TaskLifecycleTypeEnum.PERSISTENT
      });
    }
    if (params.taskType === TaskTypeEnum.KPI) {
      return new TaskUI({ lifecycleType: TaskLifecycleTypeEnum.KPI });
    }
    return new TaskUI({ taskType: params.taskType });
  };

  const isQuiz = params.taskType === TaskTypeEnum.QUIZ;

  const [taskOptions, setTaskOptions] = useState<TaskOption[]>([]);
  const [pageState, setPageState] = useState<StateStructureType>({
    previousFormTab: TaskFormTypeEnum.TASK_FORM,
    formTab: TaskFormTypeEnum.TASK_FORM,
    task: setTaskInitStateBasedOnTaskType(),
    chosenPage: undefined,
    chosenPageElement: undefined
  });

  /**
   * Sets formTab and previousFormTab
   */
  const goBackOneFormHandler = (): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        formTab: prevValues.previousFormTab,
        previousFormTab: TaskFormTypeEnum.TASK_FORM
      })
    );
  };

  /**
   * Fetches Lessons and sets response in taskOptions
   */
  const fetchTaskOptions = async (): Promise<void> => {
    const response = await getTaskOptions(
      TaskTypeEnum.LESSON,
      "availableLessons"
    );
    if (response.statusType === ResponseStatusType.OK) {
      setTaskOptions(response.options);
    }
  };

  useEffect(() => {
    fetchTaskOptions();
  }, []);

  // TASK ACTIONS

  /**
   * Saves values from task form in task state
   * Sets chosenPage as new TaskPageUI with predefined page number
   * @param taskValues - TaskUI
   */
  const addNewPage = (taskValues: TaskUI): void => {
    let lastTaskPageNumber = taskValues.taskPages.length || 0;
    if (isQuiz) {
      lastTaskPageNumber = taskValues.taskPages.filter(
        page => !page.isLastPageFront
      ).length;
    }

    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        task: utils.updateInstanceObject(TaskUI, prevValues.task, {
          ...taskValues
        }),
        previousFormTab: TaskFormTypeEnum.TASK_FORM,
        formTab: TaskFormTypeEnum.PAGE_FORM,
        chosenPage: new TaskPageUI({ pageNumber: lastTaskPageNumber + 1 })
      })
    );
  };

  /**
   * Saves values from task form in task state
   * Sets chosenPage from list of pages
   * @param taskValues - TaskUI
   * @param page - TaskPageUI
   */
  const editPage = (taskValues: TaskUI, page: TaskPageUI): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        task: utils.updateInstanceObject(TaskUI, prevValues.task, {
          ...taskValues
        }),
        chosenPage: page,
        previousFormTab: TaskFormTypeEnum.TASK_FORM,
        formTab: TaskFormTypeEnum.EDIT_PAGE_FORM
      })
    );
  };

  /**
   * Removes quizCompletionMessages from list
   * Requires users confirmation
   * @param messageIndex - number
   * @param values - TaskUI
   * @param setFieldValue - formik onChange helper
   * @param messageId - udnefined | string
   */
  const removeQuizCompletionMessage = (
    messageIndex: number,
    values: TaskUI,
    setFieldValue: (fieldName: string, value: any) => void,
    messageId?: string
  ): void => {
    const result = window.confirm(
      "Are u sure u want to delete this quiz completion message?"
    );
    if (!result) {
      return;
    }

    values &&
      values.quizCompletionMessages &&
      setFieldValue(
        "quizCompletionMessages",
        values.quizCompletionMessages.filter((message, i) => i !== messageIndex)
      );
  };

  /**
   * Removes chosen page from list of pages
   * Requires users confirmation
   * @param pageIndex - number
   */
  const removePageFromTask = (pageIndex: number): void => {
    const result = window.confirm("Are u sure u want to delete this page?");

    if (!result) {
      return;
    }
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        task: utils.updateInstanceObject(TaskUI, prevValues.task, {
          taskPages: prevValues.task.taskPages
            .filter((page, i) => i !== pageIndex)
            .map((page, i) =>
              utils.updateInstanceObject(TaskPageUI, page, {
                pageNumber: i + 1
              })
            )
        })
      })
    );
  };

  // PAGE ACTIONS

  /**
   * Appends new page to task at the end of list and sets page number to be in order
   * If is quiz add page next to last and sets page number to be in order
   * @param page - TaskPageUI
   */
  const appendNewPageToTask = (page: TaskPageUI): void => {
    let firstPage: TaskPageUI;
    let lastPage: TaskPageUI;
    let middlePages: TaskPageUI[];
    if (isQuiz) {
      firstPage = pageState.task.taskPages[0];
      lastPage = pageState.task.taskPages[pageState.task.taskPages.length - 1];
      middlePages = pageState.task.taskPages.filter(
        page => !page.isFirstPageFront && !page.isLastPageFront
      );
    }

    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage: null,
        previousFormTab: TaskFormTypeEnum.TASK_FORM,
        formTab: TaskFormTypeEnum.TASK_FORM,
        task: utils.updateInstanceObject(TaskUI, prevValues.task, {
          taskPages: !utils.isEmpty(prevValues.task.taskPages)
            ? isQuiz
              ? [
                  firstPage,
                  ...middlePages,
                  page,
                  utils.updateInstanceObject(TaskPageUI, lastPage, {
                    pageNumber: page.pageNumber + 1
                  })
                ]
              : [...prevValues.task.taskPages, page]
            : [page]
        })
      })
    );
  };

  /**
   * Update task page with page
   * @param page - TaskPageUI
   */
  const updatePage = (page: TaskPageUI): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        task: utils.updateInstanceObject(TaskUI, prevValues.task, {
          taskPages: prevValues.task.taskPages.map(taskPage =>
            taskPage.pageNumber === page.pageNumber ? page : taskPage
          )
        }),
        chosenPage: null,
        previousFormTab: TaskFormTypeEnum.TASK_FORM,
        formTab: TaskFormTypeEnum.TASK_FORM
      })
    );
  };

  /**
   * Adds new page element to chosenPageElement
   * Saves current page form values
   * @param currentFormTab - TaskFormTypeEnum
   * @param pageFormValues - TaskPageUI
   */
  const addNewPageElement = (
    currentFormTab: TaskFormTypeEnum,
    pageFormValues: TaskPageUI
  ): void => {
    const lastPageElementNumber: number =
      pageState.chosenPage?.taskPageElements?.length || 0;

    if (isQuiz) {
      return setPageState(prevValues =>
        utils.updateObject(prevValues, {
          chosenPage: utils.updateInstanceObject(TaskPageUI, prevValues, {
            ...pageFormValues
          }),
          chosenPageElement: new TaskPageElementUI({
            sequenceNumber: lastPageElementNumber + 1,
            type:
              pageFormValues.isFirstPageFront || pageFormValues.isLastPageFront
                ? ""
                : TaskPageElementTypeEnum.QUESTION,
            question:
              pageFormValues.isFirstPageFront || pageFormValues.isLastPageFront
                ? null
                : new TaskQuestionUI({
                    type: pageState.task.quizTypeFront
                  })
          }),
          previousFormTab: currentFormTab,
          formTab: TaskFormTypeEnum.PAGE_ELEMENT_FORM
        })
      );
    }
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage: utils.updateInstanceObject(TaskPageUI, prevValues, {
          ...pageFormValues
        }),
        chosenPageElement: new TaskPageElementUI({
          sequenceNumber: lastPageElementNumber + 1
        }),
        previousFormTab: currentFormTab,
        formTab: TaskFormTypeEnum.PAGE_ELEMENT_FORM
      })
    );
  };

  /**
   * Adds page element from list of elements in page to chosenPageElement
   * Saves current page form values
   * @param currentFormTab - TaskFormTypeEnum
   * @param pageFormValues - TaskPageUI
   */
  const editPageElement = (
    pageFormValues: TaskPageUI,
    pageElelement: TaskPageElementUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage: utils.updateInstanceObject(TaskPageUI, prevValues, {
          ...pageFormValues
        }),
        previousFormTab: prevValues.formTab,
        formTab: TaskFormTypeEnum.EDIT_PAGE_ELEMENT_FORM,
        chosenPageElement: pageElelement
      })
    );
  };

  /**
   * Removes chosen page element from list of elements in chosen page
   * Requires users confirmation
   * @param pageIndex - number
   */
  const removePageElementFromPage = (pageElementIndex: number): void => {
    const result = window.confirm("Are u sure u want to delete this element?");
    if (!result) {
      return;
    }

    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage:
          prevValues.chosenPage &&
          utils.updateInstanceObject(TaskPageUI, prevValues.chosenPage, {
            taskPageElements: prevValues.chosenPage.taskPageElements
              ?.filter((element, i) => i !== pageElementIndex)
              .map((element, i) =>
                utils.updateInstanceObject(TaskPageElementUI, element, {
                  sequenceNumber: i + 1
                })
              )
          })
      })
    );
  };

  // PAGE ELEMENT ACTIONS

  /**
   * Append new page element to chosen page
   * Redirect to page form with new page element
   * @param pageElement - TaskPageElementUI
   */
  const appendNewPageElementToChosenPage = (
    pageElement: TaskPageElementUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage:
          prevValues.chosenPage &&
          utils.updateInstanceObject(TaskPageUI, prevValues.chosenPage, {
            taskPageElements: prevValues.chosenPage?.taskPageElements
              ? [...prevValues.chosenPage?.taskPageElements, pageElement]
              : [pageElement]
          }),
        chosenPageElement: null,
        previousFormTab: TaskFormTypeEnum.TASK_FORM,
        formTab: prevValues.previousFormTab
      })
    );
  };

  /**
   * Replaces pageElement with chosenPageElement based on sequenceNumber
   * Redirect to page form with updated page element
   * @param pageElement - TaskPageElementUI
   */
  const updatePageElementToChosenPage = (
    pageElement: TaskPageElementUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage:
          prevValues.chosenPage &&
          utils.updateInstanceObject(TaskPageUI, prevValues.chosenPage, {
            taskPageElements:
              prevValues.chosenPage.taskPageElements &&
              prevValues.chosenPage.taskPageElements.map(element =>
                element.sequenceNumber === pageElement.sequenceNumber
                  ? pageElement
                  : element
              )
          }),
        chosenPageElement: null,
        previousFormTab: TaskFormTypeEnum.TASK_FORM,
        formTab: prevValues.previousFormTab
      })
    );
  };

  /**
   * Removes answer
   * Requires users confirmation
   * @param pageIndex - number
   */
  const removeAnswerFromQuestion = (
    answerIndex: number,
    values: TaskPageElementUI,
    setFieldValue: (fieldName: string, value: any) => void,
    answerId?: string
  ): void => {
    const result = window.confirm("Are u sure u want to delete this answer?");
    if (!result) {
      return;
    }

    values &&
      values.question &&
      values.question.answers &&
      setFieldValue(
        "question.answers",
        values.question.answers
          .filter((answer, i) => i !== answerIndex)
          .map((answer, i) =>
            utils.updateInstanceObject(TaskQuestionAnswerUI, answer, {
              sequenceNumber: i + 1
            })
          )
      );
  };

  return (
    <>
      <PageHeader title={`Create ${params.taskType}`} />
      {pageState.formTab === TaskFormTypeEnum.TASK_FORM && (
        <TaskForm
          isKpiForm={params.taskType === TaskTypeEnum.KPI}
          taskOptions={taskOptions}
          addNewPage={addNewPage}
          editPage={editPage}
          newTask={pageState.task}
          removePageFromTask={removePageFromTask}
          removeQuizCompletionMessage={removeQuizCompletionMessage}
        />
      )}
      {(pageState.formTab === TaskFormTypeEnum.PAGE_FORM ||
        pageState.formTab === TaskFormTypeEnum.EDIT_PAGE_FORM) &&
        pageState.chosenPage && (
          <PageForm
            quizType={pageState.task.quizTypeFront}
            isQuiz={isQuiz}
            taskType={params.taskType}
            formTab={pageState.formTab}
            newPage={pageState.chosenPage}
            addNewPageElement={addNewPageElement}
            goBackOneFormHandler={goBackOneFormHandler}
            appendNewPageToTask={appendNewPageToTask}
            removePageElementFromPage={removePageElementFromPage}
            editPageElement={editPageElement}
            updatePage={updatePage}
          />
        )}
      {(pageState.formTab === TaskFormTypeEnum.PAGE_ELEMENT_FORM ||
        pageState.formTab === TaskFormTypeEnum.EDIT_PAGE_ELEMENT_FORM) &&
        pageState.chosenPageElement && (
          <PageElementForm
            isQuiz={isQuiz}
            taskType={params.taskType}
            formTab={pageState.formTab}
            chosenPage={pageState.chosenPage && pageState.chosenPage}
            newChosenPageElement={pageState.chosenPageElement}
            goBackOneFormHandler={goBackOneFormHandler}
            appendNewPageElementToChosenPage={appendNewPageElementToChosenPage}
            updatePageElementToChosenPage={updatePageElementToChosenPage}
            removeAnswerFromQuestion={removeAnswerFromQuestion}
          />
        )}
    </>
  );
};

export default CreateTask;
