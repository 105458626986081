// enums
import {
  TaskLifecycleTypeEnum,
  TaskQuestionsTypesEnum,
  TaskTypeEnum,
  KpiTypeEnum
} from "../../../enums/app";

// DTOS
import { TaskDTO } from "../../api/dtos";

// DOMAIN Models
import { TaskPage } from "./taskPage.model";
import { QuizCompletionMessage } from "./quiz-completion-message.model";

// Utils
import { isEmpty } from "../../../utils/global.utils";

export class Task {
  quizTypeFront?: TaskQuestionsTypesEnum | string;
  id: string;
  title: string;
  taskType: TaskTypeEnum;
  kpiType?: KpiTypeEnum | null;
  sequenceNumber: number | null;
  lifecycleType: TaskLifecycleTypeEnum | null;
  visibleInHistory: boolean;
  dispatchNotification: boolean;
  visibleInMindfulness: boolean;
  triggeredByTaskId: string | null;
  durationInSeconds: number;
  titleColor: string;
  triggerIn: number;
  availableFor: number;
  repetitionCount: number;
  repetitionInterval: number;
  scheduledFor: Date | null;
  availableTo: Date | null;
  taskPages: TaskPage[];
  quizCompletionMessages?: QuizCompletionMessage[] | null;
  lessonLikes?: number;
  lessonDislikes?: number;

  constructor(entity: TaskDTO) {
    this.id = entity.id;
    this.title = entity.title;
    this.taskType = entity.task_type;
    this.kpiType = entity.kpi_type;
    this.quizTypeFront = entity.task_type || "";
    this.dispatchNotification = entity.dispatch_notification;
    this.visibleInMindfulness = entity.visible_in_mindfulness;
    this.triggeredByTaskId = entity.triggered_by_task_id;
    this.sequenceNumber = entity.sequence_number;
    this.lifecycleType = entity.lifecycle_type;
    this.visibleInHistory = entity.visible_in_history;
    this.durationInSeconds = entity.duration_in_seconds;
    this.titleColor = entity.title_color;
    this.triggerIn = entity.trigger_in;
    this.availableFor = entity.available_for;
    this.repetitionCount = entity.repetition_count;
    this.repetitionInterval = entity.repetition_interval;
    this.scheduledFor = entity.scheduled_for;
    this.availableTo = entity.available_to;
    this.taskPages = !isEmpty(entity.pages)
      ? entity.pages.map(
          page => new TaskPage(page, entity.task_type, entity.pages.length)
        )
      : [];
    this.quizCompletionMessages = !isEmpty(entity.quiz_completion_messages)
      ? entity?.quiz_completion_messages?.map(
          message => new QuizCompletionMessage(message)
        )
      : null;
    this.lessonLikes = entity.total_likes || 0;
    this.lessonDislikes = entity.total_dislikes || 0;
  }
}
