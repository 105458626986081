// Dtos
import { FitnessFiltersDto } from "../../api/dtos";

// Domain Models
import { FitnessFilter } from "./fitness-filter.model";

export class FitnessFilters {
  exercises?: FitnessFilter[];
  equipments?: FitnessFilter[];
  targets?: FitnessFilter[];

  constructor(entity?: FitnessFiltersDto) {
    this.exercises = entity?.exercises.map(
      exercise => new FitnessFilter(exercise) || []
    );
    this.equipments = entity?.equipments?.map(
      equipment => new FitnessFilter(equipment) || []
    );
    this.targets =
      entity?.targets.map(target => new FitnessFilter(target)) || [];
  }
}
