// components
import { FileUpload } from "../../../../../components/UI";

// Enums
import { OnboardingPageElementTypeEnum } from "../../../../../enums/app";

// Contracts
import { FileFormProps } from "../contracts/forms-based-on-type.types";

const FileForm = ({
  pageElementType,
  setFieldValue,
  touched,
  errors,
  values
}: FileFormProps) => {
  return (
    <>
      {(pageElementType === OnboardingPageElementTypeEnum.AUDIO ||
        pageElementType === OnboardingPageElementTypeEnum.VIDEO) && (
        <FileUpload
          name="coverImage"
          id="coverImage"
          value={values.coverImage}
          setFieldValue={setFieldValue}
          touched={touched.coverImage}
          error={errors.coverImage}
          placeholder="Upload Cover Image"
        />
      )}
      <FileUpload
        name="file"
        id="file"
        value={values.file}
        setFieldValue={setFieldValue}
        touched={touched.file}
        error={errors.file}
        placeholder="Upload File"
      />
    </>
  );
};

export default FileForm;
