import validator from "validator";

export const loginFormValidation = ({
  email,
  password
}: {
  email: string;
  password: string;
}) => {
  const errors: any = {};

  if (!email) {
    errors.email = "* Required";
  } else if (!validator.isEmail(email)) {
    errors.email = "You have entered an invalid email address.";
  }

  if (!password) {
    errors.password = "* Required";
  }
  // else if (
  //   !validator.isStrongPassword(password, {
  //     minLength: 8,
  //     minLowercase: 1,
  //     minUppercase: 1,
  //     minNumbers: 1,
  //     minSymbols: 0,
  //   })
  // ) {
  //   errors.password =
  //     "Password must be 8 or more characters length, must contain at least one lowercase letter, at least one capital letter and at least one number.";
  // }
  return errors;
};
