// Dtos
import { ShopArticleDto } from "../dtos";

// Domain Models
import { ShopArticle } from "../../app/domain";

// Api Enums
import { ResponseStatusType } from "../../../enums/api";

export type ShopArticleResponseDataType = {
  shop_item: ShopArticleDto;
};

export class ShopArticleResponse {
  shopArticle: ShopArticle;
  statusType: ResponseStatusType;

  constructor(responseData: ShopArticleResponseDataType) {
    this.shopArticle = new ShopArticle(responseData.shop_item);
    this.statusType = ResponseStatusType.OK;
  }
}
