import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

// Theme
import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

// Components
import PageHeader from "../../components/pageHeader/PageHeader";

// Enums
import { ResponseStatusType } from "../../enums/api";

// Calls
import {
  fetchFitnessList,
  deleteFetnessItem
} from "../../api/calls/fitness/fitness";

// Config
import { Row, createData, columns } from "./fitness-table.config";

const Fitness = () => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<Row[]>([]);

  /**
   * Sets page number for paggination
   * @param newPage - number
   */
  const handleChangePage = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  /**
   * Sets number of items in table per page
   * @param event - any
   */
  const handleChangeRowsPerPage = (event: any): void => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /**
   * Removes chosen fitness from fitness list
   * Requires users confirmation
   * @param id - string
   */
  const removeFitness = async (id: string): Promise<void> => {
    const result = window.confirm("Are u sure u want to delete this fitness?");
    if (!result) {
      return;
    }
    const response = await deleteFetnessItem(id);
    if (response.statusType !== ResponseStatusType.OK) {
      return;
    }
    toast.success(response.message);
    setRows(prevValues => prevValues.filter(row => row.id !== id));
  };

  /**
   * Fetch Fitnesses for list of Fitnesses and create data for list
   */
  const getFitnessList = async (): Promise<void> => {
    const response = await fetchFitnessList();
    if (response.statusType !== ResponseStatusType.OK) {
      return;
    }

    setRows(
      response.fitnessList.map(fitness =>
        createData(fitness.name, fitness.id, fitness.type)
      )
    );
  };

  useEffect(() => {
    getFitnessList();
  }, []);

  return (
    <>
      <PageHeader title="Fitness">
        <Button
          sx={{ marginLeft: "10px" }}
          onClick={() => history.push(`create-fitness`)}
          variant="contained"
        >
          Add new fitness
        </Button>
      </PageHeader>
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 220px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column: any) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "id" ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end"
                                }}
                              >
                                <Button
                                  onClick={() =>
                                    history.push(`edit-fitness/${value}`)
                                  }
                                  variant="contained"
                                >
                                  Edit
                                </Button>
                                <Button
                                  onClick={() => removeFitness(value)}
                                  variant="contained"
                                  color="error"
                                >
                                  Delete
                                </Button>
                              </div>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default Fitness;
