import * as yup from "yup";
import { EditorState, convertToRaw } from "draft-js";

// Enums
import {
  TaskPageElementTypeEnum,
  TaskQuestionsTypesEnum
} from "../../../../enums/app";

export const taskPageElementsValidationSchema = () =>
  yup.lazy(values => {
    if (
      values.type === TaskPageElementTypeEnum.AUDIO ||
      values.type === TaskPageElementTypeEnum.VIDEO
    ) {
      return yup.object().shape({
        coverImage: yup.string().typeError("* Required").required("* Required"),
        type: yup.string().typeError("* Required").required("* Required"),
        file: yup.string().typeError("* Required").required("* Required")
      });
    }

    if (values.type === TaskPageElementTypeEnum.IMAGE) {
      return yup.object().shape({
        type: yup.string().typeError("* Required").required("* Required"),
        file: yup.string().typeError("* Required").required("* Required")
      });
    }

    if (values.type === TaskPageElementTypeEnum.TEXT) {
      return yup.object().shape({
        content: yup
          .object()
          .typeError("* Required")
          .test("has text", "* Required", value => {
            if (!(value instanceof EditorState)) {
              return true;
            }
            const contentValue = convertToRaw(value.getCurrentContent());
            const blockWithText = contentValue.blocks.find(
              block => block.text.trim().length !== 0
            );
            return !!blockWithText;
          })
          .required("* Required")
      });
    }

    if (values.type === TaskPageElementTypeEnum.COMPLETE_BUTTON) {
      return yup.object().shape({
        type: yup.string().required("* Required"),
        color: yup.string().required("* Required"),
        content: yup.string().required("* Required")
      });
    }

    if (values.type === TaskPageElementTypeEnum.CONTINUE_BUTTON) {
      return yup.object().shape({
        type: yup.string().required("* Required"),
        color: yup.string().required("* Required"),
        content: yup.string().required("* Required")
      });
    }

    if (values.type === TaskPageElementTypeEnum.QUESTION) {
      if (
        values.question &&
        values.question.type === TaskQuestionsTypesEnum.JOURNAL
      ) {
        return yup.object().shape({
          type: yup.string().required("* Required"),
          question: yup.object().shape({
            type: yup.string().required("* Required"),
            title: yup
              .object()
              .typeError("* Required")
              .test("has text", "* Required", value => {
                if (!(value instanceof EditorState)) {
                  return true;
                }
                const contentValue = convertToRaw(value.getCurrentContent());
                return !(contentValue.blocks[0].text.trim().length === 0);
              })
              .required("* Required")
          })
        });
      }

      if (
        values.question &&
        values.question.type === TaskQuestionsTypesEnum.CONDITIONAL
      ) {
        return yup.object().shape({
          type: yup.string().required("* Required"),
          question: yup.object().shape({
            type: yup.string().required("* Required"),
            title: yup
              .object()
              .typeError("* Required")
              .test("has text", "* Required", value => {
                if (!(value instanceof EditorState)) {
                  return true;
                }
                const contentValue = convertToRaw(value.getCurrentContent());
                return !(contentValue.blocks[0].text.trim().length === 0);
              })
              .required("* Required"),
            answers: yup
              .array()
              .typeError("* Required")
              .of(
                yup.object().shape({
                  answer: yup.string().required("* Required")
                })
              )
              .required("* Required")
              .min(2, "Question must contain 2 answers")
          })
        });
      }

      if (
        values.question &&
        values.question.type === TaskQuestionsTypesEnum.POP
      ) {
        return yup.object().shape({
          type: yup.string().required("* Required"),
          question: yup.object().shape({
            type: yup.string().required("* Required"),
            title: yup
              .object()
              .typeError("* Required")
              .test("has text", "* Required", value => {
                if (!(value instanceof EditorState)) {
                  return true;
                }
                const contentValue = convertToRaw(value.getCurrentContent());
                return !(contentValue.blocks[0].text.trim().length === 0);
              })
              .required("* Required"),
            explanation: yup.string().required("* Required"),
            answers: yup
              .array()
              .typeError("* Required")
              .of(
                yup.object().shape({
                  answer: yup.string().required("* Required")
                })
              )
              .required("* Required")
              .min(2, "Question must contain 2 answers")
              .test(
                "has text",
                "* POP question must have at least one correct answer",
                value => {
                  let haveCorrectAnswer = false;
                  value?.forEach(answer => {
                    if (answer.isCorrect) {
                      haveCorrectAnswer = true;
                    }
                  });
                  return haveCorrectAnswer;
                }
              )
          })
        });
      }

      if (
        values.question &&
        values.question.type === TaskQuestionsTypesEnum.CONSULTATION
      ) {
        return yup.object().shape({
          type: yup.string().required("* Required"),
          question: yup.object().shape({
            type: yup.string().required("* Required"),
            title: yup
              .object()
              .typeError("* Required")
              .test("has text", "* Required", value => {
                if (!(value instanceof EditorState)) {
                  return true;
                }
                const contentValue = convertToRaw(value.getCurrentContent());
                return !(contentValue.blocks[0].text.trim().length === 0);
              })
              .required("* Required"),
            answers: yup
              .array()
              .typeError("* Required")
              .of(
                yup.object().shape({
                  answer: yup.string().required("* Required"),
                  answerScore: yup
                    .number()
                    .required("* Required")
                    .min(0, "Score must be 0 or higher")
                })
              )
              .required("* Required")
              .min(2, "Question must contain 2 answers")
          })
        });
      }

      return yup.object().shape({
        type: yup.string().required("* Required"),
        question: yup.object().shape({
          type: yup.string().required("* Required")
        })
      });
    }

    return yup.object().shape({
      type: yup.string().required("* Required")
    });
  });
