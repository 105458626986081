import { EditorState } from "draft-js";

// Enums
import { FitnessPageElementTypeEnum } from "../../../enums/app";

// UI Models
import { FitnessPageElementUI } from "../../app/ui";

// Utils
import { valueOrNull } from "../../../utils/global.utils";

export class FitnessPageElementRequest {
  id?: string;
  sequence_number: number;
  type: FitnessPageElementTypeEnum | string;
  content: EditorState | string | null;
  color: string | null;
  // link: string | null;
  file: string | null;
  cover_image: string | null;

  constructor(taskPageElementUI: FitnessPageElementUI) {
    this.id = taskPageElementUI.id;
    this.sequence_number = taskPageElementUI.sequenceNumber;
    this.type = taskPageElementUI.type;
    this.color = valueOrNull(taskPageElementUI.color);
    this.content = valueOrNull(taskPageElementUI.content);
    // this.link = valueOrNull(taskPageElementUI.link);
    this.file = valueOrNull(taskPageElementUI.file);
    this.cover_image = valueOrNull(taskPageElementUI.coverImage);
  }
}
