// Ui Models
import { TaskQuestionAnswerUI } from "../../app/ui";

// App Enum
import { AnswerTypeEnum } from "../../../enums/app";

export class QuestionAnswerRequest {
  id?: string;
  answer?: string;
  sequence_number: number;
  type: AnswerTypeEnum;
  is_correct?: boolean;
  answer_score?: number | null;

  constructor(taskQuestionAnswerUI: TaskQuestionAnswerUI) {
    this.id = taskQuestionAnswerUI.id;
    this.answer = taskQuestionAnswerUI.answer;
    this.type = taskQuestionAnswerUI.type;
    this.sequence_number = taskQuestionAnswerUI.sequenceNumber;
    this.is_correct = taskQuestionAnswerUI.isCorrect;
    this.answer_score = taskQuestionAnswerUI.answerScore;
  }
}
