import * as yup from "yup";

export const fitnessValidationSchema = () =>
  yup.lazy(values => {
    return yup.object().shape({
      name: yup.string().required("* Required"),
      type: yup.string().required("* Required"),
      difficulty: yup.string().required("* Required"),
      duration: yup.number().min(1, "* Duration must be bigger then 0"),
      pages: yup.array().min(1, "* Fitness must have at least one page"),
      image: yup.string().typeError("* Required").required("* Required")
    });
  });
