// DTOS
import { OnboardingDto } from "../dtos";
// DOMAIN Models
import { Onboarding } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type OnboardingResponseDataType = {
  onboarding: OnboardingDto;
  statusType: ResponseStatusType;
};

export class OnboardingResponse {
  onboarding: Onboarding;
  statusType: ResponseStatusType;

  constructor(responseData: OnboardingResponseDataType) {
    this.onboarding = new Onboarding(responseData.onboarding);
    this.statusType = ResponseStatusType.OK;
  }
}
