// DTOS
import { OnboardingPageElementDto } from "../dtos";
// DOMAIN Models
import { OnboardingPageElement } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type OnboardingPageElementResponseDataType = {
  task_page_element: OnboardingPageElementDto;
  statusType: ResponseStatusType;
};

export class OnboardingPageElementResponse {
  onboardingPageElement: OnboardingPageElement;
  statusType: ResponseStatusType;

  constructor(responseData: OnboardingPageElementResponseDataType) {
    this.onboardingPageElement = new OnboardingPageElement(
      responseData.task_page_element
    );
    this.statusType = ResponseStatusType.OK;
  }
}
