// UI Models
import { FitnessPageElementUI } from "./fitness-page-element.ui";

// Domain Models
import { FitnessPage } from "../domain";

type FitnessPageUIType = {
  id?: string;
  pageNumber?: number;
  pageElements?: FitnessPageElementUI[];
};

export class FitnessPageUI {
  id?: string;
  pageNumber: number;
  pageElements: FitnessPageElementUI[];

  constructor(data?: FitnessPageUIType | FitnessPage | null) {
    this.id = data?.id;
    this.pageNumber = data?.pageNumber || 0;
    this.pageElements =
      data?.pageElements?.map(element => new FitnessPageElementUI(element)) ||
      [];
  }
}
