// DTOS
import { TaskPageDTO } from "../dtos";
// DOMAIN Models
import { TaskPage } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type TaskPageResponseDataType = {
  task_page: TaskPageDTO;
  statusType: ResponseStatusType;
};

export class TaskPageResponse {
  taskPage: TaskPage;
  statusType: ResponseStatusType;

  constructor(responseData: TaskPageResponseDataType) {
    this.taskPage = new TaskPage(responseData.task_page);
    this.statusType = ResponseStatusType.OK;
  }
}
