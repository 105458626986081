// enums
import { TaskTypeEnum } from "../../../enums/app";
// DTOS
import { TaskPageDTO } from "../../api/dtos";
// DOMAIN Models
import { TaskPageElement } from "./taskPageElement.model";

export class TaskPage {
  id: string;
  pageNumber: number;
  taskPageElements?: TaskPageElement[];
  isFirstPageFront?: boolean;
  isLastPageFront?: boolean;

  constructor(
    entity: TaskPageDTO,
    taskType?: TaskTypeEnum,
    pageElememntsLength?: number
  ) {
    this.id = entity.id;
    this.pageNumber = entity.page_number;
    this.isFirstPageFront =
      taskType === TaskTypeEnum.QUIZ && entity.page_number === 1 ? true : false;
    this.isLastPageFront =
      taskType === TaskTypeEnum.QUIZ &&
      entity.page_number === pageElememntsLength
        ? true
        : false;
    this.taskPageElements = entity.page_elements?.map(
      element => new TaskPageElement(element)
    );
  }
}
