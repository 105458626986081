// REQUEST Models
import { FitnessPageRequest } from "./fitness-page.request";

// UI Models
import { FitnessUI } from "../../app/ui";

// App Enums
import { FitnessTypeEnum, FitnessDifficultyEnum } from "../../../enums/app";

// Utils
import { valueOrNull } from "../../../utils/global.utils";

export class FitnessRequest {
  cover_image: string | null;
  title: string;
  duration: number;
  type: FitnessTypeEnum | string;
  difficulty: FitnessDifficultyEnum | string;
  exercises?: string[];
  equipments?: string[];
  targets?: string[];
  fitness_pages: FitnessPageRequest[];

  constructor(fitnessUI: FitnessUI) {
    this.cover_image = valueOrNull(fitnessUI.image);
    this.title = fitnessUI.name;
    this.duration = fitnessUI.duration;
    this.type = fitnessUI.type;
    this.difficulty = fitnessUI.difficulty;
    this.exercises = fitnessUI.exercises;
    this.equipments = fitnessUI.equipments;
    this.targets = fitnessUI.targets;
    this.fitness_pages = fitnessUI.pages.map(
      page => new FitnessPageRequest(page)
    );
  }
}
