import { Editor } from "react-draft-wysiwyg";
import { Field, FieldProps, FieldArray } from "formik";
import { toast } from "react-toastify";

// Theme components
import {
  Card,
  Select,
  Checkbox,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
  FormControlLabel
} from "@mui/material";
import Typography from "@mui/material/Typography";

// Builders
import {
  questionTypeOptions,
  answerTypeOptions
} from "../../../../../builders/onboarding-page-elements/onboarding-page-elements.builder";

// App Enums
import {
  OnboardingQuestionsTypesEnum,
  OnboardingPageElementTypeEnum,
  QuestionAnswerTypeEnum,
  OnboardingFormTypeEnum
} from "../../../../../enums/app";

// Models
import { OnboardingQuestionAnswerUI } from "../../../../../models/app/ui";

// Contracts
import { QuestionFormProps } from "../contracts/forms-based-on-type.types";

// Utils
import {
  convertStringToEditorState,
  updateInstanceObject,
  isEmpty
} from "../../../../../utils/global.utils";

// Styles
import classes from "../onboardingPageElementForm.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const QuestionForm = ({
  setFieldValue,
  removeAnswerFromQuestion,
  formTab,
  errors,
  touched,
  values
}: QuestionFormProps) => {
  return (
    <>
      {formTab === OnboardingFormTypeEnum.PAGE_ELEMENT_FORM && (
        <div className={classes.taskFormContainer}>
          <div className={classes.fullWidth}>
            <Field name="question.type">
              {({ field, form, meta }: FieldProps) => (
                <FormControl
                  error={meta.touched && meta.error ? true : false}
                  variant="standard"
                  sx={{ width: "100%" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Question type
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={e => {
                      form.setFieldValue(field.name, e.target.value);
                      form.setFieldValue("question.image", null);
                      form.setFieldValue("question.explanation", "");
                      form.setFieldValue(
                        "question.answerType",
                        QuestionAnswerTypeEnum.SINGLE
                      );
                      form.setFieldValue("question.answers", null);
                    }}
                    label="Type"
                    name={field.name}
                  >
                    {questionTypeOptions.map(({ value, label }, i) => (
                      <MenuItem key={i} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                  <p className={classes.errorMessage}>
                    {meta.touched && meta.error && meta.error}
                  </p>
                </FormControl>
              )}
            </Field>
          </div>
        </div>
      )}

      {values.type === OnboardingPageElementTypeEnum.QUESTION && (
        <>
          <InputLabel
            style={{ fontSize: "0.8rem" }}
            id="demo-simple-select-standard-label"
          >
            Question Title
          </InputLabel>
          <Field name="question.title">
            {({ field, form, meta }: FieldProps) => (
              <>
                <div
                  className={classes.editorContainer}
                  style={{ minHeight: "200px" }}
                >
                  <Editor
                    onBlur={() => {
                      form.setFieldTouched("question.title", true);
                    }}
                    editorState={convertStringToEditorState(field.value)}
                    onEditorStateChange={editorState =>
                      form.setFieldValue("question.title", editorState)
                    }
                    toolbar={{
                      fontSize: {
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 36, 48, 60, 72, 96],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      fontFamily: {
                        options: [
                          "ITCAvantGardeStd-XLt",
                          "ITCAvantGardeStd-Bk",
                          "ITCAvantGardeStd-Md",
                          "ITCAvantGardeStd-Demi",
                          'ITCAvantGardeStd-BkCnObl'
                        ],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined
                      }
                    }}
                  />
                </div>
                <p className={classes.errorMessage}>
                  {meta.touched && meta.error && meta.error}
                </p>
              </>
            )}
          </Field>

          {formTab === OnboardingFormTypeEnum.PAGE_ELEMENT_FORM &&
            values.question?.type ===
              OnboardingQuestionsTypesEnum.CONDITIONAL && (
              <div className={classes.taskFormContainer}>
                <div className={classes.fullWidth}>
                  <Field name="question.answerType">
                    {({ field, form, meta }: FieldProps) => (
                      <FormControl
                        error={meta.touched && meta.error ? true : false}
                        variant="standard"
                        sx={{ width: "100%" }}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Answer type
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={field.value}
                          onBlur={field.onBlur}
                          onChange={e => {
                            form.setFieldValue(field.name, e.target.value);
                          }}
                          label="Answer type"
                          name={field.name}
                        >
                          {answerTypeOptions.map(({ value, name }, i) => (
                            <MenuItem key={i} value={value}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                        <p className={classes.errorMessage}>
                          {meta.touched && meta.error && meta.error}
                        </p>
                      </FormControl>
                    )}
                  </Field>
                </div>
              </div>
            )}

          {values.question?.type &&
            values.question?.type !== OnboardingQuestionsTypesEnum.JOURNAL && (
              <FieldArray
                name="question.answers"
                render={arrayHelpers => {
                  const addNewAnswer = () => {
                    let lastAnswerSequenceNumber =
                      values.question?.answers?.length || 0;

                    arrayHelpers.push(
                      new OnboardingQuestionAnswerUI({
                        sequenceNumber: lastAnswerSequenceNumber + 1
                      })
                    );
                  };

                  const deleteAnswer = async (
                    index: number,
                    answer: OnboardingQuestionAnswerUI
                  ): Promise<void> => {
                    removeAnswerFromQuestion &&
                      removeAnswerFromQuestion(
                        index,
                        values,
                        setFieldValue,
                        answer.id
                      );
                  };

                  return (
                    <Card className={classes.card}>
                      <div className={classes.answersContainer}>
                        <Typography
                          className={classes.headerTitle}
                          variant="h6"
                        >
                          Answers
                        </Typography>
                        <p className={classes.errorMessage}>
                          {touched?.question?.answers &&
                            !Array.isArray(errors?.question?.answers) &&
                            errors?.question?.answers}
                        </p>
                        <Button onClick={addNewAnswer} variant="contained">
                          Add answer
                        </Button>
                      </div>
                      <div>
                        {values.question?.answers?.map((answer, index) => (
                          <Card className={classes.card} key={index}>
                            <div
                              className={classes.answersContainer}
                              style={{ marginBottom: "20px" }}
                            >
                              <Typography
                                className={classes.headerTitle}
                                variant="h6"
                              >
                                Answer {answer.sequenceNumber}
                              </Typography>
                              <Button
                                onClick={() => deleteAnswer(index, answer)}
                                color="error"
                                variant="contained"
                              >
                                Delete answer
                              </Button>
                            </div>
                            <div className={classes.taskFormContainer}>
                              <div className={classes.fullWidth}>
                                <Field
                                  name={`question.answers[${index}].answer`}
                                >
                                  {({ field, meta }: FieldProps) => (
                                    <>
                                      <TextField
                                        fullWidth
                                        id="content"
                                        type="text"
                                        name={field.name}
                                        error={
                                          meta.touched && meta.error
                                            ? true
                                            : false
                                        }
                                        label="Answer"
                                        variant="standard"
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                      />
                                      <p className={classes.errorMessage}>
                                        {meta.touched &&
                                          meta.error &&
                                          meta.error}
                                      </p>
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </Card>
                        ))}
                      </div>
                    </Card>
                  );
                }}
              />
            )}
        </>
      )}
    </>
  );
};

export default QuestionForm;
