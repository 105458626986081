// Enums
import {
  QuestionAnswerTypeEnum,
  OnboardingQuestionsTypesEnum
} from "../../../enums/app";

// Dtos
import { OnboardingQuestionDto } from "../../api/dtos";

// Domain Models
import { OnboardingQuestionAnswer } from "./onboarding-question-answer.model";

// Utils
import { isEmpty } from "../../../utils/global.utils";

export class OnboardingQuestion {
  id: string;
  title: string;
  image: string;
  explanation: string;
  answerType: QuestionAnswerTypeEnum;
  type: OnboardingQuestionsTypesEnum;
  answers?: OnboardingQuestionAnswer[];

  constructor(entity: OnboardingQuestionDto) {
    this.id = entity.id;
    this.title = entity.title;
    this.image = entity.image;
    this.explanation = entity.explanation;
    this.answerType = entity.answer_type;
    this.type = entity.type;
    this.answers = !isEmpty(entity.answers)
      ? entity.answers?.map(answer => new OnboardingQuestionAnswer(answer))
      : [];
  }
}
