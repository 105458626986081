//App enums
import {
  TaskPageElementTypeEnum,
  TaskTypeEnum,
  TaskQuestionsTypesEnum,
  QuestionAnswerTypeEnum
} from "../../enums/app";

// Page Elements Type Field options
type pageElementsTypeOptionsType = {
  value: TaskPageElementTypeEnum;
  label: string;
};

const pageElementsTypeOptions: pageElementsTypeOptionsType[] = [
  {
    value: TaskPageElementTypeEnum.TEXT,
    label: "Text"
  },
  {
    value: TaskPageElementTypeEnum.IMAGE,
    label: "Image"
  },
  {
    value: TaskPageElementTypeEnum.VIDEO,
    label: "Video"
  },
  {
    value: TaskPageElementTypeEnum.AUDIO,
    label: "Audio"
  },
  {
    value: TaskPageElementTypeEnum.QUESTION,
    label: "Question"
  },
  {
    value: TaskPageElementTypeEnum.COMPLETE_BUTTON,
    label: "Complete button"
  },
  {
    value: TaskPageElementTypeEnum.CONTINUE_BUTTON,
    label: "Continue button"
  }
];

/**
 * Generate options for page element type field
 * @param type - TaskTypeEnum | string
 * @returns pageElementsTypeOptionsType[]
 */
export const generatePageElementTypeOptions = (
  type?: TaskTypeEnum | string,
  isFirstPage?: boolean,
  isLastPage?: boolean
): pageElementsTypeOptionsType[] => {
  if (type === TaskTypeEnum.MESSAGE) {
    return pageElementsTypeOptions.filter(
      option =>
        option.value === TaskPageElementTypeEnum.TEXT ||
        option.value === TaskPageElementTypeEnum.IMAGE ||
        option.value === TaskPageElementTypeEnum.COMPLETE_BUTTON ||
        option.value === TaskPageElementTypeEnum.CONTINUE_BUTTON
    );
  }

  if (type === TaskTypeEnum.QUIZ) {
    if (isFirstPage) {
      return pageElementsTypeOptions.filter(
        option =>
          option.value === TaskPageElementTypeEnum.TEXT ||
          option.value === TaskPageElementTypeEnum.IMAGE ||
          option.value === TaskPageElementTypeEnum.CONTINUE_BUTTON
      );
    }

    if (isLastPage) {
      return pageElementsTypeOptions.filter(
        option =>
          option.value === TaskPageElementTypeEnum.TEXT ||
          option.value === TaskPageElementTypeEnum.COMPLETE_BUTTON
      );
    }

    // return pageElementsTypeOptions.filter(
    //   option => option.value === TaskPageElementTypeEnum.QUESTION
    // );
  }

  return pageElementsTypeOptions;
};

// Question Type Field options

type questionOptionsType = {
  value: TaskQuestionsTypesEnum;
  label: string;
};

const questionTypeOptions = [
  {
    value: TaskQuestionsTypesEnum.POP,
    label: "POP"
  },
  {
    value: TaskQuestionsTypesEnum.CONDITIONAL,
    label: "CONDITIONAL"
  },
  {
    value: TaskQuestionsTypesEnum.CONSULTATION,
    label: "CONSULTATION"
  },
  {
    value: TaskQuestionsTypesEnum.JOURNAL,
    label: "JOURNAL"
  }
];

/**
 * Generate options for question type field
 * @param type - TaskTypeEnum | string | undefined
 * @returns questionOptionsType[]
 */
export const generateQuestionTypeOptions = (
  type?: TaskTypeEnum | string
): questionOptionsType[] => {
  if (type === TaskTypeEnum.QUIZ) {
    return questionTypeOptions.filter(
      option => option.value !== TaskQuestionsTypesEnum.JOURNAL
    );
  }

  if (type === TaskTypeEnum.LESSON) {
    return questionTypeOptions.filter(
      option => option.value !== TaskQuestionsTypesEnum.CONSULTATION
    );
  }

  return questionTypeOptions;
};

export const fileUploadTypes: string[] = [
  TaskPageElementTypeEnum.IMAGE,
  TaskPageElementTypeEnum.VIDEO,
  TaskPageElementTypeEnum.AUDIO
];

export const buttonTypes: string[] = [
  TaskPageElementTypeEnum.DEEPLINK,
  TaskPageElementTypeEnum.COMPLETE_BUTTON,
  TaskPageElementTypeEnum.CONTINUE_BUTTON
];

type QuestionAnswerTypeOptions = {
  value: QuestionAnswerTypeEnum;
  name: string;
};

export const answerTypeOptions: QuestionAnswerTypeOptions[] = [
  {
    value: QuestionAnswerTypeEnum.SINGLE,
    name: "Single Select"
  },
  {
    value: QuestionAnswerTypeEnum.MULTISELECT,
    name: "Multi Select"
  }
];
