import { EditorState } from "draft-js";

// Enums
import { OnboardingPageElementTypeEnum } from "../../../enums/app";

// UI Models
import { OnboardingPageElementUI } from "../../app/ui";

// Request Models
import { QuestionRequest } from "./onboarding-question.request";

// Utils
import { isEmpty, valueOrNull } from "../../../utils/global.utils";

export class OnboardingPageElementRequest {
  id?: string;
  sequence_number: number;
  type: OnboardingPageElementTypeEnum | string;
  content: EditorState | string | null;
  color: string | null;
  file: string | null;
  cover_image: string | null;
  question: QuestionRequest | null;

  constructor(onboardingPageElementUI: OnboardingPageElementUI) {
    this.id = onboardingPageElementUI.id;
    this.sequence_number = onboardingPageElementUI.sequenceNumber;
    this.type = onboardingPageElementUI.type;
    this.color = valueOrNull(onboardingPageElementUI.color);
    this.content = valueOrNull(onboardingPageElementUI.content);
    this.file = valueOrNull(onboardingPageElementUI.file);
    this.cover_image = valueOrNull(onboardingPageElementUI.coverImage);
    this.question = !isEmpty(onboardingPageElementUI.question)
      ? onboardingPageElementUI.question !== null
        ? new QuestionRequest(onboardingPageElementUI.question)
        : null
      : null;
  }
}
