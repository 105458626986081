import { useState, useEffect } from "react";
import { toast } from "react-toastify";

// Theme
import { Button, Modal, Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

// Components
import PageHeader from "../../components/pageHeader/PageHeader";

// Containers
import AddAdmin from "../../containers/forms/admins/AddAdmin";
import EditAdmin from "../../containers/forms/admins/EditAdmin";

// Enums
import { ResponseStatusType } from "../../enums/api";

// Calls
import { fetchUsers, deleteUser } from "../../api/calls/users/users";

// Config
import { Row, createData, columns } from "./admins-table.config";

// Styles
import classes from "./adminPage.module.css";

const Admins = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [adminId, setAdminId] = useState<string | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<Row[]>([]);

  /**
   * Closes modal
   */
  const handleCloseModal = (): void => {
    setIsOpen(false);
    setAdminId(undefined);
  };

  /**
   * Sets page number for paggination
   * @param newPage - number
   */
  const handleChangePage = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  /**
   * Sets number of items in table per page
   * @param event - any
   */
  const handleChangeRowsPerPage = (event: any): void => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /**
   * Fetch users for list of users and create data for list
   */
  const fetchAdmins = async (): Promise<void> => {
    const response = await fetchUsers("admin");
    if (response.statusType !== ResponseStatusType.OK) {
      return;
    }
    setRows(
      response.users.map(user =>
        createData(
          user.firstName,
          user.id,
          user.accountId,
          user.lastName,
          user.email
        )
      )
    );
  };

  /**
   * Removes chosen user from list of users
   * Requires users confirmation
   * @param id - string
   */
  const removeUser = async (id: string): Promise<void> => {
    const result = window.confirm("Are u sure u want to delete this user?");
    if (!result) {
      return;
    }
    const response = await deleteUser(id);
    if (response.statusType !== ResponseStatusType.OK) {
      return;
    }
    toast.success("User successfully deleted.");
    fetchAdmins();
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  const editAdmin = (id: string) => {
    setAdminId(id);
    setIsOpen(true);
  };

  const postCreateUpdateAction = (): void => {
    fetchAdmins();
    handleCloseModal();
  };

  return (
    <>
      <PageHeader title="Admins">
        <Button
          sx={{ marginLeft: "10px" }}
          onClick={() => setIsOpen(true)}
          variant="contained"
        >
          Add new admin
        </Button>
      </PageHeader>
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 220px)" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column: any) => {
                        const value = row[column.id];

                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "actions" ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end"
                                }}
                              >
                                <Button
                                  onClick={() => editAdmin(value.id)}
                                  variant="contained"
                                  color="primary"
                                >
                                  Edit
                                </Button>
                                <Button
                                  onClick={() => removeUser(value.accountId)}
                                  variant="contained"
                                  color="error"
                                >
                                  Delete
                                </Button>
                              </div>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Modal open={isOpen} onClose={handleCloseModal}>
        <Box className={classes.modalBox}>
          {adminId ? (
            <EditAdmin
              postCreateUpdateAction={postCreateUpdateAction}
              handleClose={handleCloseModal}
              adminId={adminId}
            />
          ) : (
            <AddAdmin
              postCreateUpdateAction={postCreateUpdateAction}
              handleClose={handleCloseModal}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Admins;
