// Enums
import {
  TaskTypeEnum,
  TaskLifecycleTypeEnum,
  TaskQuestionsTypesEnum,
  KpiTypeEnum
} from "../../../enums/app";

// Ui Models
import { TaskPageUI } from "./task-page.ui";
import { QuizCompletionMessageUI } from "./quiz-completion-message.ui";

// Domain Models
import { Task } from "../domain";

type TaskUIType = {
  id?: string;
  title?: string;
  taskType?: TaskTypeEnum | string;
  quizTypeFront?: TaskQuestionsTypesEnum | string;
  lifecycleType?: TaskLifecycleTypeEnum | null;
  kpiType?: KpiTypeEnum | null;
  visibleInHistory?: boolean;
  dispatchNotification?: boolean;
  visibleInMindfulness?: boolean;
  triggeredByTaskId?: string;
  sequenceNumber?: number;
  durationInSeconds?: number;
  titleColor?: string;
  triggerIn?: number;
  availableFor?: number;
  repetitionCount?: number;
  repetitionInterval?: number;
  scheduledFor?: Date | null;
  availableTo?: Date | null;
  taskPages?: TaskPageUI[];
  quizCompletionMessages?: QuizCompletionMessageUI[];
};

export class TaskUI {
  id?: string;
  title: string;
  taskType: TaskTypeEnum | string;
  lifecycleType: TaskLifecycleTypeEnum | null;
  kpiType?: KpiTypeEnum | string;
  quizTypeFront?: TaskQuestionsTypesEnum | string;
  visibleInHistory: boolean;
  dispatchNotification: boolean;
  visibleInMindfulness: boolean;
  triggeredByTaskId?: string;
  sequenceNumber: number;
  durationInSeconds: number;
  titleColor: string;
  triggerIn: number;
  availableFor: number;
  repetitionCount: number;
  repetitionInterval: number;
  scheduledFor: Date | null;
  availableTo: Date | null;
  taskPages: TaskPageUI[];
  quizCompletionMessages?: QuizCompletionMessageUI[];

  constructor(data?: TaskUIType | Task | null) {
    this.id = data?.id;
    this.title = data?.title || "";
    this.taskType = data?.taskType || "";
    this.lifecycleType = data?.lifecycleType || null;
    this.kpiType = data?.kpiType || "";
    this.quizTypeFront = data?.quizTypeFront || "";
    this.visibleInHistory = data?.visibleInHistory || false;
    this.dispatchNotification = data?.dispatchNotification || false;
    this.visibleInMindfulness = data?.visibleInMindfulness || false;
    this.triggeredByTaskId = data?.triggeredByTaskId || "";
    this.sequenceNumber = data?.sequenceNumber || 0;
    this.durationInSeconds = data?.durationInSeconds || 0;
    this.titleColor = data?.titleColor || "";
    this.triggerIn = data?.triggerIn || 0;
    this.availableFor = data?.availableFor || 0;
    this.repetitionCount = data?.repetitionCount || 0;
    this.repetitionInterval = data?.repetitionInterval || 0;
    this.scheduledFor = data?.scheduledFor || null;
    this.availableTo = data?.availableTo || null;
    this.taskPages = data?.taskPages?.map(page => new TaskPageUI(page)) || [];
    this.quizCompletionMessages = data?.quizCompletionMessages || [];
  }
}
