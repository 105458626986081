// REQUEST Models
import { FitnessPageElementRequest } from "./fitness-page-element.request";
// UI Models
import { FitnessPageUI } from "../../app/ui";

export class FitnessPageRequest {
  id?: string;
  page_number: number;
  fitness_page_elements: FitnessPageElementRequest[];

  constructor(fitnessPageUI: FitnessPageUI) {
    this.id = fitnessPageUI.id;
    this.page_number = fitnessPageUI.pageNumber;
    this.fitness_page_elements = fitnessPageUI.pageElements?.map(
      element => new FitnessPageElementRequest(element)
    );
  }
}
