//UI Models
import { AdminUI } from "../../../models/app/ui";

export const editAdminValidation = ({
  firstName,
  lastName,
  email,
  password
}: AdminUI): any => {
  const errors: any = {};

  if (!firstName) {
    errors.firstName = "* Required";
  }

  if (!lastName) {
    errors.lastName = "* Required";
  }

  if (!email) {
    errors.email = "* Required";
  }

  return errors;
};
