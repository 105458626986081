import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

// Components
import PageHeader from "../../components/pageHeader/PageHeader";

// Containers
import FitnessForm from "../../containers/forms/fitness/fitness-form/Fitness.form";
import FitnessPageFrom from "../../containers/forms/fitness/fitness-page-form/FitnessPage.form";
import FitnessPageElementForm from "../../containers/forms/fitness/fitness-page-element-form/FitnessPageElement.form";

// Contracts
import { StateStructureType, RouteParams } from "./contracts/edit-fitness.type";

// Calls
import {
  fetchFitnessFilters,
  deleteFitnessPage,
  deleteFitnessPageElement
} from "../../api/calls/fitness/fitness";

// App Enums
import { FitnessFormTypeEnum } from "../../enums/app";

// Api Enums
import { ResponseStatusType } from "../../enums/api";

// UI Models
import {
  FitnessUI,
  FitnessPageUI,
  FitnessPageElementUI
} from "../../models/app/ui";

// Domain Models
import { FitnessFilters } from "../../models/app/domain";

// Utils
import * as utils from "../../utils/global.utils";

const EditFitness = () => {
  const { id } = useParams<RouteParams>();

  const [pageState, setPageState] = useState<StateStructureType>({
    previousFormTab: FitnessFormTypeEnum.FITNESS_FORM,
    formTab: FitnessFormTypeEnum.FITNESS_FORM,
    fitness: null,
    chosenPageId: undefined,
    chosenPage: undefined,
    chosenPageElementId: undefined,
    chosenPageElement: undefined,
    filters: new FitnessFilters()
  });

  /**
   * Fetch filters and fitness details and set to page state
   */
  const getFilters = async (): Promise<void> => {
    const filtersResponse = await fetchFitnessFilters();
    if (filtersResponse.statusType !== ResponseStatusType.OK) {
      return;
    }

    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        filters: filtersResponse.filters
      })
    );
  };

  useEffect(() => {
    getFilters();
  }, []);

  // console.log(pageState);

  /**
   * Sets formTab and previousFormTab
   */

  const goBackOneFormHandler = (
    pageId?: string,
    chosenPage?: FitnessPageUI | null
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        formTab: prevValues.previousFormTab,
        previousFormTab: FitnessFormTypeEnum.FITNESS_FORM,
        chosenPageId: pageId ? pageId : undefined,
        chosenPage: chosenPage ? chosenPage : undefined,
        chosenPageElement: undefined,
        chosenPageElementId: undefined
      })
    );
  };

  // FITNESS ACTIONS

  /**
   * Saves values from task form in task state
   * Sets chosenPage as new TaskPageUI with predefined page number
   * @param fitnessValues - FitnessUI
   */

  const addNewPage = (fitnessValues: FitnessUI): void => {
    let lastTaskPageNumber = fitnessValues.pages.length || 0;

    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        previousFormTab: FitnessFormTypeEnum.FITNESS_FORM,
        formTab: FitnessFormTypeEnum.PAGE_FORM,
        chosenPage: new FitnessPageUI({ pageNumber: lastTaskPageNumber + 1 })
      })
    );
  };

  /**
   * Saves values from fitness form in fitness state
   * Sets chosenPage from list of pages
   * @param fitnessValues - FitnessUI
   * @param page - FitnessPageUI
   */
  const editPage = (fitnessValues: FitnessUI, page: FitnessPageUI): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        fitness:
          prevValues.fitness &&
          utils.updateInstanceObject(FitnessUI, prevValues.fitness, {
            ...fitnessValues
          }),
        chosenPage: page,
        previousFormTab: FitnessFormTypeEnum.FITNESS_FORM,
        formTab: FitnessFormTypeEnum.EDIT_PAGE_FORM
      })
    );
  };

  /**
   * Saves values from fitness form in fitness state
   * Sets chosenPage from list of pages
   * @param fitnessValues - FitnessUI
   * @param page - FitnessPageUI
   */
  const editPageWithId = (fitnessPageId: string): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPageId: fitnessPageId,
        previousFormTab: FitnessFormTypeEnum.FITNESS_FORM,
        formTab: FitnessFormTypeEnum.EDIT_PAGE_FORM
      })
    );
  };

  /**
   * Removes chosen page from list of pages and fixes pages number order
   * Requires users confirmation
   * @param pageIndex - number
   */
  const removePageFromFitness = async (
    pageIndex: number,
    pageId?: string,
    values?: FitnessUI,
    setFieldValue?: (fieldName: string, values: FitnessPageUI[]) => void
  ): Promise<void> => {
    const hasOnePage = values?.pages.length === 1;

    if (hasOnePage) {
      toast.info(
        "In order do delete last page you must first create a new page!"
      );
      return;
    }
    const result = window.confirm("Are u sure u want to delete this page?");

    if (!result) {
      return;
    }

    if (pageId) {
      const response = await deleteFitnessPage(pageId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
    }

    values &&
      setFieldValue &&
      setFieldValue(
        "pages",
        values?.pages
          .filter((page, i) => i !== pageIndex)
          .map((page, i) =>
            utils.updateInstanceObject(FitnessPageUI, page, {
              pageNumber: i + 1
            })
          )
      );
  };

  // FITNESS PAGE ACTIONS

  /**
   * Adds new page element
   * Saves current page form values
   * @param currentFormTab - FitnessFormTypeEnum
   * @param pageFormValues - FitnessPageUI
   */
  const addNewPageElement = (
    currentFormTab: FitnessFormTypeEnum,
    pageFormValues: FitnessPageUI
  ): void => {
    const lastPageElementNumber: number =
      pageFormValues?.pageElements?.length || 0;

    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage: utils.updateInstanceObject(FitnessPageUI, prevValues, {
          ...pageFormValues
        }),
        chosenPageElement: new FitnessPageElementUI({
          sequenceNumber: lastPageElementNumber + 1
        }),
        previousFormTab: currentFormTab,
        formTab: FitnessFormTypeEnum.PAGE_ELEMENT_FORM
      })
    );
  };

  /**
   * Appends new page to task at the end of list
   * @param page - FitnessPageUI
   */
  const appendNewPageToFitness = (page: FitnessPageUI): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage: null,
        previousFormTab: FitnessFormTypeEnum.FITNESS_FORM,
        formTab: FitnessFormTypeEnum.FITNESS_FORM,
        fitness:
          prevValues.fitness &&
          utils.updateInstanceObject(FitnessUI, prevValues.fitness, {
            pages: !utils.isEmpty(prevValues.fitness.pages)
              ? [...prevValues.fitness.pages, page]
              : [page]
          })
      })
    );
  };

  /**
   * Adds page element from list of elements in page to chosenPageElement
   * Saves current page form values
   * @param currentFormTab - TaskFormTypeEnum
   * @param pageFormValues - TaskPageUI
   */
  const editPageElement = (
    pageFormValues: FitnessPageUI,
    pageElelement: FitnessPageElementUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage: utils.updateInstanceObject(FitnessPageUI, prevValues, {
          ...pageFormValues
        }),
        previousFormTab: prevValues.formTab,
        formTab: FitnessFormTypeEnum.EDIT_PAGE_ELEMENT_FORM,
        chosenPageElement: pageElelement
      })
    );
  };

  /**
   * Adds page element from list of elements in page to chosenPageElement
   * Saves current page form values
   * @param elementId - string
   * @param pageFormValues - TaskPageUI
   */
  const editPageElementWithId = (
    elementId: string,
    pageFormValues: FitnessPageUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage: utils.updateInstanceObject(FitnessPageUI, prevValues, {
          ...pageFormValues
        }),
        chosenPageElementId: elementId,
        previousFormTab: prevValues.formTab,
        formTab: FitnessFormTypeEnum.EDIT_PAGE_ELEMENT_FORM
      })
    );
  };

  /**
   * Update fitness page with page
   * @param page - FitnessPageUI
   */
  const updatePage = (updatedPage: FitnessPageUI): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        fitness:
          prevValues.fitness &&
          utils.updateInstanceObject(FitnessUI, prevValues.fitness, {
            pages: prevValues.fitness.pages.map(page =>
              page.pageNumber === updatedPage.pageNumber ? updatedPage : page
            )
          }),
        chosenPage: null,
        previousFormTab: FitnessFormTypeEnum.FITNESS_FORM,
        formTab: FitnessFormTypeEnum.FITNESS_FORM
      })
    );
  };

  /**
   * Removes chosen page element from list of elements in chosen page and fixes page elements number order
   * Requires users confirmation
   * @param pageIndex - number
   */
  /**
   * Removes chosen page element from list of elements in chosen page
   * Requires users confirmation
   * @param pageIndex - number
   */
  const removePageElementFromPage = async (
    pageElementIndex: number,
    pageElementId?: string,
    values?: FitnessPageUI,
    setFieldValue?: (fieldName: string, value: FitnessPageElementUI[]) => void
  ): Promise<void> => {
    const result = window.confirm("Are u sure u want to delete this element?");
    if (!result) {
      return;
    }

    if (pageElementId) {
      const response = await deleteFitnessPageElement(pageElementId);
      if (response.statusType !== ResponseStatusType.OK) {
        return;
      }
      toast.success(response.message);
    }

    values &&
      values.pageElements &&
      setFieldValue &&
      setFieldValue(
        "pageElements",
        values?.pageElements
          ?.filter((element, i) => i !== pageElementIndex)
          .map((element, i) =>
            utils.updateInstanceObject(FitnessPageElementUI, element, {
              sequenceNumber: i + 1
            })
          )
      );
  };

  // PAGE ELEMENT ACTIONS

  /**
   * Append new page element to chosen page
   * Redirect to page form with new page element
   * @param pageElement - FitnessPageElementUI
   */
  const appendNewPageElementToChosenPage = (
    pageElement: FitnessPageElementUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage:
          prevValues.chosenPage &&
          utils.updateInstanceObject(FitnessPageUI, prevValues.chosenPage, {
            pageElements: prevValues.chosenPage?.pageElements
              ? [...prevValues.chosenPage?.pageElements, pageElement]
              : [pageElement]
          }),
        chosenPageElement: null,
        previousFormTab: FitnessFormTypeEnum.FITNESS_FORM,
        formTab: prevValues.previousFormTab
      })
    );
  };

  /**
   * Replaces pageElement with chosenPageElement based on sequenceNumber
   * Redirect to page form with updated page element
   * @param pageElement - TaskPageElementUI
   */
  const updatePageElementToChosenPage = (
    pageElement: FitnessPageElementUI
  ): void => {
    setPageState(prevValues =>
      utils.updateObject(prevValues, {
        chosenPage:
          prevValues.chosenPage &&
          utils.updateInstanceObject(FitnessPageUI, prevValues.chosenPage, {
            pageElements:
              prevValues.chosenPage.pageElements &&
              prevValues.chosenPage.pageElements.map(element =>
                element.sequenceNumber === pageElement.sequenceNumber
                  ? pageElement
                  : element
              )
          }),
        chosenPageElement: null,
        previousFormTab: FitnessFormTypeEnum.FITNESS_FORM,
        formTab: prevValues.previousFormTab
      })
    );
  };

  return (
    <>
      <PageHeader title={`Edit Fitness`} />
      {pageState.formTab === FitnessFormTypeEnum.FITNESS_FORM && (
        <FitnessForm
          isEditForm
          filters={pageState.filters}
          fitnessId={id}
          addNewPage={addNewPage}
          editPage={editPage}
          editPageWithId={editPageWithId}
          removePageFromFitness={removePageFromFitness}
        />
      )}
      {(pageState.formTab === FitnessFormTypeEnum.EDIT_PAGE_FORM ||
        pageState.formTab === FitnessFormTypeEnum.PAGE_FORM) &&
        (pageState.chosenPage || pageState.chosenPageId) && (
          <FitnessPageFrom
            pageId={pageState.chosenPageId}
            page={pageState.chosenPage}
            formTab={pageState.formTab}
            addNewPageElement={addNewPageElement}
            goBackOneFormHandler={goBackOneFormHandler}
            editPageElement={editPageElement}
            appendNewPageToFitness={appendNewPageToFitness}
            updatePage={updatePage}
            removePageElementFromPage={removePageElementFromPage}
            editPageElementWithId={editPageElementWithId}
            fitnessId={id}
            isEditForm
          />
        )}
      {(pageState.formTab === FitnessFormTypeEnum.PAGE_ELEMENT_FORM ||
        pageState.formTab === FitnessFormTypeEnum.EDIT_PAGE_ELEMENT_FORM) &&
        (pageState.chosenPageElement || pageState.chosenPageElementId) && (
          <FitnessPageElementForm
            formTab={pageState.formTab}
            chosenPageElement={pageState.chosenPageElement}
            goBackOneFormHandler={goBackOneFormHandler}
            appendNewPageElementToChosenPage={appendNewPageElementToChosenPage}
            updatePageElementToChosenPage={updatePageElementToChosenPage}
            pageElementId={pageState.chosenPageElementId}
            pageId={pageState.chosenPageId}
            page={pageState.chosenPage}
            fitnessId={id}
            isEditForm
          />
        )}
    </>
  );
};

export default EditFitness;
