// DTOS
import { FitnessPageElementDto } from "../dtos";
// DOMAIN Models
import { FitnessPageElement } from "../../app/domain";
// enums
import { ResponseStatusType } from "../../../enums/api";

export type FitnessPageElementsResponseDataType = {
  fitness_page_elements: FitnessPageElementDto[];
  statusType: ResponseStatusType;
};

export class FitnessPageElementsResponse {
  fitnessPageElements: FitnessPageElement[];
  statusType: ResponseStatusType;

  constructor(responseData: FitnessPageElementsResponseDataType) {
    this.fitnessPageElements = responseData.fitness_page_elements.map(
      element => new FitnessPageElement(element)
    );
    this.statusType = ResponseStatusType.OK;
  }
}
