// DTOS
import { FitnessDto } from "../dtos";

// DOMAIN Models
import { Fitness } from "../../app/domain";

//Api Enums
import { ResponseStatusType } from "../../../enums/api";

export type FitnessResponseDataType = {
  fitness: FitnessDto;
  statusType: ResponseStatusType;
};

export class FitnessResponse {
  fitness: Fitness;
  statusType: ResponseStatusType;

  constructor(responseData: FitnessResponseDataType) {
    this.fitness = new Fitness(responseData.fitness);
    this.statusType = ResponseStatusType.OK;
  }
}
