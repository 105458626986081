import { FormikHelpers, useFormik } from "formik";
import { toast } from "react-toastify";

//UI Models
import { AdminUI } from "../../../models/app/ui";

// Domain Models
import { ShopArticle } from "../../../models/app/domain";

// components
import AdminForm from "./AdminForm";

// Api Enums
import { ResponseStatusType } from "../../../enums/api";

// Calls
import { createAdminUser } from "../../../api/calls/users/users";

// validations
import { addAdminValidation } from "./add-admin-form.validation";

const AddAdmin: React.FC<{
  postCreateUpdateAction: () => void;
  handleClose: () => void;
}> = props => {
  /**
   * Request for creating new article
   * If success adds new article to end of list and close modal
   * If fails should return notification
   * @param values - AdminUI
   */
  const createAdminHandler = async (
    values: AdminUI,
    { setSubmitting }: FormikHelpers<AdminUI>
  ): Promise<void> => {
    setSubmitting(true);

    const response = await createAdminUser(values);
    if (response.statusType !== ResponseStatusType.OK) {
      setSubmitting(false);
      return;
    }

    toast.success(response.message);
    props.postCreateUpdateAction();
  };

  const formik = useFormik({
    initialValues: new AdminUI(),
    onSubmit: createAdminHandler,
    validate: addAdminValidation
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting
  } = formik;

  return (
    <AdminForm
      formTitle="Add new admin"
      submitText="Create"
      values={values}
      errors={errors}
      touched={touched}
      setFieldValue={setFieldValue}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      handleClose={props.handleClose}
    />
  );
};

export default AddAdmin;
