import { ApiResponse } from "apisauce";

// Api
import { api } from "../../api";
import { getGeneralApiProblem } from "../../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem
} from "../../general-api-problems/general-api-problems.types";

// Response
import {
  TasksResponse,
  TasksResponseDataType
} from "../../../models/api/responses/tasks.response";
import {
  MessageResponseType,
  MessageResponse
} from "../../../models/api/responses/message.response";

// App Enums
import { TaskTypeEnum } from "../../../enums/app";

/**
 * Fetch tasks with required task type
 * @params taskType - TaskTypeEnum
 * @returns - TasksResponse or GeneralApiProblem
 */
export const getTasks = async (
  taskType: TaskTypeEnum
): Promise<TasksResponse | GeneralApiProblem> => {
  const query =
    taskType === TaskTypeEnum.KPI
      ? `lifecycle_type=kpi`
      : `task_type=${taskType}`;
  const response: ApiResponse<TasksResponseDataType, ExceptionPayload> =
    await api.apisauce.get(`/task/search?${query}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as TasksResponseDataType;
  return new TasksResponse(responseData);
};

/**
 * Change page number request
 * @param taskPageId - string
 * @param pageNumber - number
 * @returns MessageResponse or GeneralApiProblem
 */
export const reorderLesson = async (
  lessonId?: string,
  pageNumber?: number | null
): Promise<TasksResponse | GeneralApiProblem> => {
  const response: ApiResponse<TasksResponseDataType, ExceptionPayload> =
    await api.apisauce.post(
      `/task/reorder?id=${lessonId}&position=${pageNumber}`
    );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as TasksResponseDataType;
  return new TasksResponse(responseData);
};
