import { ApiResponse } from "apisauce";

// Api
import { api } from "../../api";
import { getGeneralApiProblem } from "../../general-api-problems/general-api-problems";
import {
  ExceptionPayload,
  GeneralApiProblem
} from "../../general-api-problems/general-api-problems.types";

// UI Models
import { TaskUI, TaskPageUI } from "../../../models/app/ui";

// Request Models
import {
  CreateTaskRequest,
  UpdateTaskRequest,
  UpdateTaskPageRequest
} from "../../../models/api/requests";

// Response Models
import {
  TaskResponse,
  TaskResponseDataType
} from "../../../models/api/responses/task.response";
import {
  TaskPageResponse,
  TaskPageResponseDataType
} from "../../../models/api/responses/task-page.response";
import {
  TaskPagesResponse,
  TaskPagesResponseDataType
} from "../../../models/api/responses/task-pages.response";
import {
  TaskPageElementResponse,
  TaskPageElementResponseDataType
} from "../../../models/api/responses/task-page-element.response";
import {
  TaskPageElementsResponse,
  TaskPageElementsResponseDataType
} from "../../../models/api/responses/task-page-elements.response";
import {
  MessageResponseType,
  MessageResponse
} from "../../../models/api/responses/message.response";
import { ResponseStatusType } from "../../../enums/api";

/**
 * Create task request
 * @param file - File
 * @returns MessageResponse or GeneralApiProblem
 */
export const createTask = async (
  taskUI: TaskUI
): Promise<MessageResponse | GeneralApiProblem> => {
  const requestPayload = new CreateTaskRequest(taskUI);

  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.post("/task", requestPayload);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;

  return new MessageResponse(responseData);
};

/**
 * Get task details request
 * @param id - string
 * @returns TaskResponse or GeneralApiProblem
 */
export const getTask = async (
  id: string
): Promise<TaskResponse | GeneralApiProblem> => {
  const response: ApiResponse<TaskResponseDataType, ExceptionPayload> =
    await api.apisauce.get(`/task/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }
  const responseData = response.data as TaskResponseDataType;
  return new TaskResponse(responseData);
};

/**
 * Update task request
 * @param taskUI - TaskUI
 * @param taskId - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const updateTask = async (
  taskUI: TaskUI,
  taskId: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const requestPayload = new UpdateTaskRequest(taskUI, taskId);
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.patch(`/task/${taskId}`, requestPayload);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};

/**
 * Delete task request
 * @param id - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const deleteTask = async (
  id: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/task/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};

/**
 * Get task page details request
 * @param id - string
 * @returns TaskPageResponse or GeneralApiProblem
 */
export const getTaskPage = async (
  id: string
): Promise<TaskPageResponse | GeneralApiProblem> => {
  const response: ApiResponse<TaskPageResponseDataType, ExceptionPayload> =
    await api.apisauce.get(`/task/page/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }
  const responseData = response.data as TaskPageResponseDataType;
  return new TaskPageResponse(responseData);
};

/**
 * Delete task page request
 * @param id - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const deletePage = async (
  id: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/task/task-page/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};

/**
 * Update task page element request
 * @param taskUI - TaskUI
 * @param taskId - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const updateTaskPage = async (
  taskPageUI: TaskPageUI,
  taskId: string,
  taskPageId?: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const requestPayload = {
    task_pages: [new UpdateTaskPageRequest(taskPageUI, taskId, taskPageId)]
  };

  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.patch(`/task/${taskId}`, requestPayload);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};

/**
 * Change page number request
 * @param taskPageId - string
 * @param pageNumber - number
 * @returns MessageResponse or GeneralApiProblem
 */
export const reorderTaskPage = async (
  taskPageId?: string,
  pageNumber?: number
): Promise<TaskPagesResponse | GeneralApiProblem> => {
  const response: ApiResponse<TaskPagesResponseDataType, ExceptionPayload> =
    await api.apisauce.post(
      `/task/page/reorder?id=${taskPageId}&position=${pageNumber}`
    );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as TaskPagesResponseDataType;
  return new TaskPagesResponse(responseData);
};

/**
 * Get task page details request
 * @param id - string
 * @returns TaskPageResponse or GeneralApiProblem
 */
export const getTaskPageElement = async (
  id: string
): Promise<TaskPageElementResponse | GeneralApiProblem> => {
  const response: ApiResponse<
    TaskPageElementResponseDataType,
    ExceptionPayload
  > = await api.apisauce.get(`/task/page-element/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }
  const responseData = response.data as TaskPageElementResponseDataType;
  return new TaskPageElementResponse(responseData);
};

/**
 * Change page number request
 * @param taskPageId - string
 * @param pageNumber - number
 * @returns MessageResponse or GeneralApiProblem
 */
export const reorderTaskPageElements = async (
  elementId?: string,
  pageNumber?: number
): Promise<TaskPageElementsResponse | GeneralApiProblem> => {
  const response: ApiResponse<
    TaskPageElementsResponseDataType,
    ExceptionPayload
  > = await api.apisauce.post(
    `/task/page-element/reorder?id=${elementId}&position=${pageNumber}`
  );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as TaskPageElementsResponseDataType;
  return new TaskPageElementsResponse(responseData);
};

/**
 * Delete task page element request
 * @param id - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const deletePageElement = async (
  id: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/task/task-page-element/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};

/**
 * Delete task page element request
 * @param id - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const deleteQuizCompletionMessage = async (
  id: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/task/completion-message/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};

/**
 * Delete task page element question answer request
 * @param id - string
 * @returns MessageResponse or GeneralApiProblem
 */
export const deleteTaskPageElementQuestionAnswer = async (
  id: string
): Promise<MessageResponse | GeneralApiProblem> => {
  const response: ApiResponse<MessageResponseType, ExceptionPayload> =
    await api.apisauce.delete(`/task/answer/${id}`);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    return problem;
  }

  const responseData = response.data as MessageResponseType;
  return new MessageResponse(responseData);
};
