import { QuizCompletionMessageDto } from "../../api/dtos";

export class QuizCompletionMessage {
  id?: string;
  lowerLimit: number;
  upperLimit: number;
  completionMessage: string;

  constructor(entity: QuizCompletionMessageDto) {
    this.id = entity.id;
    this.lowerLimit = entity.lower_limit;
    this.upperLimit = entity.upper_limit;
    this.completionMessage = entity.completion_message;
  }
}
